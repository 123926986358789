import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterFromArr, filterToArr } from '../../data/staticFilters';
import { updateInstaFilters } from '../../redux/features/filters.feature';
import SingleSelect from '../SingleSelect';
const InstaFollowersFilter = () => {
  const dispath = useDispatch();
  let instaFiltersState = useSelector((state) => state.filters.instaFilters);

  const [flwerFrom, setFlwerFrom] = useState(filterFromArr);
  const [flwerTo, setFlwerTo] = useState(filterToArr);
  // handling the flowers from -----> to
  const handleFlwerFrom = (v) => {
    dispath(
      updateInstaFilters({
        influencer: {
          ...instaFiltersState.influencer,
          followers: {
            min: v,
            max: instaFiltersState.influencer.followers.max,
          },
        },
        audience: {
          ...instaFiltersState.audience,
        },
      })
    );
    const to = [];
    filterToArr.forEach((obj) => {
      if (obj.value > v.value) {
        to.push(obj);
      }
    });
    setFlwerTo(to);
  };
  const handleFlwerTo = (v) => {
    dispath(
      updateInstaFilters({
        influencer: {
          ...instaFiltersState.influencer,
          followers: {
            min: instaFiltersState.influencer.followers.min,
            max: v,
          },
        },
        audience: {
          ...instaFiltersState.audience,
        },
      })
    );
    // dispath(updateInstaFilters(v));
    const from = [];
    filterFromArr.forEach((obj) => {
      if (obj.value < v.value) {
        from.push(obj);
      }
    });
    setFlwerFrom(from);
  };
  return (
    <>
      <p className=' mb-1 text-sm'>Followers</p>
      <div className='flex flex-full space-x-2'>
        <SingleSelect
          value={instaFiltersState.influencer.followers.min}
          options={flwerFrom}
          className='basis-1/2'
          name='followers-from'
          onChange={(v) => handleFlwerFrom(v)}
          placeholder='From'
        />
        <SingleSelect
          value={instaFiltersState.influencer.followers.max}
          className='basis-1/2'
          options={flwerTo}
          name='followers-to'
          onChange={(v) => handleFlwerTo(v)}
          placeholder='To'
        />
      </div>
    </>
  );
};

export default InstaFollowersFilter;
