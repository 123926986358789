import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../components/Navbar';
import avatar from '../assets/img/avatar.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { BsGear } from 'react-icons/bs';
import { BiLogOut } from 'react-icons/bi';
import { logout } from '../redux/features/auth/login.feature';
import { FaHome, FaSearch } from 'react-icons/fa';

const Profile = () => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.login);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!userInfo?.data?.success) {
      navigate('/');
    }
  }, [userInfo]);

  return (
    userInfo?.data?.success && (
      <div className='min-h-screen bg-secondary'>
        <Navbar />
        <div className='container'>
          <div className='row flex min-h-[500px]'>
            <div className='hidden basis-1/4 border-r border-white/40 md:block'>
              <h3 className='border-b border-white/40 py-2 text-xl uppercase'>
                Important Links
              </h3>
              <ul className='mt-3'>
                <li className='mb-1'>
                  <Link
                    to='/'
                    className='flex  items-center  text-sm hover:text-pink md:text-base '
                  >
                    <FaHome className='mr-2' />
                    Home
                  </Link>
                </li>
                <li className='mb-1 flex'>
                  <Link
                    to='/search'
                    className='flex  items-center  text-sm hover:text-pink md:text-base '
                  >
                    <FaSearch className='mr-2' />
                    Search Influencers
                  </Link>
                </li>

                <li className='mb-1 flex'>
                  <Link
                    to='/forgetpassword'
                    className='flex  items-center  text-sm hover:text-pink md:text-base '
                  >
                    <BsGear className='mr-2' />
                    Change Password
                  </Link>
                </li>
                <li className='mb-1 flex'>
                  <span
                    onClick={() => dispatch(logout())}
                    className='flex  cursor-pointer  items-center text-sm hover:text-pink md:text-base'
                  >
                    <BiLogOut className='mr-2' />
                    Logout
                  </span>
                </li>
              </ul>
            </div>
            <div className='basis-full px-4 pt-10 md:basis-[75%]'>
              <div className='mx-auto'>
                <div className='flex items-center justify-center py-3'>
                  <div
                    className='h-32 w-32 rounded-full bg-cover sm:h-40 sm:w-40'
                    style={{
                      backgroundImage: `url(${avatar})`,
                    }}
                  ></div>
                </div>
                <ul className='text-center'>
                  <li>
                    <strong>Full name: </strong> {userInfo?.data?.name}
                  </li>
                  <li>
                    <strong>Email:</strong> {userInfo?.data?.email}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Profile;
