import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { genderOptionsArr } from '../../data/staticFilters';
import { updateInstaFilters } from '../../redux/features/filters.feature';
import SingleSelect from '../SingleSelect';

const AudienceGenderFilter = () => {
  const dispath = useDispatch();
  const { influencer, audience } = useSelector(
    (state) => state.filters.instaFilters
  );
  return (
    <>
      <p className='mb-1 text-sm text-white text-opacity-90 '>Gender</p>

      <div className='flex space-x-2'>
        <SingleSelect
          className='w-full'
          options={genderOptionsArr}
          name='gender'
          placeholder='gender'
          onChange={(obj) =>
            dispath(
              updateInstaFilters({
                audience: {
                  ...audience,
                  gender: obj,
                },
                influencer: {
                  ...influencer,
                },
              })
            )
          }
          value={audience.gender}
        />
      </div>
    </>
  );
};

export default AudienceGenderFilter;
