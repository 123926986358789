import React, { useState } from 'react';
import { BsInfoCircleFill } from 'react-icons/bs';
import Tooltip from 'rc-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { filterFromArr, filterToArr } from '../../data/staticFilters';
import { updateInstaFilters } from '../../redux/features/filters.feature';
import SingleSelect from '../SingleSelect';
const InstaAvgReelsFilter = () => {
  const dispath = useDispatch();
  let instaFiltersState = useSelector((state) => state.filters.instaFilters);

  const [filterFrom, setFilterFrom] = useState(filterFromArr);
  const [filterTo, sefFilterTo] = useState(filterToArr);
  // handling the flowers from -----> to
  const handleFilterFrom = (v) => {
    dispath(
      updateInstaFilters({
        influencer: {
          ...instaFiltersState.influencer,
          reelsPlays: {
            min: v,
            max: instaFiltersState.influencer.reelsPlays.max,
          },
        },
        audience: {
          ...instaFiltersState.audience,
        },
      })
    );
    const to = [];
    filterToArr.forEach((obj) => {
      if (obj.value > v.value) {
        to.push(obj);
      }
    });
    sefFilterTo(to);
  };
  const handlefilterTo = (v) => {
    dispath(
      updateInstaFilters({
        influencer: {
          ...instaFiltersState.influencer,
          reelsPlays: {
            min: instaFiltersState.influencer.reelsPlays.min,
            max: v,
          },
        },
        audience: {
          ...instaFiltersState.audience,
        },
      })
    );
    // dispath(updateInstaFilters(v));
    const from = [];
    filterFromArr.forEach((obj) => {
      if (obj.value < v.value) {
        from.push(obj);
      }
    });
    setFilterFrom(from);
  };
  // useEffect(() => {
  //   handleFilterFrom(
  //     instaFiltersState.influencer.reelsPlays.min?.value
  //       ? instaFiltersState.influencer.reelsPlays?.min
  //       : { value: 0 }
  //   );
  //   handlefilterTo(
  //     instaFiltersState.influencer.reelsPlays.max?.value
  //       ? instaFiltersState.influencer.reelsPlays?.max
  //       : { value: 0 }
  //   );
  // }, []);

  return (
    <>
      <p className='mb-1 text-sm text-white text-opacity-90 flex items-center'>
        Average Reels plays
        <Tooltip
          placement='top'
          overlay={
            <span className='text-center inline-block'>
              Identift Influcencers by the average number of plays on thier
              reels
            </span>
          }
        >
          <span className='ml-2 hover:opacity-70'>
            <BsInfoCircleFill size={15} />
          </span>
        </Tooltip>
      </p>
      <div className='flex space-x-2'>
        <SingleSelect
          className='basis-1/2'
          options={filterFrom}
          onChange={(v) => handleFilterFrom(v)}
          name='followers-from'
          placeholder='From'
          value={instaFiltersState.influencer.reelsPlays.min}
        />
        <SingleSelect
          className='basis-1/2'
          options={filterTo}
          onChange={(v) => handlefilterTo(v)}
          name='followers-to'
          placeholder='To'
          value={instaFiltersState.influencer.reelsPlays.max}
        />
      </div>
    </>
  );
};

export default InstaAvgReelsFilter;
