import React from 'react';
import { MdClose } from 'react-icons/md';
import { Link } from 'react-router-dom';
import ResetPass from '../components/forgetpass/ResetPass';

const Forgetpassword = () => {
  return (
    <div className='bg-secondary'>
      <div>
        <div className='fixed left-0 top-0 right-0 flex items-center justify-end p-4 md:justify-between '></div>
        <div className='login flex min-h-[100vh] items-center justify-center px-4 py-16'>
          <ResetPass />
        </div>
      </div>
    </div>
  );
};

export default Forgetpassword;
