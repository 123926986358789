import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterFromArr, filterToArr } from '../../data/staticFilters';
import { updateTiktokFilters } from '../../redux/features/filters.feature';
import SingleSelect from '../SingleSelect';
const TTSubFilter = () => {
  const dispath = useDispatch();
  let TTFitlersState = useSelector((state) => state.filters.tiktokFilters);
  const [flwerFrom, setFlwerFrom] = useState(filterFromArr);
  const [flwerTo, setFlwerTo] = useState(filterToArr);
  // handling the flowers from -----> to
  const handleFlwerFrom = (v) => {
    dispath(
      updateTiktokFilters({
        influencer: {
          ...TTFitlersState.influencer,
          followers: {
            min: v,
            max: TTFitlersState.influencer.followers.max,
          },
        },
        audience: {
          ...TTFitlersState.audience,
        },
      })
    );
    const to = [];
    filterToArr.forEach((obj) => {
      if (obj.value > v.value) {
        to.push(obj);
      }
    });
    setFlwerTo(to);
  };
  const handleFlwerTo = (v) => {
    dispath(
      updateTiktokFilters({
        influencer: {
          ...TTFitlersState.influencer,
          followers: {
            min: TTFitlersState.influencer.followers.min,
            max: v,
          },
        },
        audience: {
          ...TTFitlersState.audience,
        },
      })
    );
    // dispath(updateTiktokFilters(v));
    const from = [];
    filterFromArr.forEach((obj) => {
      if (obj.value < v.value) {
        from.push(obj);
      }
    });
    setFlwerFrom(from);
  };
  return (
    <>
      <p className=' mb-1 text-sm'>Subscribers</p>
      <div className='flex-full flex space-x-2'>
        <SingleSelect
          value={TTFitlersState.influencer.followers.min}
          options={flwerFrom}
          className='basis-1/2'
          name='followers-from'
          onChange={(v) => handleFlwerFrom(v)}
          placeholder='From'
        />
        <SingleSelect
          value={TTFitlersState.influencer.followers.max}
          className='basis-1/2'
          options={flwerTo}
          name='followers-to'
          onChange={(v) => handleFlwerTo(v)}
          placeholder='To'
        />
      </div>
    </>
  );
};

export default TTSubFilter;
