import React, { useEffect, useState } from 'react';
import { FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InstaFilterForm from '../components/InstaFilterForm';
import Navbar from '../components/Navbar';
import TTFilterForm from '../components/TTFilterForm';
import YTFilterForm from '../components/YTFilterForm';

const FindInfluencers = () => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.login);
  useEffect(() => {
    if (!userInfo?.data?.jsontoken) {
      navigate('/login');
    }
  }, [userInfo]);
  const [tab, setTab] = useState(1);
  return (
    userInfo?.data?.jsontoken && (
      <div>
        <Navbar />
        <div className='bg-secondary py-4'>
          <div className='container'>
            <div className='tabs-header mb-3'>
              <ul className='flex select-none text-[13px] text-[#f2e3f9] sm:text-sm'>
                <li
                  onClick={() => setTab(1)}
                  style={{
                    background: tab === 1 && '#a722f4',
                  }}
                  className='flex cursor-pointer items-center rounded-[2px] border-r border-white/20 bg-white/20 px-2.5 py-2 hover:bg-pink/40 sm:px-3'
                >
                  <FaInstagram />
                  <span className='pl-2'>Instagram</span>
                </li>
                <li
                  onClick={() => setTab(2)}
                  style={{
                    background: tab === 2 && '#a722f4',
                  }}
                  className='flex cursor-pointer items-center rounded-[2px] border-r border-white/20 bg-white/20 px-2.5 py-2 hover:bg-pink/40 sm:px-3'
                >
                  <FaYoutube />
                  <span className='pl-2'>Youtube</span>
                </li>
                <li
                  onClick={() => setTab(3)}
                  style={{
                    background: tab === 3 && '#a722f4',
                  }}
                  className='flex cursor-pointer items-center rounded-[2px] bg-white/20 px-2.5 py-2 hover:bg-pink/40 sm:px-3'
                >
                  <FaTiktok />
                  <span className='pl-2'>Tiktok</span>
                </li>
              </ul>
            </div>
            {/* tabs content */}
            <div className='tab-body'>
              {tab === 1 ? (
                <InstaFilterForm />
              ) : tab === 2 ? (
                <YTFilterForm />
              ) : tab === 3 ? (
                <TTFilterForm />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default FindInfluencers;
