import React, { useEffect, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { CgLogOut } from 'react-icons/cg';
import { IoPersonSharp } from 'react-icons/io5';
import { FaClosedCaptioning, FaCross } from 'react-icons/fa';
import { IoClose, IoMenuOutline, IoPersonOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../assets/img/logo.png';
import { logout } from '../redux/features/auth/login.feature';
const Navbar = () => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const userInfo = useSelector((state) => state.login);
  console.log(userInfo);
  const shortName = userInfo?.data?.name?.slice(0, 7) + '...';

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimenion]);

  return (
    <header className='body-font border-b border-white/30 bg-primary text-white'>
      <div className='container flex  items-center justify-between py-3 md:py-6'>
        <Link to='/' className='logo-ancher z-[10] order-2 md:order-1'>
          <img className='w-[120px] md:w-[150px]' src={Logo} alt='' />
        </Link>
        <div
          className='menu-opener hidden cursor-pointer'
          onClick={() => setMenu(true)}
        >
          <IoMenuOutline size={28} />
        </div>
        <nav
          className={`on-mobile z-[99] flex  flex-wrap items-center justify-center md:order-2  md:ml-auto`}
        >
          <ul
            style={{
              display: menu ? 'flex' : '',
            }}
            className={`mobile-menu flex text-sm`}
          >
            {menu ? (
              <li className='mb-2 flex items-center justify-between border-b border-white/40 px-4 pb-1 pt-4'>
                <span>Menu</span>
                <span
                  onClick={() => setMenu(false)}
                  className='cursor-pointer p-1.5'
                >
                  <IoClose size={24} />
                </span>
              </li>
            ) : null}

            <li className='py-2'>
              <a href='https://lovefy.com/' className='mr-5 hover:text-pink'>
                Why Lovefy
              </a>
            </li>
            <li className='py-2'>
              <a
                href='https://marketplace.lovefy.com/'
                className='mr-5 py-2 transition duration-300 hover:text-pink'
              >
                Marketplace
              </a>
            </li>
            <li className='py-2'>
              <Link
                to='/search'
                className='mr-5 py-2 transition duration-300 hover:text-pink'
              >
                Search Influencer
              </Link>
            </li>
            <li className='py-2'>
              <a
                href='https://love.io/wallet'
                className='mr-5 py-2 transition duration-300 hover:text-pink'
              >
                Wallet
              </a>
            </li>
            <li className='py-2'>
              <a
                href='https://love.io/'
                className='mr-5 py-2 transition duration-300 hover:text-pink'
              >
                Love.io
              </a>
            </li>
            <li className='py-2'>
              <a
                href='https://love.io/#team'
                className='mr-5 py-2 transition duration-300 hover:text-pink'
              >
                Team
              </a>
            </li>
            <li className='py-2'>
              <a
                href='/#contact'
                className='mr-5 py-2 transition duration-300 hover:text-pink'
              >
                Contact
              </a>
            </li>
          </ul>
        </nav>
        {!userInfo?.data?.success ? (
          <Link
            to='/login'
            className='ease order-3  rounded-full border-0 bg-white py-1 px-5 font-semibold text-secondary transition duration-300 hover:bg-pink hover:text-white focus:outline-none md:order-3 md:mt-0 md:inline-block md:px-7 md:py-[8px]'
          >
            Login
          </Link>
        ) : (
          <div className='hover:bg-tertiary/5 group  relative z-10 order-3 flex cursor-pointer items-center space-x-3 rounded-md p-2 md:order-3'>
            <IoPersonSharp />
            <span
              title={userInfo?.data?.name}
              className='hidden text-sm capitalize opacity-80 md:block'
            >
              {shortName}
            </span>
            <span className='bg-tertiary/5 absolute right-0 bottom-0 rounded-sm p-1 md:static md:right-auto md:bottom-auto md:bg-transparent md:p-0'>
              <BsChevronDown className='text-sm opacity-80 ' />
            </span>
            <div className='absolute right-0 top-[100%]  z-[20] hidden rounded-md border border-white/40 bg-secondary py-1 shadow-md group-hover:block '>
              <ul className=' z-[20] w-[120px]'>
                <li className='py-[5px] px-3 hover:bg-primary/5'>
                  <Link to='/profile' className='flex items-center'>
                    <IoPersonOutline className='mr-2' /> Profile
                  </Link>
                </li>
                <li
                  onClick={() => dispatch(logout())}
                  className='flex items-center py-[5px] px-3 hover:bg-primary/5'
                >
                  <CgLogOut className='mr-2' /> Logout
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Navbar;
