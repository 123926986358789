import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { formatNumber } from '../utils/utilFuns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      display: false,
      labels: {
        padding: 5,
      },
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        borderColor: '#a1a1a1',
      },
      ticks: {
        color: '#fff',
        beginAtZero: true,
        padding: 10,
      },
    },
    y: {
      grid: {
        // borderColor: false,
        color: '#a1a1a1',
        drawBorder: false,
        afterFit: function (scale) {
          scale.width = 80; //<-- set value as you wish
        },
      },
      ticks: {
        color: '#fff',
        callback: (v) => formatNumber(v),
        beginAtZero: true,
        padding: 10,
      },

      // suggestedMin: 0,
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June'];



export function FollowersChart({labels, data}) {
  console.log(labels, data)
   const _data = {
     labels,
     datasets: [
       {
         label: 'Followers',
         data,
         lineTension: 0.3,
         borderColor: '#7a5cff',
         backgroundColor: '#a722f4',
       },
     ],
   };
  return <Line options={options} data={_data} />;
}
