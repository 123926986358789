import { useDispatch, useSelector } from 'react-redux';
// import { formatNumber } from '../utils/utilFuns';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Pagination from './TTPagination';
import InstaInfluencerOverview from '../insta/InstaInfluencerOverview';
import { getInfluencerReport } from '../../redux/features/influencer-report.feature';
import { CgClose } from 'react-icons/cg';
import { updateYoutubeFilters } from '../../redux/features/filters.feature';
import { getYTInfluencers } from '../../redux/features/youtubeInfluencers-list.feature';
import YTPagination from './TTPagination';
import { formatNumber } from '../utils/utilFuns';
import TTInfluencerOverview from './TTInfluencerOverview';
import TTPagination from './TTPagination';

const TTInfluencersList = () => {
  const dispatch = useDispatch();
  const [displayOverview, setDisplayOverview] = useState(false);
  const openOverview = () => setDisplayOverview(true);
  const closeOverview = () => setDisplayOverview(false);
  const {
    data: influencersData,
    loading: influencersLoading,
    errorMessage: influencersError,
  } = useSelector((state) => state.tiktokInfluencers);

  const itemClickHanlder = (id) => {
    setDisplayOverview(true);
    dispatch(
      getInfluencerReport({
        id,
        platform: 'tiktok',
      })
    );
  };
  return (
    <div className='min-h-screen'>
      <TTInfluencerOverview
        openOverview={displayOverview}
        closeOverview={closeOverview}
      />
      <h1 className='mb-2 text-2xl'>Influencers</h1>
      {influencersLoading === 'succeeded' && influencersData?.total > 0 && (
        <div className='mb-3'>
          Total Results: {influencersData.total.toLocaleString()}
        </div>
      )}
      {influencersLoading === 'pending' && (
        <h1 className='p-6 text-center'>loading</h1>
      )}
      {influencersError && influencersLoading === 'failed' && (
        <p className='py-4 text-center'>{influencersError}</p>
      )}
      {influencersData?.error && influencersData.code === 'no_result' && (
        <div className='mb-4 border border-white/30 py-8 px-4 '>
          <h2 className='mb-2'>Clear your filters and try again</h2>
          <p>
            We couldn’t find influencers that match your exact filters.{' '}
            <span className='font-bold'>Try using 1-2 filters at a time.</span>
          </p>
        </div>
      )}
      {!influencersData?.error &&
        influencersData?.directs?.length < 1 &&
        influencersData?.lookalikes?.length < 1 &&
        influencersLoading === 'succeeded' && (
          <div className='mb-4 border border-white/30 py-8 px-4 '>
            <h2 className='mb-2 text-xl font-semibold'>Come back tomorrow</h2>
            <p>
              We’re having some trouble showing your selected influencer(s).
              This usually happens when we’re updating profiles.
            </p>
          </div>
        )}
      {influencersData?.error && influencersData.code !== 'no_result' && (
        <div className='mb-4 border border-red-400 py-8 px-4'>
          {influencersData.message}
        </div>
      )}
      {influencersData?.directs?.length > 0 &&
        influencersLoading === 'succeeded' &&
        !influencersError && (
          <h2 className='mb-4 font-semibold'>Profiles found by username(s)</h2>
        )}
      <div className='overflow-x-auto'>
        <ul className='min-w-[475px]'>
          {influencersLoading === 'succeeded' &&
            influencersData &&
            influencersData?.directs?.map((influencer) => (
              <li
                key={influencer.userId}
                onClick={() => itemClickHanlder(influencer.userId)}
              >
                <div className='mb-3 flex transform cursor-pointer select-none flex-row items-center rounded-md border border-white border-opacity-10 bg-primary shadow-lg shadow-pinkLight/5 duration-300 hover:bg-secondary/50 hover:shadow-md hover:shadow-pink/20'>
                  <div className='basis-[52px] p-4 md:basis-[82px]'>
                    <div
                      style={{
                        backgroundImage: `url('${influencer.profile.picture}')`,
                        backgroundSize: 'cover',
                      }}
                      className='h-10 w-10 rounded-full bg-cover md:h-[3.125rem] md:w-[3.125rem]'
                    ></div>
                  </div>
                  <div className='basis-1/3'>
                    <h2 className='text-sm md:text-[18px] '>
                      {influencer.profile.fullname}
                    </h2>
                    <a
                      href={influencer.profile.url}
                      rel='noopener noreferrer'
                      target='_blank'
                      className='text-sm text-pink'
                    >
                      @{influencer.profile.username}
                    </a>
                  </div>
                  <div className='flex basis-1/6 flex-col p-4'>
                    <h2 className='text-sm leading-5 md:text-[18px]'>
                      {formatNumber(influencer.profile.followers)}
                    </h2>
                    <span className='text-[13px] font-normal leading-snug text-white opacity-80'>
                      Followers
                    </span>
                  </div>
                  <div className='flex flex-col p-4 '>
                    <h2 className=' leading-5'>
                      <span className='text-sm md:text-[18px] '>
                        {formatNumber(influencer.profile.engagements)}
                      </span>
                      {/* influencer.profile.engagementRate */}
                      <span className='pl-2 text-sm text-white text-opacity-90'>{`(${(
                        influencer.profile.engagementRate.toPrecision(2) * 100
                      ).toFixed(2)})%`}</span>
                    </h2>
                    <span className='text-xs font-normal leading-snug text-white opacity-80 md:text-[13px]'>
                      Engagements (Engagement rate)
                    </span>
                  </div>
                  <div className='mx-auto hidden basis-1/5 p-4 text-center md:block'>
                    <span className=' rounded-md border border-pinkLight bg-transparent py-1.5 px-3 text-sm hover:bg-pinkLight'>
                      View Detail
                    </span>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
      {influencersData?.directs?.length > 0 &&
        influencersData?.lookalikes?.length > 0 &&
        influencersLoading === 'succeeded' &&
        !influencersError && (
          <h2 className='my-4 font-semibold'>Lookalikes found</h2>
        )}

      <div className='overflow-x-auto'>
        {' '}
        <ul className='min-w-[475px]'>
          {influencersLoading === 'succeeded' &&
            influencersData &&
            influencersData?.lookalikes?.map((influencer) => (
              <li
                key={influencer.userId}
                onClick={() => itemClickHanlder(influencer.userId)}
              >
                <div className='mb-3 flex transform cursor-pointer select-none flex-row items-center rounded-md border border-white border-opacity-10 bg-primary shadow-lg shadow-pinkLight/5 duration-300 hover:bg-secondary/50 hover:shadow-md hover:shadow-pink/20'>
                  <div className='basis-[52px] p-4 md:basis-[82px]'>
                    <div
                      style={{
                        backgroundImage: `url('${influencer.profile.picture}')`,
                        backgroundSize: 'cover',
                      }}
                      className='h-10 w-10 rounded-full bg-cover md:h-[3.125rem] md:w-[3.125rem]'
                    ></div>
                  </div>
                  <div className='basis-1/3'>
                    <h2 className='text-sm md:text-[18px] '>
                      {influencer.profile.fullname}
                    </h2>
                    <a
                      href={influencer.profile.url}
                      rel='noopener noreferrer'
                      target='_blank'
                      className='text-sm text-pink'
                    >
                      @{influencer.profile.username}
                    </a>
                  </div>
                  <div className='flex basis-1/6 flex-col p-4'>
                    <h2 className='text-sm leading-5 md:text-[18px]'>
                      {formatNumber(influencer.profile.followers)}
                    </h2>
                    <span className='text-[13px] font-normal leading-snug text-white opacity-80'>
                      Followers
                    </span>
                  </div>
                  <div className='flex flex-col p-4 '>
                    <h2 className=' leading-5'>
                      <span className='text-sm md:text-[18px] '>
                        {formatNumber(influencer.profile.engagements)}
                      </span>
                      {/* influencer.profile.engagementRate */}
                      <span className='pl-2 text-sm text-white text-opacity-90'>{`(${(
                        influencer.profile.engagementRate.toPrecision(2) * 100
                      ).toFixed(2)})%`}</span>
                    </h2>
                    <span className='text-xs font-normal leading-snug text-white opacity-80 md:text-[13px]'>
                      Engagements (Engagement rate)
                    </span>
                  </div>
                  <div className='mx-auto hidden basis-1/5 p-4 text-center md:block'>
                    <span className=' rounded-md border border-pinkLight bg-transparent py-1.5 px-3 text-sm hover:bg-pinkLight'>
                      View Detail
                    </span>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <div className='pt-6'>
        <TTPagination />
      </div>
    </div>
  );
};

export default TTInfluencersList;
