import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  loading: false,
  data: null,
  errorMessage: null,
};

export const createUser = createAsyncThunk('auth/createUser', async (user) => {
  // console.log(user);
  let url = `https://influencerapi.lovefy.com/api/users`;
  let response = await axios.post(url, user);
  console.log(response.data);
  return response.data;
});

const crateUserSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    clearSingup: function (state, action) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.errorMessage = `Oops! Something goes wrong!`;
      });
  },
});
export const { clearSingup } = crateUserSlice.actions;

export default crateUserSlice.reducer;
