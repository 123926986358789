import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { updateYoutubeFilters } from '../../redux/features/filters.feature';
import { getYTInfluencers } from '../../redux/features/youtubeInfluencers-list.feature';
const YTPagination = () => {
  const {
    data: influencersData,
    loading: influencersLoading,
    errorMessage: influencersError,
  } = useSelector((state) => state.youtubeInfluencers);
  const dispatch = useDispatch();
  let youtubeFiltersState = useSelector(
    (state) => state.filters.youtubeFilters
  );
  const itemsPerPage = 15;
  const items = influencersData.total;
  const [pageCount, setPageCount] = useState(
    Math.ceil(items / itemsPerPage) < 665
      ? Math.ceil(items / itemsPerPage)
      : 665
  );
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(
      Math.ceil(items / itemsPerPage) < 665
        ? Math.ceil(items / itemsPerPage)
        : 665
    );
  }, [itemOffset, itemsPerPage, items]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items;
    dispatch(
      updateYoutubeFilters({
        influencer: {
          ...youtubeFiltersState.influencer,
        },
        audience: {
          ...youtubeFiltersState.audience,
        },
        page: event.selected,
      })
    );

    dispatch(
      getYTInfluencers({
        platform: 'youtube',
        filters: youtubeFiltersState,
        page: event.selected,
      })
    );
    setItemOffset(newOffset);
  };
  return (
    !influencersData.error &&
    influencersLoading === 'succeeded' && (
      <ReactPaginate
        forcePage={youtubeFiltersState.page}
        breakLabel='...'
        nextLabel='Next >'
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel='< Previous'
        renderOnZeroPageCount={null}
        nextClassName='pagination-btn-nex ml-2 font-semibold text-pinkLight'
        previousClassName='pagination-btn-pre  mr-2 font-semibold text-pinkLight'
        pageClassName='pagination-btn mx-0.5'
        className='pagination flex flex-wrap items-center justify-center text-xs sm:text-sm'
        breakClassName='pagination-break'
        pageLinkClassName='border border-pinkLight border-opacity-50 px-2 min-w-[30px] min-h-[30px]  sm:min-w-[35px] sm:min-h-[35px] mx-1 flex justify-center items-center'
      />
    )
  );
};

export default YTPagination;
