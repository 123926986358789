import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { formatNumber } from '../utils/utilFuns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    legend: {
      position: 'top',
      display: false,
      labels: {
        padding: 5,
      },
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  },
  responsive: true,

  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
        borderColor: '#a1a1a1',
      },
      ticks: {
        color: '#fff',
        padding: 8,
      },
    },
    y: {
      stacked: true,
      grid: {
        // borderColor: false,
        color: '#a1a1a1',
        drawBorder: false,
        afterFit: function (scale) {
          scale.width = 80; //<-- set value as you wish
        },
      },
      ticks: {
        color: '#fff',
        callback: (v) => formatNumber(v),
        padding: 8,
      },

      // suggestedMin: 0,
    },
  },
};



export function StatsBarChart({labels, likes, comments, views}) {

const _data = {
  labels,
  datasets: [
    // {
    //   label: 'Views',
    //   data: views,
    //   backgroundColor: '#a722f4',
    // },
    {
      label: 'Likes',
      data: likes,
      backgroundColor: '#7a5cff',
    },
    {
      label: 'Comments',
      data: comments,
      backgroundColor: '#a722f4',
    },
  ],
};
  return <Bar options={options} data={_data} />;
}
