export const filterFromArr = [
  {
    value: 1000,
    label: '1,000',
  },
  {
    value: 3000,
    label: '3,000',
  },
  {
    value: 5000,
    label: '5,000',
  },
  {
    value: 10000,
    label: '10,000',
  },
  {
    value: 15000,
    label: '15,000',
  },
  {
    value: 25000,
    label: '25,000',
  },
  {
    value: 35000,
    label: '35,000',
  },
  {
    value: 50000,
    label: '50,000',
  },
  {
    value: 75000,
    label: '75,000',
  },
  {
    value: 100000,
    label: '100,000',
  },
  {
    value: 175000,
    label: '175,000',
  },
  {
    value: 250000,
    label: '250,000',
  },
  {
    value: 350000,
    label: '350,000',
  },
  {
    value: 500000,
    label: '500,000',
  },
  {
    value: 1000000,
    label: '1,000,000',
  },
  {
    value: 2000000,
    label: '2,000,000',
  },
];
export const filterToArr = [
  {
    value: 3000,
    label: '3,000',
  },
  {
    value: 5000,
    label: '5,000',
  },
  {
    value: 10000,
    label: '10,000',
  },
  {
    value: 15000,
    label: '15,000',
  },
  {
    value: 25000,
    label: '25,000',
  },
  {
    value: 35000,
    label: '35,000',
  },
  {
    value: 50000,
    label: '50,000',
  },
  {
    value: 75000,
    label: '75,000',
  },
  {
    value: 100000,
    label: '100,000',
  },
  {
    value: 175000,
    label: '175,000',
  },
  {
    value: 250000,
    label: '250,000',
  },
  {
    value: 350000,
    label: '350,000',
  },
  {
    value: 500000,
    label: '500,000',
  },
  {
    value: 1000000,
    label: '1,000,000',
  },
  {
    value: 2000000,
    label: '2,000,000',
  },
  {
    value: 3000000,
    label: '3,000,000+',
  },
];
export const genderOptionsArr = [
  {
    value: 'MALE',
    label: 'Male',
  },
  {
    value: 'FEMALE',
    label: 'Female',
  },
];

export const credibility = [
  {
    value: 0.75,
    label: '≤ 25%',
  },
  {
    value: 0.65,
    label: '≤ 35%',
  },
  {
    value: 0.5,
    label: '≤ 50%',
  },
];
export const languageWeight = [
  {
    value: 0.05,
    label: '≤ 5%',
  },
  {
    value: 0.1,
    label: '≤ 10%',
  },
  {
    value: 0.15,
    label: '≤ 15%',
  },
  {
    value: 0.2,
    label: '≤ 20%',
  },
  {
    value: 0.25,
    label: '≤ 25%',
  },
  {
    value: 0.3,
    label: '≤ 30%',
  },
  {
    value: 0.35,
    label: '≤ 35%',
  },
  {
    value: 0.4,
    label: '≤ 40%',
  },
  {
    value: 0.45,
    label: '≤ 45%',
  },
  {
    value: 0.5,
    label: '≤ 50%',
  },
  {
    value: 0.55,
    label: '≤ 55%',
  },
  {
    value: 0.6,
    label: '≤ 60%',
  },
  {
    value: 0.65,
    label: '≤ 65%',
  },
  {
    value: 0.7,
    label: '≤ 70%',
  },
  {
    value: 0.75,
    label: '≤ 75%',
  },
  {
    value: 0.8,
    label: '≤ 80%',
  },
  {
    value: 0.85,
    label: '≤ 85%',
  },
  {
    value: 0.9,
    label: '≤ 90%',
  },
  {
    value: 0.95,
    label: '≤ 95%',
  },
];

export const ageGroups = [
  {
    value: '13-17',
    label: '13-17',
  },
  {
    value: '18-24',
    label: '18-24',
  },
  {
    value: '25-34',
    label: '25-34',
  },
  {
    value: '35-44',
    label: '35-44',
  },
  {
    value: '45+',
    label: '45+',
  },
];