import React, { useState } from 'react';
import { sendContactEmail } from '../redux/features/contact.feature';
import { useDispatch } from 'react-redux';

const Contact = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [msg, setMsg] = useState('');
  const [subject, setSubject] = useState('');

  // const [CFSent, setCFSent] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      sendContactEmail({
        email,
        name,
        msg,
        subject,
      })
    );
  };
  return (
    <div id='contact' className='bg-primary'>
      <section>
        <div class='mx-auto max-w-screen-md py-8 px-4 lg:py-16'>
          <h2 class='mb-4 text-center text-4xl font-bold tracking-tight text-white'>
            Contact Us
          </h2>
          <p class='mb-6 text-center font-light text-[#f2e3f9] dark:text-gray-400 sm:text-xl lg:mb-16'>
            Got a technical issue? Want to send feedback about a beta feature?
            Need details about our Business plan? Let us know.
          </p>
          <form onSubmit={handleSubmit} class='space-y-8'>
            <div className='flex flex-col justify-between sm:flex-row'>
              <div className='basis-full sm:basis-1/2 sm:pr-1.5'>
                <label
                  for='name'
                  className='text-white-800 mb-2 block text-sm font-medium '
                >
                  Your name
                </label>
                <input
                  type='name'
                  onChange={(e) => setName(e.target.value)}
                  id='text'
                  className='block w-full rounded-md  bg-white/80 p-2.5 text-secondary focus:border focus:border-none focus:bg-white/90 focus:outline-pink'
                  placeholder='enter name'
                  required
                />
              </div>
              <div className='mt-8 basis-full sm:mt-0 sm:basis-1/2 sm:pl-1.5'>
                <label
                  for='email'
                  className='text-white-800 mb-2 block text-sm font-medium '
                >
                  Your email
                </label>
                <input
                  type='email'
                  onChange={(e) => setEmail(e.target.value)}
                  id='email'
                  className='block w-full rounded-md  bg-white/80 p-2.5 text-secondary focus:border focus:border-none focus:bg-white/90 focus:outline-pink'
                  placeholder='enter email'
                  required
                />
              </div>
            </div>

            <div>
              <label
                for='subject'
                className='text-white-800 mb-2 block text-sm font-medium '
              >
                Subject
              </label>
              <input
                onChange={(e) => setSubject(e.target.value)}
                type='text'
                id='subject'
                className='block w-full rounded-md  bg-white/80 p-2.5 text-secondary focus:border focus:border-none focus:bg-white/90 focus:outline-pink'
                required
              />
            </div>
            <div class='sm:col-span-2'>
              <label
                for='message'
                className='text-white-800 mb-2 block text-sm font-medium '
              >
                Your message
              </label>
              <textarea
                onChange={(e) => setMsg(e.target.value)}
                id='message'
                rows='6'
                className='block w-full rounded-md  bg-white/80 p-2.5 text-secondary focus:border focus:border-none focus:bg-white/90 focus:outline-pink'
                placeholder='Leave a comment...'
              ></textarea>
            </div>
            <button
              type='submit'
              className='mx-auto inline-block rounded-lg border border-pinkLight  py-1.5 px-6 text-white transition duration-300 hover:border-transparent hover:bg-gradient-to-r hover:from-pinkLight hover:to-purple focus:outline-none md:py-3 md:px-8'
            >
              Send message
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Contact;
