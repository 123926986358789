import React, { useRef, useState } from 'react';
import MultiSelect from 'multiselect-react-dropdown';
const CustomMultiSelect = ({
  handleSearch,
  options,
  loading,
  setRef,
  placeholder,
  checkbox,
  multi,
  isObject,
  onSelect,
  onRemove,
  selectedValues,
  selectionLimit,
}) => {
  // console.log(singleRef.current);
  const styles = {
    multiselectContainer: {
      // To change css for multiselect (Width,height,etc..)
    },
    searchBox: {
      // To change search box element look
      minHeight: 40,
    },
    inputField: {
      // To change input field position or margin
    },
    chips: {
      // To change css chips(Selected options)
    },
    optionContainer: {
      // To change css for option container
      background: '#260c33',
      fontSize: 14,
      border: 'none',
      borderRadius: 0,
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
    },
    option: {
      // To change css for dropdown options
    },
    groupHeading: {
      // To chanage group heading style
    },
  };
  return (
    <>
      <MultiSelect
        selectionLimit={selectionLimit}
        style={styles}
        options={options}
        onSearch={handleSearch}
        loading={loading}
        isObject={isObject}
        displayValue='name'
        showCheckbox={checkbox}
        ref={setRef}
        placeholder={placeholder}
        singleSelect={!multi}
        // selectedValueDecorator={function noRefCheck(e) {
        //   return <span className='hidden'>{e}</span>;
        // }}
        // onSelect={() => setSelectedValues()}
        onSelect={onSelect}
        onRemove={onRemove}
        selectedValues={selectedValues}
      />
    </>
  );
};

export default CustomMultiSelect;
