import React, { useEffect } from 'react';
import { FaHeart } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { verifyEmail } from '../../redux/features/auth/verifyEmail.feature';
import { useDispatch, useSelector } from 'react-redux';
import { IoAlertCircleOutline } from 'react-icons/io5';

const VerificationReq = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.verifyEmail);
  console.log(state);
  const { email, code } = useParams();
  useEffect(() => {
    dispatch(
      verifyEmail({
        email,
        code,
      })
    );
  }, [email, code]);

  return (
    <div className='flex min-h-screen items-center justify-center bg-secondary'>
      {state?.loading && (
        <div class='love-loader flex h-full w-full items-center justify-center'>
          <div>
            <div className='text-center'>
              <FaHeart color='#D84DB7' size={68} className='mx-auto' />
            </div>
            <p className='mt-5 font-light text-[#f2e3f9]'>
              Verifying your email!
            </p>
          </div>
        </div>
      )}

      {state?.emailVerified && (
        <div className='max-w-xs text-center'>
          <h2 className='mt-5 mb-5 text-xl font-bold text-[#f2e3f9]'>
            Email Verified Successfully!
          </h2>
          <p className='mb-4 text-sm opacity-80'>
            Click on the login button and Login to your account now.
          </p>
          <Link
            to='/login'
            className='ease order-3  rounded-full border-0 bg-white py-1 px-5 font-semibold text-secondary transition duration-300 hover:bg-pink hover:text-white focus:outline-none md:order-3 md:mt-0 md:inline-block md:px-10 md:py-[8px]'
          >
            Login
          </Link>
        </div>
      )}
      {!state?.emailVerified && !state?.loading && (
        <div className='max-w-xs text-center'>
          <h2 className='mt-5 mb-5 flex items-center text-xl font-normal text-red-400'>
            <IoAlertCircleOutline className='mr-2' /> Something went wrong!
          </h2>
        </div>
      )}
    </div>
  );
};

export default VerificationReq;
