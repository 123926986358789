import Tooltip from 'rc-tooltip';
import React, { useEffect, useState } from 'react';
import {
  BsChatRightText,
  BsHeartFill,
  BsInfoCircleFill,
  BsPeople,
  BsPersonX,
} from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import { FaHeart, FaInstagram } from 'react-icons/fa';
import { MdOutlineLoop } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { getInfluencerReport } from '../../redux/features/influencer-report.feature';
import { formatNumber, fromatDate } from '../utils/utilFuns';
import InstaInfluencerReport from './InstaInfluencerReport';

const InstaInfluencerOverview = ({ openOverview, closeOverview }) => {
  const [displayReport, setDisplayReport] = useState(false);
  const openReport = () => setDisplayReport(true);
  const closeReport = () => setDisplayReport(false);
  const {
    data: influencerReportData,
    loading: influencerReportLoading,
    errorMessage: influencerReportError,
  } = useSelector((state) => state.influencerReport);

  // useEffect(() => {
  //   dispatch(getInfluencerReport({ id: 330962932, platform: 'instagram' }));
  // }, [])

  return (
    openOverview && (
      <div className='hidescrollbar-but-scroll fixed top-0 right-0 z-[99] mx-auto h-screen w-[448px] max-w-full overflow-y-scroll bg-primary py-10 shadow-lg shadow-pink/30 '>
        {influencerReportLoading && (
          <div class='love-loader flex h-full w-full items-center justify-center'>
            <div>
              <FaHeart color='#D84DB7' size={68} />
            </div>
          </div>
        )}
        <InstaInfluencerReport
          openReport={displayReport}
          closeReport={closeReport}
        />
        {!influencerReportLoading && !influencerReportError && (
          <>
            <button
              onClick={() => closeOverview()}
              className='fixed right-5 top-5 z-[100] rounded-full border border-white p-1'
            >
              <CgClose />
            </button>
            <div className='flex flex-col items-center justify-center  '>
              <div
                style={{
                  backgroundImage: `url(${influencerReportData?.profile?.profile?.picture})`,
                }}
                className='h-28 w-28 rounded-full bg-cover'
              ></div>
              <h2 className='mt-4 mb-4 text-xl'>
                {influencerReportData?.profile?.profile?.fullname}
              </h2>
              <div className='flex items-center'>
                <FaInstagram />
                <a
                  href={influencerReportData?.profile?.profile?.url}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='ml-1.5 text-pink'
                >
                  {influencerReportData?.profile?.profile?.username}
                </a>
              </div>
              <p className='text-sm font-light text-[#bbbbbb]'>
                Creator account
              </p>
              {influencerReportData?.profile?.userId ? (
                <button
                  onClick={openReport}
                  className=' my-4 flex min-w-[140px] max-w-[150px] justify-center rounded-lg bg-gradient-to-r from-pinkLight to-purple  py-2  text-sm text-white focus:outline-none md:py-2'
                >
                  View full report
                </button>
              ) : null}
            </div>

            <div className='flex space-x-3 p-4 '>
              <div className='flex basis-1/3 justify-center border border-white/20'>
                <div className='p-4 pb-2 text-center'>
                  <FaHeart style={{ margin: 'auto' }} color='#ff4d4a' />
                  <span className='mt-1.5 block text-xl font-semibold'>
                    {formatNumber(
                      influencerReportData?.profile?.stats?.avgLikes?.value
                    )}
                  </span>
                  <small className='block text-xs text-white text-opacity-80'>
                    AVG LIKES
                  </small>

                  <Tooltip
                    placement='top'
                    className='mt-2'
                    overlay={
                      <span className='inline-block text-center'>
                        The average sum of likes on the last 30 posts
                      </span>
                    }
                  >
                    <span className='mx-auto inline-block opacity-80 hover:opacity-70'>
                      <BsInfoCircleFill size={15} />
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div className='flex basis-1/3 justify-center border border-white/20'>
                <div className='p-4 pb-2 text-center'>
                  <BsPeople style={{ margin: 'auto' }} color='#d419ac' />
                  <span className='mt-1.5 block text-xl font-semibold'>
                    {formatNumber(
                      influencerReportData?.profile?.profile?.followers
                    )}
                  </span>
                  <small className='block text-xs text-white text-opacity-80'>
                    FOLLOWERS
                  </small>

                  <Tooltip
                    placement='top'
                    className='mt-2'
                    overlay={
                      <span className='inline-block text-center'>
                        How many Total followers the influencer has on
                        Instagram.
                      </span>
                    }
                  >
                    <span className='mx-auto inline-block opacity-80 hover:opacity-70'>
                      <BsInfoCircleFill size={15} />
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div className='flex basis-1/3 justify-center border border-white/20'>
                <div className='p-4 pb-2 text-center'>
                  <MdOutlineLoop style={{ margin: 'auto' }} color='#7a0fff' />
                  <span className='mt-1.5 block text-xl font-semibold'>
                    {(
                      influencerReportData?.profile?.profile?.engagementRate.toPrecision(
                        2
                      ) * 100
                    ).toFixed(2)}
                    %
                  </span>

                  <div className='flex'>
                    <small className='block text-xs text-white text-opacity-80'>
                      ENGAGEMENT RATE
                    </small>

                    <Tooltip
                      placement='top'
                      className='mt-2'
                      overlay={
                        <span className='inline-block text-center'>
                          This is an indication of how engaged the influencer’s
                          audience is. It is calculated as likes, comments etc.
                          ÷ followers. The average Instagram engagement rate is
                          2%.
                        </span>
                      }
                    >
                      <span className='inline-block  opacity-80 hover:opacity-70'>
                        <BsInfoCircleFill size={15} />
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            {/* Audience details by followers */}
            <div className='px-4'>
              <div className='mb-4 flex items-center'>
                <BsPeople />
                <span className='pl-1 font-semibold'>
                  Audience Details by Followers
                </span>
                <Tooltip
                  placement='top'
                  className='ml-1.5'
                  overlay={
                    <span className='inline-block text-center'>
                      This is what the audience of this profile looks like. To
                      get a more detailed overview, Click “View full report”
                      above.
                    </span>
                  }
                >
                  <span className='inline-block  opacity-80 hover:opacity-70'>
                    <BsInfoCircleFill size={15} />
                  </span>
                </Tooltip>
              </div>
              <div className='flex space-x-2'>
                <div className='basis-1/2'>
                  <div className='mx-auto flex basis-1/2 justify-center border border-white/20'>
                    <div className='p-3  py-4 text-center'>
                      <BsPersonX style={{ margin: 'auto' }} color='#7a0fff' />
                      <span className='mt-1.5 block text-2xl font-semibold'>
                        {(
                          100 -
                          influencerReportData?.profile?.audience?.credibility *
                            100
                        ).toFixed(2)}
                        %
                      </span>
                      <span className='mb-0.5 flex items-center'>
                        <small className='mt-1 block text-sm text-white text-opacity-80'>
                          Fake Followers
                        </small>

                        <Tooltip
                          placement='top'
                          className='ml-2'
                          overlay={
                            <span className='inline-block text-center'>
                              Does this influencer have a real audience? More
                              than 50% of fake followers is typically a sign of
                              fraud.
                            </span>
                          }
                        >
                          <span className='inline-block  opacity-80 hover:opacity-70'>
                            <BsInfoCircleFill size={15} />
                          </span>
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                  <div className='mt-3 '>
                    <div className=' flex border border-white/20 '>
                      <div className='w-full p-3  py-3'>
                        <h3 className='text-sm uppercase'>
                          Gender percentage
                          <Tooltip
                            placement='top'
                            className='ml-2'
                            overlay={
                              <span className='inline-block text-center'>
                                What gender categories does the influencer
                                reach?
                              </span>
                            }
                          >
                            <span className='inline-block  opacity-80 hover:opacity-70'>
                              <BsInfoCircleFill size={15} />
                            </span>
                          </Tooltip>
                        </h3>
                        <div
                          style={{
                            background: `conic-gradient(
                                  #7a5cff 0,
                                  #7a5cff ${
                                    influencerReportData?.profile?.audience
                                      ?.genders &&
                                    influencerReportData?.profile?.audience
                                      ?.genders[0]?.weight * 100
                                  }%,

                                   #a722f4 0,
                                   #a722f4 100%
                              )`,
                          }}
                          className='mt-2.5 h-[90px] w-[90px] rounded-full'
                        ></div>
                        <ul className='mt-4 '>
                          {influencerReportData?.profile?.audience?.genders?.map(
                            (gender) => (
                              <li
                                key={gender.code}
                                className='mb-1 flex items-center justify-between'
                              >
                                <div className='flex items-center text-xs'>
                                  {gender.code === 'FEMALE' ? (
                                    <span className='mr-1 block h-2 w-2 rounded-full bg-purple text-[#ccc] '></span>
                                  ) : (
                                    <span className='mr-1 block h-2 w-2 rounded-full bg-pinkLight text-[#ccc] '></span>
                                  )}
                                  {gender.code}
                                </div>
                                <div className='text-xs text-[#c0c0c0]'>
                                  {(gender.weight * 100).toFixed(2)}%
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='basis-1/2'>
                  <div className='flex basis-1/2 border border-white/20 '>
                    <div className='w-full p-3 pt-2 pb-2'>
                      <h3 className='flex items-center text-sm'>
                        Location by Country
                        <Tooltip
                          placement='top'
                          className='ml-2'
                          overlay={
                            <span className='inline-block text-center'>
                              This indicates the countries the influencer’s
                              audience is located in.
                            </span>
                          }
                        >
                          <span className='inline-block  opacity-80 hover:opacity-70'>
                            <BsInfoCircleFill size={15} />
                          </span>
                        </Tooltip>
                      </h3>

                      <ul className='mt-3.5'>
                        {influencerReportData?.profile?.audience?.geoCountries?.map(
                          (coutry, index) =>
                            index < 3 && (
                              <li
                                key={coutry.name}
                                className='relative mb-5 flex items-center justify-between after:absolute after:-bottom-1.5 after:block after:h-1 after:w-full after:rounded-full  after:bg-white/50'
                              >
                                <div className='flex items-center text-xs'>
                                  {coutry.name}
                                </div>
                                <div className='text-xs text-[#c0c0c0]'>
                                  {(coutry.weight * 100).toFixed(2)}%
                                </div>
                                <span
                                  style={{
                                    width: coutry.weight * 100 + '%',
                                  }}
                                  className='absolute -bottom-1.5 z-10 block h-1 rounded-full bg-pink'
                                ></span>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className='mt-2 flex basis-1/2 border border-white/20'>
                    <div className='w-full p-3 pt-2 pb-2'>
                      <h3 className='flex items-center text-sm'>
                        Location by City
                        <Tooltip
                          placement='top'
                          className='ml-2'
                          overlay={
                            <span className='inline-block text-center'>
                              This indicates the cities the influencer’s
                              audience is located in.
                            </span>
                          }
                        >
                          <span className='inline-block  opacity-80 hover:opacity-70'>
                            <BsInfoCircleFill size={15} />
                          </span>
                        </Tooltip>
                      </h3>

                      <ul className='mt-3.5'>
                        {influencerReportData?.profile?.audience?.geoCities?.map(
                          (city, index) =>
                            index < 3 && (
                              <li
                                key={city.index}
                                className='relative mb-5 flex items-center justify-between after:absolute after:-bottom-1.5 after:block after:h-1 after:w-full after:rounded-full  after:bg-white/50'
                              >
                                <div className='flex items-center text-xs'>
                                  {city.name}
                                </div>
                                <div className='text-xs text-[#c0c0c0]'>
                                  {(city.weight * 100).toFixed(2)}%
                                </div>
                                <span
                                  style={{
                                    width: city.weight * 100 + '%',
                                  }}
                                  className='absolute -bottom-1.5 z-10 block h-1 rounded-full bg-pink'
                                ></span>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-3 flex justify-center border border-white/20'>
                <div className='w-full p-3'>
                  <h3 className='text-sm '>
                    Age and Gender Split
                    <Tooltip
                      placement='top'
                      className='ml-2'
                      overlay={
                        <span className='inline-block text-center'>
                          What gender categories does the influencer reach?
                        </span>
                      }
                    >
                      <span className='inline-block  opacity-80 hover:opacity-70'>
                        <BsInfoCircleFill size={15} />
                      </span>
                    </Tooltip>
                  </h3>
                  <div className='mt-2 flex flex-row justify-around'>
                    {influencerReportData?.profile?.audience?.gendersPerAge?.map(
                      (genderPerAge, index) => (
                        <div key={index} className='max-w-[65px] basis-1/5 '>
                          <div className='flex h-[110px]  justify-between'>
                            <div className='col flex flex-col-reverse text-center text-[10px]'>
                              <span
                                style={{
                                  height:
                                    Math.floor(genderPerAge.female * 100) +
                                    1 +
                                    '%',
                                }}
                                className='mx-auto w-1.5 rounded-md bg-purple'
                              ></span>
                              <span>
                                {(genderPerAge.female * 100).toFixed(2)}%
                              </span>
                            </div>
                            <div className='col flex flex-col-reverse text-center text-[10px]'>
                              <span
                                style={{
                                  height:
                                    Math.floor(genderPerAge.male * 100) +
                                    1 +
                                    '%',
                                }}
                                className='mx-auto w-1.5 rounded-md bg-pinkLight'
                              ></span>
                              <span>
                                {(genderPerAge.male * 100).toFixed(2)}%
                              </span>
                            </div>
                          </div>

                          <span className='mt-1.5 block text-center text-xs'>
                            {genderPerAge.code}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className='mt-3 flex w-full justify-center border border-white/20'>
                <div className='w-full p-3'>
                  <h3 className='text-sm '>
                    Lookalikes
                    <Tooltip
                      placement='top'
                      className='ml-2'
                      overlay={
                        <span className='inline-block text-center'>
                          These are the topics the audience posts about and
                          interacts with the most often.
                        </span>
                      }
                    >
                      <span className='inline-block  opacity-80 hover:opacity-70'>
                        <BsInfoCircleFill size={15} />
                      </span>
                    </Tooltip>
                  </h3>
                  <ul className='mt-3'>
                    {influencerReportData?.profile?.lookalikes?.map(
                      (item, index) =>
                        index < 4 && (
                          <li
                            key={item.userId}
                            className='mb-3 flex items-center'
                          >
                            <span
                              style={{
                                backgroundImage: `url(${item.picture})`,
                              }}
                              className='block h-9 w-9 rounded-full bg-cover'
                            ></span>
                            <a
                              className='ml-3 text-[13px]'
                              href='http://www.instagram.com'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <span className='text-base'>{item.fullname}</span>
                              <span className='block text-pink'>
                                {item.username}
                              </span>
                            </a>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              </div>
              <div className='mt-3 flex justify-center border border-white/20'>
                <div className='w-full p-3'>
                  <h3 className='text-sm '>
                    Audience Interests
                    <Tooltip
                      placement='top'
                      className='ml-2'
                      overlay={
                        <span className='inline-block text-center'>
                          These are the topics the audience posts about and
                          interacts with the most often.
                        </span>
                      }
                    >
                      <span className='inline-block  opacity-80 hover:opacity-70'>
                        <BsInfoCircleFill size={15} />
                      </span>
                    </Tooltip>
                  </h3>
                  <ul className='mt-2.5 text-[13px] text-[#f2e3f9] text-opacity-80'>
                    {influencerReportData?.profile?.interests?.map(
                      (interest, index) =>
                        index < 5 && (
                          <li className='mb-1' key={interest.id}>
                            {interest.name}
                          </li>
                        )
                    )}
                  </ul>
                </div>
              </div>
              <div className='mt-3 flex w-full  justify-center border border-white/20'>
                <div className='w-full p-3'>
                  <h3 className='text-sm '>
                    Popular # and @
                    <Tooltip
                      placement='top'
                      className='ml-2'
                      overlay={
                        <span className='inline-block text-center'>
                          These are the topics the audience posts about and
                          interacts with the most often.
                        </span>
                      }
                    >
                      <span className='inline-block  opacity-80 hover:opacity-70'>
                        <BsInfoCircleFill size={15} />
                      </span>
                    </Tooltip>
                  </h3>
                  <ul className='mt-3 flex flex-wrap text-[13px] font-light'>
                    {influencerReportData?.profile?.hashtags?.map(
                      (hashtag, index) =>
                        index < 5 && (
                          <li
                            key={index}
                            title={hashtag.tag}
                            className='mb-1.5 mr-1.5 inline rounded-full bg-pinkLight px-2'
                          >
                            #{hashtag.tag}
                          </li>
                        )
                    )}
                  </ul>
                  <ul className='mt-3 flex flex-wrap text-[13px] font-light'>
                    {influencerReportData?.profile?.mentions?.map(
                      (mention, index) =>
                        index < 5 && (
                          <li
                            key={index}
                            title={mention.tag}
                            className='mb-1.5 mr-1.5 inline rounded-full bg-pinkLight px-2'
                          >
                            @{mention.tag}
                          </li>
                        )
                    )}
                  </ul>
                </div>
              </div>

              <div className='mt-3 flex justify-center '>
                <div className='w-full'>
                  <h3 className='flex items-center text-sm '>
                    <FaInstagram />
                    <span className='ml-2'>Popular Posts</span>
                    <Tooltip
                      placement='top'
                      className='ml-2 '
                      overlay={
                        <span className='inline-block text-center'>
                          A preview of the most liked content the influencer has
                          posted.
                        </span>
                      }
                    >
                      <span className='inline-block  opacity-80 hover:opacity-70'>
                        <BsInfoCircleFill size={15} />
                      </span>
                    </Tooltip>
                  </h3>
                  <ul className='row -mx-1.5 mt-3 flex flex-wrap'>
                    {influencerReportData?.profile?.popularPosts.map(
                      (post, index) =>
                        index < 8 && (
                          <li key={post.id} className='basis-1/2 p-1.5 '>
                            <span className='relative block  overflow-hidden border border-white/20 bg-secondary'>
                              <span className='block p-1.5 px-2 text-sm text-white/90'>
                                {fromatDate(post.created)}
                              </span>
                              <a
                                href={post.url}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{
                                  backgroundImage: `url(${post.thumbnail})`,
                                }}
                                className='block h-32 w-full transform bg-cover bg-center duration-300 hover:scale-105 hover:opacity-80'
                              ></a>

                              <div className=' left-0 bottom-0 right-0 z-10 border-t border-white/20'>
                                <div className='flex space-x-3 bg-secondary px-2 py-1'>
                                  <span className='flex items-center text-xs'>
                                    <FaHeart color='#ff4d4a' className='mr-1' />
                                    {formatNumber(post.likes)}
                                  </span>
                                  <span className='flex items-center text-xs'>
                                    <BsChatRightText
                                      color='#7a5cff'
                                      className='mr-1'
                                    />{' '}
                                    {formatNumber(post.comments)}
                                  </span>
                                </div>
                              </div>
                            </span>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    )
  );
};

export default InstaInfluencerOverview;
