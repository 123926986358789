import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// import {
//   resetUserPassword,
//   sendUserOtp,
// } from '../../redux/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import GetOtpForm from './GetOtpForm';
import NewPasswordForm from './NewPasswordForm';

const ResetPass = ({ email }) => {
  const history = useNavigate();

  const [showRestPassForm, setShowRestForm] = useState(false);

  const dispatch = useDispatch();

  const userOtp = useSelector((state) => state.userOtp);
  const userInfo = useSelector((state) => state.login);
  const userResetPass = useSelector((state) => state.userResetPass);
  const { passRested } = userResetPass;
  const { otpSend } = userOtp;

  // const redirect = location.search ? location.search.split("=")[1] : "/";
  const redirect = '/';

  // useEffect(() => {
  //   if (userInfo) {
  //     history(redirect);
  //   }
  // }, [history, userInfo, redirect]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (otpSend) {
        setShowRestForm(true);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [otpSend]);

  return (
    <>{showRestPassForm && otpSend ? <NewPasswordForm /> : <GetOtpForm />}</>
  );
};

export default ResetPass;
