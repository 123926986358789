import React from 'react';
import { FaLinkedin, FaTelegram, FaTwitter, FaYoutube } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='bg-secondary py-8'>
      <div className='container'>
        <div className='flex flex-col-reverse justify-between  sm:flex-row'>
          <p className='mt-3 text-xs text-white/80 sm:text-sm'>
            Copyright © {new Date().getFullYear()}, Lovefy.com Inc. All rights
            reserved.
          </p>
          <div className='flex items-center sm:justify-start'>
            <h2 className='mr-2'>Follow us:</h2>
            <ul className='flex space-x-3'>
              <li>
                <a
                  className='hover:text-pink'
                  href='https://www.youtube.com/channel/UCGw-wE42BvSP3Gu1zmlISrg'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaYoutube />
                </a>
              </li>
              <li>
                <a
                  className='hover:text-pink'
                  href='https://twitter.com/LovePulseChain'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a
                  className='hover:text-pink'
                  href='https://t.me/loveiocommunity'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaTelegram />
                </a>
              </li>
              <li>
                <a
                  className='hover:text-pink'
                  href='https://www.linkedin.com/company/love-crypto/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaLinkedin />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
