import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiAlertCircle } from 'react-icons/fi';
import Logo from '../../assets/img/logo.png';

// import { sendUserOtp } from '../../redux/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { sendOtp } from '../../redux/features/auth/otp.feature';

const GetOtpForm = () => {
  const loginUser = useSelector((state) => state.login);
  const history = useNavigate();
  const [email, setEmail] = useState(
    loginUser?.data?.success ? loginUser?.data?.email : ''
  );
  const [successAlert, setSuccessAlert] = useState(false);
  const [SuccessAlertMsg, setSuccessAlertMsg] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const dispatch = useDispatch();

  const userOtp = useSelector((state) => state.userOtp);
  const userInfo = useSelector((state) => state.login);
  const { otpSend, error, loading } = userOtp;

  // // const redirect = location.search ? location.search.split("=")[1] : "/";
  const redirect = '/';

  // useEffect(() => {
  //   if (userInfo) {
  //     history(redirect);
  //   }
  // }, [history, userInfo, redirect]);
  useEffect(() => {
    if (error) {
      setAlert(true);
      setAlertMsg(error);
    } else {
      if (otpSend) {
        setSuccessAlert(true);
        setSuccessAlertMsg('OTP is successfully sent');
      }
    }

    const interval = setInterval(() => {
      setAlert(false);
      setSuccessAlert(false);
    }, 3000);
    return () => clearInterval(interval);
  }, [error, otpSend]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setAlert(true);
      setAlertMsg('Please enter your email address');
    } else {
      dispatch(sendOtp({ email }));
    }
  };
  return (
    <div className='w-full max-w-[500px]'>
      <div className='text-center'>
        <Link
          to='/'
          className='mb-6 inline-flex items-center text-2xl font-semibold text-secondary dark:text-white'
        >
          <img className='mr-2 w-[200px]' src={Logo} alt='logo' />
        </Link>
      </div>
      {/* {loading && <h1>loading</h1>} */}
      <form
        onSubmit={handleSubmit}
        className='mx-auto w-full rounded-lg border border-white/40 bg-primary px-6 py-8 md:p-10'
      >
        <h1 className='text-tertiary mb-8 text-3xl font-semibold'>
          Genrate OTP
        </h1>

        <div className='relative'>
          <div className='mb-1 flex justify-between text-sm'>
            <label
              htmlFor='name'
              className='text-white-800 mb-2 block text-sm font-medium '
            >
              Email
            </label>
            <div className='text-tertiary/60'>
              Go to the
              <Link className='ml-2 font-semibold text-pink' to='/login'>
                Login ?
              </Link>
            </div>
          </div>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type='email'
            id='email'
            name='email'
            className='block w-full rounded-md bg-gray-50 p-2.5 text-secondary focus:border focus:border-none focus:outline-pink  sm:text-sm'
          />
        </div>
        {alert && !loading ? (
          <div className='relative mt-3'>
            <div className='flex items-center space-x-2 rounded  py-1.5 text-red-700'>
              <FiAlertCircle className='text-red-700' />
              <span className='text-sm'>{alertMsg}</span>
            </div>
          </div>
        ) : null}
        {successAlert && !loading ? (
          <div className='relative mt-3'>
            <div className='flex items-center space-x-2 rounded py-1.5 text-green-700'>
              <FiAlertCircle className='text-green-700' />
              <span className='text-sm'>{SuccessAlertMsg}</span>
            </div>
          </div>
        ) : null}
        {/* {alert && (

          <div className="relative mt-3">
            <div className="bg-orange-300 flex space-x-2 px-4 py-3 items-center rounded">
              <FiAlertCircle className="text-orange-700" />
              <span className="text-sm">Please enter your email</span>
            </div>
          </div>

      )} */}

        <div className='mt-6 text-center'>
          {loading ? (
            <button
              disabled
              className='inline-flex rounded-full border-0 bg-indigo-600 bg-gradient-to-r from-pinkLight from-purple to-purple to-pinkLight py-2 px-6 text-base text-white transition duration-500 focus:outline-none md:py-2 md:px-8'
            >
              Processing wait...
            </button>
          ) : (
            <button
              type='submit'
              className='inline-flex rounded-full border-0 bg-gradient-to-r from-pinkLight to-purple py-2 px-6 text-base text-white transition duration-500 hover:bg-indigo-600 hover:from-purple hover:to-pinkLight focus:outline-none md:py-2 md:px-8'
            >
              Get OTP
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default GetOtpForm;
