import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import LoginForm from '../components/login/LoginForm';

const Login = () => {
  const userInfo = useSelector((state) => state.login);
  const loginPage = userInfo?.data?.success ? (
    <Navigate to='/' replace={true} />
  ) : (
    <div>
      <LoginForm />
    </div>
  );
  return loginPage;
};

export default Login;
