import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  loading: false,
  data: [],
  errorMessage: null,
};

export const getTags = createAsyncThunk(
  'tags/getTags',
  async ({ platform, query }, { getState }) => {
    const { jsontoken } = getState()?.login?.data;
    const config = {
      headers: { Authorization: `Bearer ${jsontoken}` },
    };

    let dataUrl = `${
      process.env.BACKEND_URL
    }/influencers/${platform}/hashtags?search=${query.replace('#', '')}`;
    let response = await axios.get(dataUrl, config);
    return response.data;
  }
);

const tagsListSlice = createSlice({
  name: 'tags',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getTags.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getTags.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getTags.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = `Oops! Something goes wrong!`;
      });
  },
});
export default tagsListSlice.reducer;
