import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';

const initialState = {
  loading: false,
  data: [],
  errorMessage: null,
};
let cancelToken;
export const getInfluencerReport = createAsyncThunk(
  'influencers/getInfluencerReport',
  async (argu, { getState }) => {
    const { jsontoken } = getState()?.login?.data;
    const config = {
      headers: { Authorization: `Bearer ${jsontoken}` },
    };
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel();
    }
    cancelToken = axios.CancelToken.source();
    try {
      const { id, platform } = argu;
      let dataUrl = `https://influencerapi.lovefy.com/api/influencers/${platform}/report/${id}`;
      let response = await axios.get(dataUrl, config, {
        cancelToken: cancelToken.token,
      });
      return response.data;
    } catch (error) {
      // console.log("d")
    }
  }
);

const influencerReportSlice = createSlice({
  name: 'influencerReport',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getInfluencerReport.pending, (state, action) => {
        state.loading = true;
        state.errorMessage = '';
      })
      .addCase(getInfluencerReport.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload;
        } else {
          state.loading = true;
          state.data = action.payload || {};
        }
      })
      .addCase(getInfluencerReport.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = `Oops! Something goes wrong!`;
      });
  },
});
export default influencerReportSlice.reducer;
