import React, { useEffect, useState } from 'react';
import Logo from '../../assets/img/logo.png';
import { Link, redirect, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createUser } from '../../redux/features/auth/singup.feature';
const SignupForm = () => {
  const dispatch = useDispatch();
  const { loading, errorMessage, data } = useSelector((state) => state.signup);
  const history = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [alert, setAlert] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email && password && name) {
      if (password !== confirmPassword) {
        setAlert('confirm password please');
      } else {
        setAlert('');
        dispatch(
          createUser({
            name,
            email,
            password,
          })
        );
      }
    } else {
      setAlert('All fields are required');
    }
  };
  useEffect(() => {
    let redirect;
    if (data?.data) {
      setName('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      redirect = setTimeout(() => {
        history('/verify-email');
      }, 2000);
    }

    return () => {
      clearTimeout(redirect);
    };
  }, [data]);

  return (
    <div>
      <section className='min-h-screen bg-secondary'>
        <div className='mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-screen lg:py-0'>
          <Link
            to='/'
            className='mb-6 flex items-center text-2xl font-semibold text-secondary dark:text-white'
          >
            <img className='mr-2 w-[200px]' src={Logo} alt='logo' />
          </Link>

          <div className='w-full rounded-lg border border-white/50 bg-primary shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md md:mt-0 xl:p-0'>
            <div className='space-y-4 p-6 sm:p-8 md:space-y-6'>
              <h1 className='text-xl font-bold leading-tight tracking-tight text-white md:text-2xl'>
                Create an account
              </h1>
              <p className='mt-0 capitalize text-red-500'>{alert}</p>
              {data?.success && data?.data ? (
                <p className='text-green-500'>Sucessfully Account Created!</p>
              ) : null}
              {data?.success && !data?.data ? (
                <p className='text-yellow-500'>This email Already used!</p>
              ) : null}
              <form
                onSubmit={handleSubmit}
                className='space-y-4 md:space-y-6'
                action='#'
              >
                <div>
                  <label
                    htmlFor='name'
                    className='text-white-800 mb-2 block text-sm font-medium '
                  >
                    Full name
                  </label>
                  <input
                    type='text'
                    name='name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    id='name'
                    className='block w-full rounded-lg  bg-gray-50 p-2.5 text-secondary focus:border focus:border-none focus:outline-pink  sm:text-sm'
                    placeholder='Your name'
                    required=''
                  />
                </div>
                <div>
                  <label
                    htmlFor='email'
                    className='text-white-800 mb-2 block text-sm font-medium '
                  >
                    Your email
                  </label>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type='email'
                    name='email'
                    id='email'
                    className='block w-full rounded-lg  bg-gray-50 p-2.5 text-secondary focus:border focus:border-none focus:outline-pink  sm:text-sm'
                    placeholder='example@example.com'
                    required=''
                  />
                </div>
                <div>
                  <label
                    htmlFor='password'
                    className='text-white-800 mb-2 block text-sm font-medium '
                  >
                    Password
                  </label>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type='password'
                    name='password'
                    id='password'
                    placeholder='••••••••'
                    className='block w-full rounded-lg  bg-gray-50 p-2.5 text-secondary focus:border focus:border-none focus:outline-pink  sm:text-sm'
                    required=''
                  />
                </div>
                <div>
                  <label
                    htmlFor='confirm-password'
                    className='text-white-800 mb-2 block text-sm font-medium '
                  >
                    Confirm password
                  </label>
                  <input
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type='password'
                    name='confirm-password'
                    id='confirm-password'
                    placeholder='••••••••'
                    className='block w-full rounded-lg  bg-gray-50 p-2.5 text-secondary focus:border focus:border-none focus:outline-pink  sm:text-sm'
                    required=''
                  />
                </div>
                {/* <div className='flex items-start'>
                  <div className='flex items-center h-5'>
                    <input
                      id='terms'
                      aria-describedby='terms'
                      type='checkbox'
                      className='w-4 h-4 border border-gray-300 rounded focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 bg-gray-50 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800'
                      required=''
                    />
                  </div>
                  <div className='ml-3 text-sm'>
                    <label
                      htmlFor='terms'
                      className='text-[#f2e3f9]0 font-light'
                    >
                      I accept the{' '}
                      <a
                        className='font-medium text-pink hover:underline'
                        href='#'
                      >
                        Terms and Conditions
                      </a>
                    </label>
                  </div>
                </div> */}
                <div className='text-center'>
                  {loading ? (
                    <button
                      disabled
                      className='mx-auto inline-block cursor-not-allowed rounded-lg border border-pinkLight  bg-gradient-to-r from-pinkLight to-purple py-1.5 px-6 text-white transition duration-300 hover:border-transparent focus:outline-none md:py-3 md:px-8'
                    >
                      Processing...
                    </button>
                  ) : (
                    <button
                      type='submit'
                      className='mx-auto inline-block rounded-lg border border-pinkLight  py-1.5 px-6 text-white transition duration-300 hover:border-transparent hover:bg-gradient-to-r hover:from-pinkLight hover:to-purple focus:outline-none md:py-3 md:px-8'
                    >
                      Sign up
                    </button>
                  )}
                </div>
                <p className='text-sm font-light text-[#f2e3f9]'>
                  Already have an account?{' '}
                  <Link
                    to='/login'
                    className='font-medium text-pink hover:underline'
                  >
                    Login here
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignupForm;
