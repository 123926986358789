import axios from 'axios';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import React, { useEffect, useRef, useState } from 'react';
import { BsInfoCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { languageWeight } from '../data/staticFilters';
import {
  clearYTFilters,
  updateYoutubeFilters,
} from '../redux/features/filters.feature';
import { getLocations } from '../redux/features/locations/locations.feature';
import { getTags } from '../redux/features/tags/tags.feature';
import { getYTInfluencers } from '../redux/features/youtubeInfluencers-list.feature';
import AudienceGenderFilter from './insta/AudienceGenderFilter';
import SingleSelect from './SingleSelect';
import CustomMultiSelect from './utils/CustomMultiSelect';
import YTAudienceAge from './youtube/YTAudienceAge';
import YTAvgViews from './youtube/YTAvgViews';
import YTGenderFilter from './youtube/YTGenderFilter';
import YTInfluencersList from './youtube/YTInfluencersList';
import YTSubFilter from './youtube/YTSubFilter';
import YTTopicsFilter from './youtube/YTTopicsFilter';
import YTUsernameFilter from './youtube/YTUsernameFilter';
const YTFilterForm = () => {
  const dispath = useDispatch();
  let locationsState = useSelector((state) => {
    return state['locations'];
  });
  const { data, loading } = locationsState;

  let YTFiltersState = useSelector((state) => state.filters.youtubeFilters);
  const { jsontoken } = useSelector((state) => state?.login?.data);
  const config = {
    headers: { Authorization: `Bearer ${jsontoken}` },
  };

  const [inputValue, setValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);

  const [languages, setLanguages] = useState([]);

  // handle input change event
  const handleInputChange = (value) => {
    setValue(value);
  };

  // handle selection
  const handleChange = (value) => {
    dispath(
      updateYoutubeFilters({
        influencer: {
          ...YTFiltersState.influencer,
          // location: locationIds,
          language: value,
        },
        audience: {
          ...YTFiltersState.audience,
        },
      })
    );
  };
  const {
    data: influencersData,
    loading: influencersLoading,
    errorMessage: influencersError,
  } = useSelector((state) => state.youtubeInfluencers);

  useEffect(() => {
    !influencersData.lookalikes?.length &&
      dispath(
        getYTInfluencers({
          platform: 'youtube',
          filters: YTFiltersState,
          page: YTFiltersState.page,
        })
      );

    axios({
      method: 'get',
      url: `https://influencerapi.lovefy.com/api/influencers/youtube/languages`,
      ...config,
    })
      .then(function (response) {
        setLanguages(response.data.languages);
        // setLoading(false);
      })
      .catch((e) => console.log(e));
  }, []);

  // load options using API call
  const loadOptions = (inputValue) => {
    dispath(getLocations(inputValue));
  };

  const locationRef = useRef();
  const [options_, setOptions] = useState([]);
  // const [loading, setLoading] = useState(false);
  const handleLocations = async (value) => {
    dispath(getLocations({ platform: 'youtube', query: value }));
  };
  // get lags
  const handleTags = async (value) => {
    dispath(getTags(value));
  };

  const onLocationChange = (arr) => {
    // const locationIds = arr.map((_) => _.id);
    dispath(
      updateYoutubeFilters({
        influencer: {
          ...YTFiltersState.influencer,
          // location: locationIds,
          location: [...arr],
        },
        audience: {
          ...YTFiltersState.audience,
        },
      })
    );
  };

  const handelLangChange = (lang) => {
    dispath(
      updateYoutubeFilters({
        influencer: { ...YTFiltersState.influencer },
        audience: {
          ...YTFiltersState.audience,
          language: lang,
          languageWeight: languageWeight[3],
        },
      })
    );
  };
  const handleGetInfluencers = () => {
    dispath(
      updateYoutubeFilters({
        influencer: YTFiltersState.influencer,
        audience: YTFiltersState.audience,
        page: 0,
      })
    );

    const inf = dispath(
      getYTInfluencers({
        platform: 'youtube',
        filters: YTFiltersState,
        page: 0,
      })
    );
    if (influencersLoading === 'pending') {
      return inf.abort();
    }
  };
  const handleClearAllFilters = () => {
    dispath(clearYTFilters());

    dispath(
      getYTInfluencers({
        platform: 'youtube',
        filters: null,
        page: 0,
      })
    );
  };
  return (
    <div>
      <div className='bg-primary p-4'>
        {/* <h1 className='mb-4 text-2xl text-white'>Influencer filters</h1> */}
        <div className='flex flex-row flex-wrap  '>
          <div className='mb-3 flex-auto md:basis-1/3 lg:basis-1/3 '>
            <p className='mb-1 text-sm text-white text-opacity-90'>
              Influencer location
            </p>
            <CustomMultiSelect
              multi={true}
              checkbox={true}
              loading={loading}
              options={data.locations}
              setRef={locationRef}
              handleSearch={handleLocations}
              placeholder='Search Influencer location'
              onSelect={(arr) => onLocationChange(arr)}
              onRemove={(arr) => onLocationChange(arr)}
              selectedValues={YTFiltersState.influencer.location}
            />
          </div>
          <div className='mb-3  basis-full sm:basis-1/2 sm:pl-2 md:basis-1/4'>
            <YTSubFilter />
          </div>
          <div className='mb-3  ml-0 basis-full sm:basis-1/2 md:basis-1/4 md:pl-2'>
            <YTAvgViews />
          </div>
          <div className='mb-3 flex-auto sm:pl-2'>
            <YTGenderFilter />
          </div>

          <div className='ms:pl-0 mb-3 basis-1/2 pl-2 md:basis-1/3 lg:basis-1/3 '>
            <YTTopicsFilter />
          </div>

          <div className='mb-3 basis-1/2 pl-2 md:basis-1/3  lg:basis-1/3'>
            <p className='mb-1 text-sm text-white text-opacity-90'>Language</p>
            <SingleSelect
              isSearchable={true}
              options={languages}
              onInputChange={handleInputChange}
              onChange={(v) => handleChange(v)}
              getOptionLabel={(v) => v.name}
              getOptionValue={(v) => v.code}
              name='influencer lang'
              value={YTFiltersState.influencer.language}
            />
          </div>
        </div>
      </div>
      <div className='mt-2 bg-primary p-4'>
        {/* <h1 className='mb-4 text-2xl text-white'>Audience filters</h1> */}
        <div className='flex flex-row flex-wrap  '>
          <div className='mb-3 flex-auto basis-1/2 md:basis-1/3 lg:basis-1/3'>
            <p className='mb-1 flex items-center text-sm text-white text-opacity-90'>
              Audience location
              <Tooltip
                placement='top'
                overlay={
                  <span className='inline-block text-center'>
                    To narrow down your search results, to find the best
                    matching influencers for you, e.g. finding influencers whose
                    most audience is in Berlin and most are female.
                  </span>
                }
              >
                <span className='ml-2 hover:opacity-70'>
                  <BsInfoCircleFill size={15} />
                </span>
              </Tooltip>
            </p>
            <CustomMultiSelect
              selectedValues={YTFiltersState.audience.location}
              selectionLimit={2}
              multi={true}
              checkbox={true}
              loading={data.loading}
              options={data.locations}
              handleSearch={handleLocations}
              onSelect={(arr) =>
                dispath(
                  updateYoutubeFilters({
                    influencer: { ...YTFiltersState.influencer },
                    audience: {
                      ...YTFiltersState.audience,
                      location: [...arr],
                    },
                  })
                )
              }
              onRemove={(arr) =>
                dispath(
                  updateYoutubeFilters({
                    influencer: { ...YTFiltersState.influencer },
                    audience: {
                      ...YTFiltersState.audience,
                      location: arr,
                    },
                  })
                )
              }
              placeholder='Search Influencer location'
            />
          </div>

          <div className='mb-3 flex-auto basis-1/2 pl-2 sm:basis-auto'>
            <AudienceGenderFilter />
          </div>

          <div className='mb-3 basis-1/2 pl-0 md:basis-1/3 md:pl-2  lg:basis-1/3'>
            <p className='mb-1 flex items-center text-sm text-white text-opacity-90'>
              Age
              <Tooltip
                placement='top'
                overlay={
                  <span className='inline-block text-center'>
                    To narrow down your search results, to find the best
                    matching influencers for you, e.g. finding influencers whose
                    more than 30% of audience age group is '13-17' OR '18-24'.
                  </span>
                }
              >
                <span className='ml-2 hover:opacity-70'>
                  <BsInfoCircleFill size={15} />
                </span>
              </Tooltip>
            </p>
            <YTAudienceAge />
          </div>
          <div className='mb-3 basis-1/2 pl-2 md:basis-1/3 md:pl-0 lg:basis-1/3'>
            <p className='mb-1 flex items-center text-sm text-white text-opacity-90'>
              Language
              <Tooltip
                placement='top'
                overlay={
                  <span className='inline-block text-center'>
                    To narrow down your search results, to find the best
                    matching influencers for you, e.g. finding influencers whose
                    most audience's language is english.
                  </span>
                }
              >
                <span className='ml-2 hover:opacity-70'>
                  <BsInfoCircleFill size={15} />
                </span>
              </Tooltip>
            </p>
            <SingleSelect
              isClearable={true}
              value={YTFiltersState.audience.language}
              options={languages}
              // loadOptions={languages}
              onInputChange={handleInputChange}
              // onChange={(v) => handleChange(v)}
              getOptionLabel={(v) => v.name}
              getOptionValue={(v) => v.code}
              onChange={(lang) => handelLangChange(lang)}
              isSearchable={true}
            />
            {/* {YTFiltersState.audience.language && (
              <div className='mt-4 flex items-center justify-between'>
                <span className='text-white'>
                  {YTFiltersState.audience.language?.name}
                </span>
                <SingleSelect
                  getOptionLabel={(v) => v.label}
                  getOptionValue={(v) => v.value}
                  name='audience-lang-weight'
                  options={languageWeight}
                  onChange={(obj) => handleLangWeightChange(obj)}
                  selectedValue={YTFiltersState.audience.languageWeight}
                  value={YTFiltersState.audience.languageWeight}
                />
              </div>
            )} */}
          </div>
        </div>
      </div>
      <div className='mt-2 bg-primary p-4'>
        {/* <h1 className='mb-4 text-2xl text-white'>Audience filters</h1> */}
        <div className='flex flex-row flex-wrap  '>
          <div className='mb-3 flex-auto sm:pl-2'>
            <YTUsernameFilter />
          </div>
        </div>
      </div>
      <div className='flex flex-col justify-end space-y-3 border-t border-white/30 bg-primary px-4 py-5 sm:flex-row sm:space-y-0'>
        {influencersLoading === 'pending' ? (
          <button
            onClick={handleClearAllFilters}
            className=' inline-flex cursor-not-allowed rounded-lg border border-pinkLight  py-1.5 px-6 text-white transition duration-300 hover:border-transparent hover:bg-gradient-to-r hover:from-pinkLight hover:to-purple focus:outline-none md:py-3 md:px-8'
            disabled
          >
            Clear all filters
          </button>
        ) : (
          <button
            onClick={handleClearAllFilters}
            className='inline-flex rounded-lg border border-pinkLight py-1.5  px-6 text-white transition duration-300 hover:border-transparent hover:bg-gradient-to-r hover:from-pinkLight hover:to-purple focus:outline-none md:py-3 md:px-8 '
          >
            Clear all filters
          </button>
        )}
        {influencersLoading === 'pending' ? (
          <button
            onClick={handleGetInfluencers}
            className='inline-flex  cursor-not-allowed rounded-lg border border-transparent bg-gradient-to-r from-pinkLight to-purple py-1.5 px-6 text-white transition duration-300  hover:border-pinkLight hover:from-transparent hover:to-transparent focus:outline-none sm:ml-3 md:py-3 md:px-8'
            disabled
          >
            Find Influencers
          </button>
        ) : (
          <button
            onClick={handleGetInfluencers}
            className='inline-flex rounded-lg border border-transparent bg-gradient-to-r from-pinkLight to-purple py-1.5 px-6 text-white transition duration-300 hover:border-pinkLight  hover:from-transparent hover:to-transparent focus:outline-none sm:ml-3 md:py-3 md:px-8'
          >
            Find Influencers
          </button>
        )}
      </div>

      <div className='mt-4'>
        <YTInfluencersList />
      </div>
    </div>
  );
};

export default YTFilterForm;
