import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateInstaFilters } from '../../redux/features/filters.feature';
import { getTopics } from '../../redux/features/topics/topics.feature';
import CustomMultiSelect from '../utils/CustomMultiSelect';
import { BsInfoCircleFill } from 'react-icons/bs';
import Tooltip from 'rc-tooltip';
const InstaTopicsFilter = () => {
  const dispath = useDispatch();
  let instaFiltersState = useSelector((state) => state.filters.instaFilters);

  let topicsState = useSelector((state) => state['topics']);
  // get topics
  const handleTopics = async (value) => {
    dispath(getTopics({ platform: 'instagram', query: value }));
    // console.log(value);
  };

  const onChangeTopic = (arr) => {
    // const locationIds = arr.map((_) => _.id);
    dispath(
      updateInstaFilters({
        influencer: {
          ...instaFiltersState.influencer,
          // location: locationIds,
          topics: [...arr],
        },
        audience: {
          ...instaFiltersState.audience,
        },
      })
    );
  };
  return (
    <>
      <p className='mb-1 flex items-center text-sm text-white text-opacity-90'>
        Topics
        <Tooltip
          placement='top'
          overlay={
            <span className='inline-block text-center'>
              Search by topic clusters, influencers use in their posts. For
              example "#cars #audi @topgear" means "Writing about #cars and
              #audi, has a similar topic as @topgear account.
            </span>
          }
        >
          <span className='ml-2 hover:opacity-70'>
            <BsInfoCircleFill size={15} />
          </span>
        </Tooltip>
      </p>
      <CustomMultiSelect
        loading={topicsState.loading}
        options={topicsState.data.tags}
        handleSearch={handleTopics}
        placeholder='Search Topics'
        checkbox={false}
        isObject={false}
        multi={true}
        onSelect={onChangeTopic}
        onRemove={onChangeTopic}
        selectedValues={instaFiltersState.influencer.topics}
      />
    </>
  );
};

export default InstaTopicsFilter;
