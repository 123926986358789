import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';

const storedResults = JSON.parse(localStorage.getItem('influencers.youtube'));
const initialState = {
  loading: storedResults ? 'succeeded' : 'idle',
  data: storedResults ?? [],
  errorMessage: null,
};

export const getYTInfluencers = createAsyncThunk(
  'influencers/getYTInfluencers',
  async (argu, { getState, signal }) => {
    const { jsontoken } = getState()?.login?.data;
    const config = {
      headers: { Authorization: `Bearer ${jsontoken}` },
    };
    const { filters, platform, page } = argu;
    console.log(argu);

    let dataUrl = `https://influencerapi.lovefy.com/api/influencers/${platform}`;
    try {
      if (filters) {
        const influencersLocationIds = [];
        const audienceLocationIds = [];
        filters?.audience?.location?.map((lo) =>
          audienceLocationIds.push(lo.id)
        );
        filters?.influencer?.location?.map((lo) =>
          influencersLocationIds.push(lo.id)
        );

        let finalFilters = {
          filter: {
            influencer: {
              followers: {
                min: filters.influencer.followers?.min?.value || null,
                max:
                  (filters.influencer.followers?.max?.value > 2000000
                    ? null
                    : filters.influencer.followers?.max?.value) || null,
              },

              location: influencersLocationIds || [],
              language: filters.influencer.language?.code || null,

              gender: filters.influencer.gender?.value || null,
              relevance:
                [
                  ...filters.influencer?.topics?.map((topic) => '#' + topic),
                  ...filters.influencer?.usernames?.map(
                    (user) => user.username
                  ),
                ] || [],
              // hasYoutube: false,
              // textTags: textTags || [],

              views: {
                min: filters.influencer?.reelsPlays?.min?.value || null,
                max:
                  (filters.influencer.reelsPlays?.max?.value > 2000000
                    ? null
                    : filters.influencer.reelsPlays?.max?.value) || null,
              },
            },
            audience: {
              location: audienceLocationIds,
              age: filters.audience?.age.map((a) => a.value),
              // credibility: filters.audience?.credibility?.value || null,
              language: filters.audience?.language?.code || null,
              gender: filters.audience?.gender?.value || null,
            },
          },
          page,
          sort: {
            ...filters.sort,
          },
        };
        let response = await axios.post(dataUrl, finalFilters, config, {
          signal,
        });
        console.log(response.data);
        return response.data;
      } else {
        let response = await axios.post(
          dataUrl,
          {
            sort: {
              field: 'followers',
              direction: 'desc',
            },
            filter: {},
          },
          config
        );
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  },
  {
    condition: (argu, { getState, extra }) => {
      const { loading } = getState().youtubeInfluencers;

      if (loading === 'pending') {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  }
);

const YTinfluencerListSlice = createSlice({
  name: 'YTinfluencers',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getYTInfluencers.pending, (state, action) => {
        state.loading = 'pending';
        state.errorMessage = null;
      })
      .addCase(getYTInfluencers.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.data = action.payload;
        localStorage.setItem(
          'influencers.youtube',
          JSON.stringify(action.payload)
        );
        state.errorMessage = null;
      })
      .addCase(getYTInfluencers.rejected, (state, action) => {
        console.log(action);
        if (action.error.name === 'AbortError') {
          state.loading = 'pending';
          state.data = [];
          state.errorMessage = null;
        } else {
          state.loading = 'failed';
          state.data = [];
          localStorage.setItem('influencers.youtube', JSON.stringify([]));
          state.errorMessage = `Oops! Something goes wrong!`;
        }
      });
  },
});
export default YTinfluencerListSlice.reducer;
