import React from 'react';
import VerificationReq from '../components/verifyEmail/VerificationReq';

const EmailVerificationPage = () => {
  return (
    <div>
      <VerificationReq />
    </div>
  );
};

export default EmailVerificationPage;
