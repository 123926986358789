import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const initialState = {
  loading: false,
  data: userInfo ? userInfo : null,
  errorMessage: null,
};

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ email, password }) => {
    let url = `https://influencerapi.lovefy.com/api/users/login`;
    let response = await axios.post(url, { email, password });

    return response.data;
  }
);

const loginSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    clearLogin: function () {
      return initialState;
    },
    logout: function () {
      localStorage.removeItem('userInfo');
      return {
        loading: false,
        data: null,
        errorMessage: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        localStorage.setItem('userInfo', JSON.stringify(action.payload));
        state.data = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = `Oops! Something goes wrong!`;
      });
  },
});
export const { clearLogin, logout } = loginSlice.actions;
export default loginSlice.reducer;
