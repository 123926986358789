import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { genderOptionsArr } from '../../data/staticFilters';
import { updateTiktokFilters } from '../../redux/features/filters.feature';
import SingleSelect from '../SingleSelect';

const TTAudienceGenderFilter = () => {
  const dispath = useDispatch();
  const { influencer, audience } = useSelector(
    (state) => state.filters.tiktokFilters
  );
  return (
    <>
      <p className='mb-1 text-sm text-white text-opacity-90 '>Gender</p>

      <div className='flex space-x-2'>
        <SingleSelect
          className='w-full'
          options={genderOptionsArr}
          name='gender'
          placeholder='gender'
          onChange={(obj) =>
            dispath(
              updateTiktokFilters({
                audience: {
                  ...audience,
                  gender: obj,
                },
                influencer: {
                  ...influencer,
                },
              })
            )
          }
          value={audience.gender}
        />
      </div>
    </>
  );
};

export default TTAudienceGenderFilter;
