import React from 'react';
import VerifyEmail from '../components/verifyEmail/VerifyEmail';

const VerifyEmailPage = () => {
  return (
    <div>
      <VerifyEmail />
    </div>
  );
};

export default VerifyEmailPage;
