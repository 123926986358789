import Multiselect from 'multiselect-react-dropdown';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateYoutubeFilters } from '../../redux/features/filters.feature';
import { getUsers } from '../../redux/features/users/users.feature';
const YTUsernameFilter = () => {
  const dispath = useDispatch();
  let YTFiltersState = useSelector((state) => state.filters.youtubeFilters);
  const [setInputValue] = useState({
    username: '',
  });

  let usernameState = useSelector((state) => state['YTusers']);
  // console.log(usernameState)
  // const  [_users, setUsers] = useState([])
  const styles = {
    multiselectContainer: {
      // To change css for multiselect (Width,height,etc..)
    },
    searchBox: {
      // To change search box element look
      minHeight: 40,
    },
    inputField: {
      // To change input field position or margin
    },
    chips: {
      // To change css chips(Selected options)
    },
    optionContainer: {
      // To change css for option container
      background: '#260c33',
      fontSize: 14,
      border: 'none',
      borderRadius: 0,
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
    },
    option: {
      // To change css for dropdown options
    },
    groupHeading: {
      // To chanage group heading style
    },
  };

  const handleUsers = async (value) => {
    // console.log(usernameState.loading)
    dispath(getUsers(value.replace('@', '')));
    setInputValue({
      username: value.replace('@', ''),
    });
  };

  const onChangeUser = (arr) => {
    dispath(
      updateYoutubeFilters({
        influencer: {
          ...YTFiltersState.influencer,
          // location: locationIds,
          usernames: [...arr],
        },
        audience: {
          ...YTFiltersState.audience,
        },
      })
    );
  };

  return (
    <>
      <p className='mb-1 flex items-center text-sm text-white text-opacity-90'>
        Username
      </p>
      <Multiselect
        selectionLimit={2}
        style={styles}
        // options={_users}
        options={usernameState?.data?.users}
        onSearch={handleUsers}
        loading={usernameState.loading}
        handleSearch={handleUsers}
        isObject={true}
        displayValue='username'
        checkbox={false}
        placeholder='@username'
        // isObject={false}
        singleSelect={false}
        onSelect={onChangeUser}
        onRemove={onChangeUser}
        selectedValues={YTFiltersState.influencer.usernames}
      />
    </>
  );
};

export default YTUsernameFilter;
