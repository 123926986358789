import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  loading: false,
  passwordRested: false,
  error: null,
};
export const resetPass = createAsyncThunk(
  'auth/resetPassword',
  async ({ email, password, otp }) => {
    console.log(email, password);
    let url = `https://influencerapi.lovefy.com/api/users/changepass`;
    let response = await axios.post(url, { otp, email, password });
    return response.data;
  }
);

const resetPassSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(resetPass.pending, (state, action) => {
        state.loading = true;
        state.passwordRested = false;
        state.error = null;
      })
      .addCase(resetPass.fulfilled, (state, action) => {
        state.loading = false;
        state.passwordRested = true;
        state.error = null;
      })
      .addCase(resetPass.rejected, (state, action) => {
        state.loading = false;
        state.passwordRested = false;
        state.error = `Oops! Something goes wrong!`;
      });
  },
  reducers: {
    clearResetPass: function (state, aciton) {
      return initialState;
    },
  },
});
export const { clearResetPass } = resetPassSlice.actions;
export default resetPassSlice.reducer;
