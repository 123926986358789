import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import InstaInfluencerReport from '../components/insta/InstaInfluencerReport';
const pageStyle = `
  @page {
    size: 800mm 500mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: none;
    }
  }
`;
const PdfPrint = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Influe"
  });
  return (
    <>
      <div className='bg-secondary'>
        <InstaInfluencerReport ref={componentRef} pageStyle={pageStyle} />
        <button onClick={handlePrint}>Print this out!</button>
      </div>
    </>
  );
};

export default PdfPrint;
