export const formatNumber = (n) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
};

export const fromatDate = (date) => {
  var options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const created = new Date(date);
  return created.toLocaleString('en-US', options);
};

export const getPercentage = (number, percent) => percent/100 * (number)