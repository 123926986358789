import React from 'react';
import { ReactComponent as MailSentIcon } from '../../assets/img/mail-sent.svg';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
const VerifyEmail = () => {
  return (
    <div className='flex min-h-screen items-center justify-center bg-secondary '>
      <div className='container'>
        <div className='max-w-[1080px] rounded-xl bg-primary py-8 px-5'>
          <h2 className='text-center text-base font-light opacity-80'>
            Thanks for registering!
          </h2>

          <MailSentIcon className='mx-auto mt-10 max-h-[90px] transform transition duration-300 hover:scale-[1.1]' />

          <div className='mx-auto max-w-2xl px-4 pt-10 text-center'>
            <h2 className='mb-4 text-2xl font-bold'>
              {' '}
              Verify your email address.
            </h2>
            <p className='text-[#f2e3f9] opacity-90 '>
              Click on the link that has just been sent to your email account to
              verify your email and continue searching{' '}
              <strong>influencer</strong>.
            </p>

            <p className='mt-10'>
              <Link to='/' className='inline-flex items-center text-pink '>
                <FaArrowLeft className='mr-2' /> Back to Home
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
