import MultiSelect from 'multiselect-react-dropdown';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ageGroups } from '../../data/staticFilters';
import { updateYoutubeFilters } from '../../redux/features/filters.feature';
const YTAudienceAge = () => {
  const dispatch = useDispatch();
  const YTFiltersState = useSelector((state) => state.filters.youtubeFilters);
  // console.log(singleRef.current);
  const styles = {
    multiselectContainer: {
      // To change css for multiselect (Width,height,etc..)
    },
    searchBox: {
      // To change search box element look
      minHeight: 40,
    },
    inputField: {
      // To change input field position or margin
    },
    chips: {
      // To change css chips(Selected options)
    },
    optionContainer: {
      // To change css for option container
      background: '#260c33',
      fontSize: 14,
      border: 'none',
      borderRadius: 0,
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
    },
    option: {
      // To change css for dropdown options
    },
    groupHeading: {
      // To chanage group heading style
    },
  };
  return (
    <>
      <MultiSelect
        selectionLimit={2}
        style={styles}
        options={ageGroups}
        isObject={true}
        displayValue='label'
        showCheckbox={true}
        placeholder='Select Age'
        singleSelect={false}
        // showArrow={true}
        // selectedValueDecorator={function noRefCheck(e) {
        //   return <span className='hidden'>{e}</span>;
        // }}
        // onSelect={() => setSelectedValues()}
        onSelect={(arr) =>
          dispatch(
            updateYoutubeFilters({
              audience: {
                ...YTFiltersState.audience,
                age: [...arr],
              },
              influencer: {
                ...YTFiltersState.influencer,
              },
            })
          )
        }
        onRemove={(arr) =>
          dispatch(
            updateYoutubeFilters({
              audience: {
                ...YTFiltersState.audience,
                age: [...arr],
              },
              influencer: {
                ...YTFiltersState.influencer,
              },
            })
          )
        }
        selectedValues={YTFiltersState?.audience?.age}
      />
    </>
  );
};

export default YTAudienceAge;
