import React from 'react';
import { Link } from 'react-router-dom';
import BgImg from '../assets/img/bg2a.jpg';
import { ReactComponent as InstagramIcon } from '../assets/img/icons/instagram.svg';
import { ReactComponent as TiktokIcon } from '../assets/img/icons/tiktok.svg';
import { ReactComponent as YoutubeIcon } from '../assets/img/icons/youtube.svg';
const HeroSection = () => {
  return (
    <section
      style={{
        backgroundImage: 'url(' + BgImg + ')',
      }}
      className='body-font relative z-0 flex bg-cover bg-center bg-no-repeat text-gray-600 md:min-h-[700px]'
    >
      <div className='absolute top-0 bottom-0 left-0 right-0 z-0 bg-gradient-to-r from-secondary/70 to-primary/70'></div>
      <div className='container relative z-10 mx-auto -mt-4 flex flex-col items-center justify-center px-5 py-10 sm:mt-0 md:py-20 lg:py-24'>
        <div className='w-full text-center lg:w-2/3'>
          <div className='icono mx-auto flex max-w-[500px] scale-[0.9] transform items-center justify-center '>
            <div className='s-i y-i '>
              <YoutubeIcon />
            </div>
            <div className='s-i i-i mx-5'>
              <InstagramIcon />
            </div>
            <div className='s-i t-i'>
              <TiktokIcon />
            </div>
            {/* <img src={HeartIcon} /> */}
          </div>
          <h1 className='title-font mx-auto mb-5 max-w-[700px] text-3xl font-extrabold !leading-tight text-white/95 sm:text-4xl md:text-5xl'>
            You want your Brand or Business to be{' '}
            <span className='flashing'>LOVED</span> ?
          </h1>
          <p className='mx-auto max-w-[750px] text-sm text-white/90  md:text-lg'>
            A global influencer and social shopping search engine currently with
            a database of over 1 million influencers in 89 countries. Brands and
            businesses can search influencers with a target audience locally or
            internationally to get their business promoted. Influencers can
            either earn in LOVE crypto currency or be paid in any fiat currency
            of their choice.
          </p>
          <div className='mt-9 flex justify-center'>
            <Link
              to='/search'
              className='inline-flex rounded-full border-0 bg-gradient-to-r from-pinkLight to-purple px-6 py-2 text-lg text-white transition duration-500 hover:bg-indigo-600 hover:from-purple hover:to-pinkLight focus:outline-none md:py-3 md:px-8 '
            >
              Search Now
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
