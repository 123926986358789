import { createSlice } from '@reduxjs/toolkit';

const LS_instaFilters = JSON.parse(localStorage.getItem('instaFilters'));
const LS_youtubeFilters = JSON.parse(localStorage.getItem('youtubeFilters'));
const LS_tiktokFilters = JSON.parse(localStorage.getItem('tiktokFilters'));

const initialState = {
  instaFilters: LS_instaFilters
    ? LS_instaFilters
    : {
        influencer: {
          location: [],
          gender: null,
          language: null,
          usernames: [],
          followers: {
            min: null,
            max: null,
          },
          reelsPlays: {
            min: null,
            max: null,
          },
          topics: [],
          tags: [],
        },
        audience: {
          location: [],
          language: null,
          gender: null,
          // credibility: null,
          age: [],
          languageWeight: null,
        },
        page: 0,
        sort: { direction: 'desc' },
      },
  youtubeFilters: LS_youtubeFilters
    ? LS_youtubeFilters
    : {
        influencer: {
          followers: {
            min: null,
            max: null,
          },
          location: [],
          topics: [],
          language: null,
          relevance: [],
          gender: null,
          usernames: [],
          views: {
            min: null,
            max: null,
          },
        },
        audience: {
          location: [],
          language: null,
          gender: null,
          age: [],
        },
        page: 0,
        sort: { direction: 'desc' },
      },
  tiktokFilters: LS_tiktokFilters
    ? LS_tiktokFilters
    : {
        influencer: {
          followers: {
            min: null,
            max: null,
          },
          location: [],
          topics: [],
          language: null,
          relevance: [],
          gender: null,
          usernames: [],
          views: {
            min: null,
            max: null,
          },
        },
        audience: {
          location: [],
          language: null,
          gender: null,
          age: [],
        },
        page: 0,
        sort: { direction: 'desc' },
      },
};
const instaFiltersSlice = createSlice({
  name: 'filters',
  initialState: initialState,
  reducers: {
    updateInstaFilters: function (state, action) {
      console.log(action.payload);
      state.instaFilters = action.payload;
      localStorage.setItem('instaFilters', JSON.stringify(action.payload));
    },
    updateYoutubeFilters: function (state, action) {
      state.youtubeFilters = action.payload;
      localStorage.setItem('youtubeFilters', JSON.stringify(action.payload));
    },
    updateTiktokFilters: function (state, action) {
      state.tiktokFilters = action.payload;
      localStorage.setItem('tiktokFilters', JSON.stringify(action.payload));
    },
    clearInstaFilters: function (state, action) {
      state.instaFilters = initialState.instaFilters;
      localStorage.removeItem('instaFilters');
    },
    clearYTFilters: function (state, action) {
      state.youtubeFilters = initialState.youtubeFilters;
      localStorage.removeItem('youtubeFilters');
    },
    clearTTFilters: function (state, action) {
      state.tiktokFilters = initialState.tiktokFilters;
      localStorage.removeItem('tiktokFilters');
    },
  },
});
export const {
  updateInstaFilters,
  updateYoutubeFilters,
  updateTiktokFilters,
  clearInstaFilters,
  clearYTFilters,
  clearTTFilters,
} = instaFiltersSlice.actions;
export default instaFiltersSlice.reducer;
