import Tooltip from 'rc-tooltip';
import React from 'react';
import {
  BsChatRightText,
  BsHeartFill,
  BsInfoCircleFill,
  BsPeople,
  BsPersonX,
  BsYoutube,
} from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import {
  FaComment,
  FaEye,
  FaHeart,
  FaInstagram,
  FaThumbsUp,
} from 'react-icons/fa';
import { MdOutlineLoop } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CommentsIllust } from '../../assets/img/comments.svg';
import { ReactComponent as FollowersIllust } from '../../assets/img/followers.svg';
import { formatNumber, fromatDate, getPercentage } from '../utils/utilFuns';
import { FollowersChart } from './FollowersChart';
import { StatsBarChart } from './StatsBarChart';

const YTInfluencerReport = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const {
    data: influencerReportData,
    loading: influencerReportLoading,
    errorMessage: influencerReportError,
  } = useSelector((state) => state.influencerReport);

  const ethnicitiesFollwersAndLikers = (arr = [], arr1 = []) => {
    let _combineEthn = [];

    arr.forEach((lang1, index) => {
      let found = false;
      arr1.forEach((lang2) => {
        if (lang1.code === lang2.code) {
          found = true;
          _combineEthn = [
            ..._combineEthn,
            {
              followers: lang1,
              likers: lang2,
            },
          ];
        }
      });
      if (!found) {
        _combineEthn = [
          ..._combineEthn,
          {
            followers: lang1,
            likers: {},
          },
        ];
      }
    });
    arr1.forEach((lang1, index) => {
      let found = false;
      arr.forEach((lang2) => {
        if (lang1.code === lang2.code) {
          found = true;
        }
      });
      if (!found) {
        _combineEthn = [
          ..._combineEthn,
          {
            followers: {},
            likers: lang1,
          },
        ];
      }
    });
    return _combineEthn;
  };

  const combineLikersAndFollowersData = (arr = [], arr1 = []) => {
    let _combineEthn = [];

    arr.forEach((lang1, index) => {
      let found = false;
      arr1.forEach((lang2) => {
        if (lang1.name === lang2.name) {
          found = true;
          _combineEthn = [
            ..._combineEthn,
            {
              followers: lang1,
              likers: lang2,
            },
          ];
        }
      });
      if (!found) {
        _combineEthn = [
          ..._combineEthn,
          {
            followers: lang1,
            likers: {},
          },
        ];
      }
    });
    arr1.forEach((lang1, index) => {
      let found = false;
      arr.forEach((lang2) => {
        if (lang1.name === lang2.name) {
          found = true;
        }
      });
      if (!found) {
        _combineEthn = [
          ..._combineEthn,
          {
            followers: {},
            likers: lang1,
          },
        ];
      }
    });
    return _combineEthn;
  };
  // console.log(
  //   ethnicitiesFollwersAndLikers(
  //     influencerReportData?.profile?.audience?.languages,
  //     influencerReportData?.profile?.audienceCommenters?.languages
  //   )
  // );
  return (
    props.openReport && (
      <div
        style={{
          background: 'rgba(0,0,0,0.7)',
        }}
        className='hidescrollbar-but-scroll fixed top-0 right-0 bottom-0 left-0 z-[999] h-screen min-h-screen overflow-y-auto'
      >
        {/* {influencerReportLoading !== 'succeeded' && (
          <h1 className='p-5 text-2xl'> Loading</h1>
        )} */}
        {influencerReportData.error ? (
          <p className='text-red-500'>{influencerReportData.message}</p>
        ) : null}
        {!influencerReportLoading &&
          !influencerReportError &&
          !influencerReportData.error && (
            <div
              ref={ref}
              className='mx-auto max-w-3xl bg-primary py-10 md:px-3'
            >
              <button
                onClick={() => props.closeReport()}
                className='fixed right-5 top-5 z-[1000] rounded-full border border-white p-1'
              >
                <CgClose />
              </button>
              <div className='flex flex-col items-center justify-center  '>
                <div className='rounded-full bg-gradient-to-br from-pinkLight to-purple p-1.5'>
                  <div
                    style={{
                      backgroundImage: `url(${influencerReportData?.profile?.profile?.picture})`,
                    }}
                    className='h-24 w-24 rounded-full bg-cover md:h-40 md:w-40'
                  ></div>
                </div>
                <h2 className='mt-5 text-xl'>
                  {influencerReportData?.profile?.profile?.fullname}
                </h2>
                <div className='flex items-center'>
                  <FaInstagram />
                  <a
                    href={
                      influencerReportData?.profile?.profile?.url ||
                      `https://www.youtube.com/channel/${influencerReportData?.profile?.userId}`
                    }
                    target='_blank'
                    rel='noopener noreferrer'
                    className='ml-1.5 text-pink'
                  >
                    {influencerReportData?.profile?.profile?.username}
                  </a>
                </div>
                <p className='mb-3 text-sm font-light text-[#bbbbbb]'>
                  Creator account
                </p>
              </div>

              <div className='-mx-2 flex flex-wrap p-2 sm:p-4'>
                <div className='w-1/2 basis-1/2 p-1.5 md:w-1/3 md:basis-1/3'>
                  <div className='flex justify-center border border-white/20'>
                    <div className='p-4 pb-2 text-center'>
                      <BsPeople style={{ margin: 'auto' }} color='#d419ac' />
                      <span className='mt-1.5 block text-xl font-semibold'>
                        {formatNumber(
                          influencerReportData?.profile?.profile?.followers
                        )}
                      </span>
                      <small className='block text-xs text-white text-opacity-80'>
                        SUBSCRIBERS
                      </small>

                      <Tooltip
                        placement='top'
                        className='mt-2'
                        overlay={
                          <span className='inline-block text-center'>
                            How many Total followers the influencer has on
                            Instagram.
                          </span>
                        }
                      >
                        <span className='mx-auto inline-block opacity-80 hover:opacity-70'>
                          <BsInfoCircleFill size={15} />
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className='w-1/2 basis-1/2 p-1.5 md:w-1/3 md:basis-1/3'>
                  <div className='flex justify-center border border-white/20'>
                    <div className='p-4 pb-2 text-center'>
                      <MdOutlineLoop
                        style={{ margin: 'auto' }}
                        color='#7a0fff'
                      />
                      <span className='mt-1.5 block text-xl font-semibold'>
                        {(
                          influencerReportData?.profile?.profile?.engagementRate.toPrecision(
                            2
                          ) * 100
                        ).toFixed(2)}
                        %
                      </span>

                      <small className='block text-xs text-white text-opacity-80'>
                        ENGAGEMENT RATE
                      </small>

                      <Tooltip
                        placement='top'
                        className='mt-2'
                        overlay={
                          <span className='inline-block text-center'>
                            This is an indication of how engaged the
                            influencer’s audience is. It is calculated as likes,
                            comments etc. ÷ followers. The average Instagram
                            engagement rate is 2%.
                          </span>
                        }
                      >
                        <span className='inline-block  opacity-80 hover:opacity-70'>
                          <BsInfoCircleFill size={15} />
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className='w-1/2 basis-1/2 p-1.5 md:w-1/3 md:basis-1/3'>
                  <div className='flex h-full justify-center border border-white/20'>
                    <div className='p-4 pb-2 text-center'>
                      <FaEye style={{ margin: 'auto' }} color='#E1ACD7' />
                      <span className='mt-1.5 block text-xl font-semibold'>
                        {formatNumber(
                          influencerReportData?.profile?.profile?.averageViews
                        )}
                      </span>
                      <small className='block text-xs text-white text-opacity-80'>
                        AVG VIEWS
                      </small>

                      <Tooltip
                        placement='top'
                        className='mt-2'
                        overlay={
                          <span className='inline-block text-center'>
                            The average sum of likes on the last 30 posts
                          </span>
                        }
                      >
                        <span className='mx-auto inline-block opacity-80 hover:opacity-70'>
                          <BsInfoCircleFill size={15} />
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className=' w-1/2 basis-1/2 p-1.5 md:w-1/3 md:basis-1/3'>
                  <div className='flex h-full justify-center border border-white/20'>
                    <div className='p-4 pb-2 text-center'>
                      <FaComment style={{ margin: 'auto' }} color='#E1ACD7' />
                      <span className='mt-1.5 block text-xl font-semibold'>
                        {formatNumber(
                          Math.ceil(
                            influencerReportData?.profile?.recentPosts
                              ?.map((post) => post.comments)
                              .reduce((a, b) => a + b, 0) /
                              influencerReportData?.profile?.recentPosts.length
                          )
                        )}
                      </span>
                      <small className='block text-xs text-white text-opacity-80'>
                        AVG COMMENTS
                      </small>

                      <Tooltip
                        placement='top'
                        className='mt-2'
                        overlay={
                          <span className='inline-block text-center'>
                            The average sum of likes on the last{' '}
                            {influencerReportData?.profile?.recentPosts?.length}{' '}
                            posts
                          </span>
                        }
                      >
                        <span className='mx-auto inline-block opacity-80 hover:opacity-70'>
                          <BsInfoCircleFill size={15} />
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className='w-1/2 basis-1/2 p-1.5 md:w-1/3 md:basis-1/3'>
                  <div className='flex h-full justify-center border border-white/20'>
                    <div className='p-4 pb-2 text-center'>
                      <FaThumbsUp style={{ margin: 'auto' }} color='#E1ACD7' />
                      <span className='mt-1.5 block text-xl font-semibold'>
                        {formatNumber(
                          influencerReportData?.profile?.profile?.engagements
                        )}
                      </span>
                      <small className='block text-xs text-white text-opacity-80'>
                        AVG Likes
                      </small>

                      <Tooltip
                        placement='top'
                        className='mt-2'
                        overlay={
                          <span className='inline-block text-center'>
                            The average sum of likes on the last 50 posts
                          </span>
                        }
                      >
                        <span className='mx-auto inline-block opacity-80 hover:opacity-70'>
                          <BsInfoCircleFill size={15} />
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
              {/* Audience details by followers */}
              <div className='px-2 md:px-4'>
                <div className='mt-3 flex justify-center '>
                  <div className='w-full'>
                    <h3 className='flex items-center text-sm '>
                      <FaInstagram />
                      <span className='ml-2'>Popular Posts</span>
                      <Tooltip
                        placement='top'
                        className='ml-2 '
                        overlay={
                          <span className='inline-block text-center'>
                            A preview of the most liked content the influencer
                            has posted.
                          </span>
                        }
                      >
                        <span className='inline-block  opacity-80 hover:opacity-70'>
                          <BsInfoCircleFill size={15} />
                        </span>
                      </Tooltip>
                    </h3>
                    <ul className='row -mx-1.5 mt-3 flex flex-wrap'>
                      {influencerReportData?.profile?.popularPosts.map(
                        (post, index) =>
                          index < 6 && (
                            <li
                              key={post.id}
                              className='w-1/2 basis-1/2 p-1.5 md:w-1/3 md:basis-1/3 '
                            >
                              <span className='relative block  overflow-hidden border border-white/20 bg-secondary'>
                                <span className='block p-1.5 px-2 text-xs text-white/90 sm:text-sm'>
                                  {fromatDate(post.created)}
                                </span>
                                <a
                                  href={post.url}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  style={{
                                    backgroundImage: `url(${post.thumbnail})`,
                                  }}
                                  className='block h-32 w-full transform bg-cover bg-center duration-300 hover:scale-105 hover:opacity-80'
                                ></a>

                                <p className='my-2 text-clip px-3 text-xs text-[#f2e3f9] text-opacity-70'>
                                  {post.text}
                                </p>
                                <div className=' left-0 bottom-0 right-0 z-10 border-t border-white/20'>
                                  <div className='flex space-x-3 bg-secondary px-2 py-1'>
                                    <span className='flex items-center text-xs'>
                                      <FaHeart
                                        color='#ff4d4a'
                                        className='mr-1'
                                      />
                                      {formatNumber(post.likes)}
                                    </span>
                                    <span className='flex items-center text-xs'>
                                      <BsChatRightText
                                        color='#7a5cff'
                                        className='mr-1'
                                      />{' '}
                                      {formatNumber(post.comments)}
                                    </span>
                                  </div>
                                </div>
                              </span>
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                </div>

                <div className='flex flex-wrap sm:flex-nowrap  sm:space-x-3 '>
                  <div className='mt-3 flex w-full basis-[64%] justify-center border border-white/20'>
                    <div className='flex w-full flex-col justify-between  px-2 pt-3 pr-4 pb-1.5'>
                      <h3 className='mb-3 px-2 text-sm'>
                        Engagement for recent posts
                        <Tooltip
                          placement='top'
                          className='ml-2'
                          overlay={
                            <span className='inline-block text-center'>
                              These are the topics the audience posts about and
                              interacts with the most often.
                            </span>
                          }
                        >
                          <span className='inline-block  opacity-80 hover:opacity-70'>
                            <BsInfoCircleFill size={15} />
                          </span>
                        </Tooltip>
                      </h3>
                      <StatsBarChart
                        labels={influencerReportData?.profile?.recentPosts
                          .map((item, index) => {
                            const month = [
                              'Jan',
                              'Feb',
                              'Mar',
                              'Apr',
                              'May',
                              'Jun',
                              'Jul',
                              'Aug',
                              'Sep',
                              'Oct',
                              'Nov',
                              'Dec',
                            ];
                            const dateTime = new Date(item.created);
                            return `${
                              month[dateTime.getMonth()]
                            } ${dateTime.getDay()}, ${dateTime.getFullYear()} `;
                          })
                          .splice(1, 6)}
                        // views={influencerReportData?.profile?.recentPosts.map(
                        //   (item) => item.avgViews
                        // )}
                        likes={influencerReportData?.profile?.recentPosts.map(
                          (item) => item.likes
                        )}
                        comments={influencerReportData?.profile?.recentPosts.map(
                          (item) => item.comments
                        )}
                      />
                    </div>
                  </div>
                  <div className='mt-3 flex w-full basis-[35%] justify-center border border-white/20'>
                    <div className='w-full p-3'>
                      <h3 className='text-sm '>
                        Lookalikes
                        <Tooltip
                          placement='top'
                          className='ml-2'
                          overlay={
                            <span className='inline-block text-center'>
                              These are the topics the audience posts about and
                              interacts with the most often.
                            </span>
                          }
                        >
                          <span className='inline-block  opacity-80 hover:opacity-70'>
                            <BsInfoCircleFill size={15} />
                          </span>
                        </Tooltip>
                      </h3>
                      <ul className='mt-3'>
                        {influencerReportData?.profile?.lookalikesByTopics?.map(
                          (item, index) =>
                            index < 4 && (
                              <li
                                key={item.userId}
                                className='mb-3 flex items-center'
                              >
                                <span
                                  style={{
                                    backgroundImage: `url(${item.picture})`,
                                  }}
                                  className='block h-9 w-9 rounded-full bg-cover'
                                ></span>
                                <a
                                  className='ml-3 text-[13px]'
                                  href='http://www.instagram.com'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  <span className='block max-w-[150px] truncate text-base'>
                                    {item.fullname}
                                  </span>
                                  <span className='block text-pink'>
                                    {item.username || item.fullname}
                                  </span>
                                </a>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='flex flex-wrap sm:flex-nowrap sm:space-x-3'>
                  <div className='mt-3 flex w-full basis-1/2 justify-center border border-white/20'>
                    <div className='w-full pt-3 pb-1 pl-1 pr-1'>
                      <h3 className='mb-3 px-2 text-sm'>
                        Followers
                        <Tooltip
                          placement='top'
                          className='ml-2'
                          overlay={
                            <span className='inline-block text-center'>
                              These are the topics the audience posts about and
                              interacts with the most often.
                            </span>
                          }
                        >
                          <span className='inline-block  opacity-80 hover:opacity-70'>
                            <BsInfoCircleFill size={15} />
                          </span>
                        </Tooltip>
                      </h3>

                      <FollowersChart
                        labels={influencerReportData?.profile?.statHistory.map(
                          (item) => item.month
                        )}
                        data={influencerReportData?.profile?.statHistory.map(
                          (item) => item.followers
                        )}
                      />
                    </div>
                  </div>
                  <div className='mt-3 flex w-full justify-center border border-white/20 sm:w-1/2 sm:basis-1/2'>
                    <div className='w-full pt-3 pb-1 pl-1 pr-1'>
                      <h3 className='mb-3 px-2 text-sm'>
                        Likes
                        <Tooltip
                          placement='top'
                          className='ml-2'
                          overlay={
                            <span className='inline-block text-center'>
                              These are the topics the audience posts about and
                              interacts with the most often.
                            </span>
                          }
                        >
                          <span className='inline-block  opacity-80 hover:opacity-70'>
                            <BsInfoCircleFill size={15} />
                          </span>
                        </Tooltip>
                      </h3>

                      <FollowersChart
                        labels={influencerReportData?.profile?.statHistory.map(
                          (item) => item.month
                        )}
                        data={influencerReportData?.profile?.statHistory.map(
                          (item) => item.avgLikes
                        )}
                      />
                    </div>
                  </div>
                  {/* <div className='mt-3 flex basis-1/2 justify-center border border-white/20'>
                    <div className='w-full pt-3 pb-1 pl-1 pr-1'>
                      <h3 className='mb-3 px-2 text-sm'>
                        Following
                        <Tooltip
                          placement='top'
                          className='ml-2'
                          overlay={
                            <span className='inline-block text-center'>
                              These are the topics the audience posts about and
                              interacts with the most often.
                            </span>
                          }
                        >
                          <span className='inline-block  opacity-80 hover:opacity-70'>
                            <BsInfoCircleFill size={15} />
                          </span>
                        </Tooltip>
                      </h3>
                      <FollowersChart
                        labels={influencerReportData?.profile?.statHistory.map(
                          (item) => item.month
                        )}
                        data={influencerReportData?.profile?.statHistory.map(
                          (item) => item.following
                        )}
                      />
                    </div>
                  </div> */}
                </div>

                {influencerReportData?.profile?.hashtags?.length ? (
                  <div className='flex space-x-3'>
                    <div className='mt-3 flex w-full  justify-center border border-white/20'>
                      <div className='w-full p-3'>
                        <h3 className='text-sm '>
                          Popular # and @
                          <Tooltip
                            placement='top'
                            className='ml-2'
                            overlay={
                              <span className='inline-block text-center'>
                                These are the topics the audience posts about
                                and interacts with the most often.
                              </span>
                            }
                          >
                            <span className='inline-block  opacity-80 hover:opacity-70'>
                              <BsInfoCircleFill size={15} />
                            </span>
                          </Tooltip>
                        </h3>
                        <ul className='mt-3 flex flex-wrap text-[13px] font-light'>
                          {influencerReportData?.profile?.hashtags?.map(
                            (hashtag, index) =>
                              index < 12 && (
                                <li
                                  title={hashtag.tag}
                                  className='mb-1.5 mr-1.5 inline rounded-full bg-pinkLight px-2'
                                >
                                  #{hashtag.tag}
                                </li>
                              )
                          )}
                        </ul>
                        <ul className='mt-3 flex flex-wrap text-[13px] font-light'>
                          {influencerReportData?.profile?.mentions?.map(
                            (mention, index) =>
                              index < 8 && (
                                <li
                                  title={mention.tag}
                                  className='mb-1.5 mr-1.5 inline rounded-full bg-pinkLight px-2'
                                >
                                  @{mention.tag}
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : null}

                {influencerReportData?.profile?.brandAffinity?.length ? (
                  <div className='flex space-x-3'>
                    <div className='mt-3 flex basis-1/2 justify-center border border-white/20'>
                      <div className='w-full p-3'>
                        <h3 className='text-sm '>
                          Brand Affinity
                          <Tooltip
                            placement='top'
                            className='ml-2'
                            overlay={
                              <span className='inline-block text-center'>
                                These are the topics the audience posts about
                                and interacts with the most often.
                              </span>
                            }
                          >
                            <span className='inline-block  opacity-80 hover:opacity-70'>
                              <BsInfoCircleFill size={15} />
                            </span>
                          </Tooltip>
                        </h3>
                        <ul className='mt-2.5 text-[13px] text-[#f2e3f9] text-opacity-80'>
                          {influencerReportData?.profile?.brandAffinity?.map(
                            (brand, index) =>
                              index < 5 && (
                                <li className='mb-1' key={brand.id}>
                                  {brand.name}
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className='mt-3 flex basis-1/2 justify-center border border-white/20'>
                      <div className='w-full p-3'>
                        <h3 className='text-sm '>
                          Audience Interests
                          <Tooltip
                            placement='top'
                            className='ml-2'
                            overlay={
                              <span className='inline-block text-center'>
                                These are the topics the audience posts about
                                and interacts with the most often.
                              </span>
                            }
                          >
                            <span className='inline-block  opacity-80 hover:opacity-70'>
                              <BsInfoCircleFill size={15} />
                            </span>
                          </Tooltip>
                        </h3>
                        <ul className='mt-2.5 text-[13px] text-[#f2e3f9] text-opacity-80'>
                          {influencerReportData?.profile?.interests?.map(
                            (interest, index) =>
                              index < 5 && (
                                <li className='mb-1' key={interest.id}>
                                  {interest.name}
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : null}

                {influencerReportData?.profile?.audience ? (
                  <>
                    <div className='mb-3 mt-5 flex items-center'>
                      <BsPeople />
                      <span className='pl-1 font-semibold'>
                        Audience data by subscribers
                      </span>
                      <Tooltip
                        placement='top'
                        className='ml-1.5'
                        overlay={
                          <span className='inline-block text-center'>
                            This is what the audience of this profile looks
                            like. To get a more detailed overview, Click “View
                            full report” above.
                          </span>
                        }
                      >
                        <span className='inline-block  opacity-80 hover:opacity-70'>
                          <BsInfoCircleFill size={15} />
                        </span>
                      </Tooltip>
                    </div>
                    <div className='my-3 flex space-x-3'>
                      <div className='basis-1/2'>
                        <FollowersIllust className='max-h-[132px] w-full' />
                      </div>
                      <div className='mx-auto flex basis-1/2 justify-center border border-white/20'>
                        <div className='p-3  py-7 text-center'>
                          <BsPersonX
                            style={{ margin: 'auto' }}
                            color='#7a0fff'
                          />
                          <span className='mt-1.5 block text-3xl'>
                            {(
                              influencerReportData?.profile?.audience?.notable *
                              100
                            ).toFixed(2)}
                            %
                          </span>
                          <span className='mb-0.5 flex items-center'>
                            <small className='mt-1 block text-sm text-white text-opacity-80'>
                              Notable Subscribers
                            </small>

                            <Tooltip
                              placement='top'
                              className='ml-2'
                              overlay={
                                <span className='inline-block text-center'>
                                  Does this influencer have a real audience?
                                  More than 50% of fake followers is typically a
                                  sign of fraud.
                                </span>
                              }
                            >
                              <span className='inline-block  opacity-80 hover:opacity-70'>
                                <BsInfoCircleFill size={15} />
                              </span>
                            </Tooltip>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='flex space-x-3'>
                      <div className='flex basis-1/2 border border-white/20 '>
                        <div className='w-full p-3 pt-2 pb-2'>
                          <h3 className='flex items-center text-sm'>
                            Location by Country
                            <Tooltip
                              placement='top'
                              className='ml-2'
                              overlay={
                                <span className='inline-block text-center'>
                                  This indicates the countries the influencer’s
                                  audience is located in.
                                </span>
                              }
                            >
                              <span className='inline-block  opacity-80 hover:opacity-70'>
                                <BsInfoCircleFill size={15} />
                              </span>
                            </Tooltip>
                          </h3>

                          <ul className='mt-3'>
                            {influencerReportData?.profile?.audience?.geoCountries?.map(
                              (coutry, index) =>
                                index < 5 && (
                                  <li className='relative mb-5 flex items-center justify-between after:absolute after:-bottom-1.5 after:block after:h-1 after:w-full after:rounded-full  after:bg-white/50'>
                                    <div className='flex items-center text-xs'>
                                      {coutry.name}
                                    </div>
                                    <div className='text-xs text-[#c0c0c0]'>
                                      {(coutry.weight * 100).toFixed(2)}%
                                    </div>
                                    <span
                                      style={{
                                        width: coutry.weight * 100 + '%',
                                      }}
                                      className='absolute -bottom-1.5 z-10 block h-1 rounded-full bg-pink'
                                    ></span>
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className='flex basis-1/2 border border-white/20'>
                        <div className='w-full p-3 pt-2 pb-2'>
                          <h3 className='flex items-center text-sm'>
                            Languages
                            <Tooltip
                              placement='top'
                              className='ml-2'
                              overlay={
                                <span className='inline-block text-center'>
                                  This indicates the cities the influencer’s
                                  audience is located in.
                                </span>
                              }
                            >
                              <span className='inline-block  opacity-80 hover:opacity-70'>
                                <BsInfoCircleFill size={15} />
                              </span>
                            </Tooltip>
                          </h3>

                          <ul className='mt-3'>
                            {influencerReportData?.profile?.audience?.languages?.map(
                              (city, index) =>
                                index < 5 && (
                                  <li className='relative mb-5 flex items-center justify-between after:absolute after:-bottom-1.5 after:block after:h-1 after:w-full after:rounded-full  after:bg-white/50'>
                                    <div className='flex items-center text-xs'>
                                      {city.name}
                                    </div>
                                    <div className='text-xs text-[#c0c0c0]'>
                                      {(city.weight * 100).toFixed(2)}%
                                    </div>
                                    <span
                                      style={{
                                        width: city.weight * 100 + '%',
                                      }}
                                      className='absolute -bottom-1.5 z-10 block h-1 rounded-full bg-pink'
                                    ></span>
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className='flex flex-wrap sm:flex-nowrap sm:space-x-3'>
                      <div className='mt-3 w-full basis-full sm:w-1/3 sm:basis-1/3'>
                        <div className=' flex  border border-white/20 '>
                          <div className='w-full p-3  py-3'>
                            <h3 className='text-sm uppercase'>
                              Gender percentage
                              <Tooltip
                                placement='top'
                                className='ml-2'
                                overlay={
                                  <span className='inline-block text-center'>
                                    What gender categories does the influencer
                                    reach?
                                  </span>
                                }
                              >
                                <span className='inline-block  opacity-80 hover:opacity-70'>
                                  <BsInfoCircleFill size={15} />
                                </span>
                              </Tooltip>
                            </h3>
                            <div
                              style={{
                                background: `conic-gradient(
                                  #7a5cff 0,
                                  #7a5cff ${
                                    influencerReportData?.profile?.audience
                                      ?.genders[0]?.weight * 100
                                  }%,

                                   #a722f4 0,
                                   #a722f4 100%
                              )`,
                              }}
                              className='mt-2 h-[90px] w-[90px] rounded-full'
                            ></div>
                            <ul className='mt-4'>
                              {influencerReportData?.profile?.audience?.genders?.map(
                                (gender) => (
                                  <li className='mb-1 flex items-center justify-between'>
                                    <div className='flex items-center text-xs'>
                                      {gender.code === 'FEMALE' ? (
                                        <span className='mr-1 block h-2 w-2 rounded-full bg-purple text-[#ccc] '></span>
                                      ) : (
                                        <span className='mr-1 block h-2 w-2 rounded-full bg-pinkLight text-[#ccc] '></span>
                                      )}
                                      {gender.code}
                                    </div>
                                    <div className='text-xs text-[#c0c0c0]'>
                                      {(gender.weight * 100).toFixed(2)}%
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='mt-3 flex basis-full justify-center border border-white/20 sm:basis-[67%]'>
                        <div className='w-full p-3'>
                          <h3 className='text-sm '>
                            Age and Gender Split
                            <Tooltip
                              placement='top'
                              className='ml-2'
                              overlay={
                                <span className='inline-block text-center'>
                                  What gender categories does the influencer
                                  reach?
                                </span>
                              }
                            >
                              <span className='inline-block  opacity-80 hover:opacity-70'>
                                <BsInfoCircleFill size={15} />
                              </span>
                            </Tooltip>
                          </h3>
                          <div className='mt-2 flex flex-row justify-around'>
                            {influencerReportData?.profile?.audience
                              ?.gendersPerAge &&
                              influencerReportData?.profile?.audience?.gendersPerAge?.map(
                                (genderPerAge) => (
                                  <div className='max-w-[70px] basis-1/5 '>
                                    <div className='flex h-[110px]  justify-between'>
                                      <div className='col flex flex-col-reverse text-center text-[10px]'>
                                        <span
                                          style={{
                                            height:
                                              genderPerAge.female * 100 + '%',
                                          }}
                                          className='mx-auto w-1.5 rounded-md bg-purple'
                                        ></span>
                                        <span>
                                          {(genderPerAge.female * 100).toFixed(
                                            2
                                          )}
                                          %
                                        </span>
                                      </div>
                                      <div className='col flex flex-col-reverse text-center text-[10px]'>
                                        <span
                                          style={{
                                            height:
                                              genderPerAge.male * 100 + '%',
                                          }}
                                          className='mx-auto w-1.5 rounded-md bg-pinkLight'
                                        ></span>
                                        <span>
                                          {(genderPerAge.male * 100).toFixed(2)}
                                          %
                                        </span>
                                      </div>
                                    </div>

                                    <span className='mt-1.5 block text-center text-xs'>
                                      {genderPerAge.code}
                                    </span>
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                {/* by likers */}
                {influencerReportData?.profile?.audienceCommenters ? (
                  <>
                    <div className='flex space-x-3'>
                      {influencerReportData?.profile?.audience?.brandAffinity
                        ?.length ? (
                        <div className='mt-3 flex basis-1/2 justify-center border border-white/20'>
                          <div className='w-full p-3'>
                            <h3 className='text-sm '>
                              Brand Affinity
                              <Tooltip
                                placement='top'
                                className='ml-2'
                                overlay={
                                  <span className='inline-block text-center'>
                                    These are the topics the audience posts
                                    about and interacts with the most often.
                                  </span>
                                }
                              >
                                <span className='inline-block  opacity-80 hover:opacity-70'>
                                  <BsInfoCircleFill size={15} />
                                </span>
                              </Tooltip>
                            </h3>
                            <ul className='mt-3 text-[13px] text-[#f2e3f9] text-opacity-80'>
                              {influencerReportData?.profile?.audience?.brandAffinity?.map(
                                (brand, index) =>
                                  index < 5 && (
                                    <li className='mb-1 flex items-center justify-between'>
                                      <span>{brand.name}</span>
                                      <span>
                                        {(brand.weight * 100).toFixed(2)}%
                                      </span>
                                    </li>
                                  )
                              )}
                            </ul>
                          </div>
                        </div>
                      ) : null}
                      {influencerReportData?.profile?.audience?.interests
                        ?.length ? (
                        <div className='mt-3 flex basis-1/2 justify-center border border-white/20'>
                          <div className='w-full p-3'>
                            <h3 className='text-sm '>
                              Audience Interests
                              <Tooltip
                                placement='top'
                                className='ml-2'
                                overlay={
                                  <span className='inline-block text-center'>
                                    These are the topics the audience posts
                                    about and interacts with the most often.
                                  </span>
                                }
                              >
                                <span className='inline-block  opacity-80 hover:opacity-70'>
                                  <BsInfoCircleFill size={15} />
                                </span>
                              </Tooltip>
                            </h3>
                            <ul className='mt-3 text-[13px] text-[#f2e3f9] text-opacity-80'>
                              {influencerReportData?.profile?.audience?.interests?.map(
                                (interest, index) =>
                                  index < 5 && (
                                    <li className='mb-1 flex items-center justify-between'>
                                      <span>{interest.name}</span>
                                      <span>
                                        {(interest.weight * 100).toFixed(2)}%
                                      </span>
                                    </li>
                                  )
                              )}
                            </ul>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className='mb-3 mt-5 flex items-center'>
                      <BsPeople />
                      <span className='pl-1 font-semibold'>
                        Audience data by Commenters
                      </span>
                    </div>
                    <div className='my-3 flex space-x-3'>
                      <div className='max-h-[132px] basis-1/2'>
                        <CommentsIllust width={'100%'} height={'100%'} />
                      </div>
                      <div className=' flex w-1/2 basis-1/2 justify-center border border-white/20'>
                        <div className='flex flex-col items-center justify-center p-3 py-4 text-center'>
                          <BsHeartFill
                            fontSize={24}
                            style={{ margin: 'auto' }}
                            color='#ff4d4a'
                          />
                          <span className='mt-0 block text-2xl font-semibold'>
                            {(
                              influencerReportData?.profile?.audienceCommenters
                                ?.notable * 100
                            ).toFixed(2)}
                            %
                          </span>
                          <span className='mb-0.5 flex items-center'>
                            <small className='mt-1 block text-xs text-white text-opacity-80'>
                              Notable Commenters
                            </small>

                            <Tooltip
                              placement='top'
                              className='ml-2'
                              overlay={
                                <span className='inline-block text-center'>
                                  Does this influencer have a real audience?
                                  More than 50% of fake followers is typically a
                                  sign of fraud.
                                </span>
                              }
                            >
                              <span className='inline-block  opacity-80 hover:opacity-70'>
                                <BsInfoCircleFill size={15} />
                              </span>
                            </Tooltip>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='flex space-x-3'>
                      <div className='flex basis-1/2 border border-white/20 '>
                        <div className='w-full p-3 pt-2 pb-2'>
                          <h3 className='flex items-center text-sm'>
                            Location by Country
                            <Tooltip
                              placement='top'
                              className='ml-2'
                              overlay={
                                <span className='inline-block text-center'>
                                  This indicates the countries the influencer’s
                                  audience is located in.
                                </span>
                              }
                            >
                              <span className='inline-block  opacity-80 hover:opacity-70'>
                                <BsInfoCircleFill size={15} />
                              </span>
                            </Tooltip>
                          </h3>
                          <ul className='mt-3'>
                            {influencerReportData?.profile?.audienceCommenters?.geoCountries?.map(
                              (coutry, index) =>
                                index < 6 && (
                                  <li className='relative mb-5 flex items-center justify-between after:absolute after:-bottom-1.5 after:block after:h-1 after:w-full after:rounded-full  after:bg-white/50'>
                                    <div className='flex items-center text-xs'>
                                      {coutry.name}
                                    </div>
                                    <div className='text-xs text-[#c0c0c0]'>
                                      {(coutry.weight * 100).toFixed(2)}%
                                    </div>
                                    <span
                                      style={{
                                        width: coutry.weight * 100 + '%',
                                      }}
                                      className='absolute -bottom-1.5 z-10 block h-1 rounded-full bg-pink'
                                    ></span>
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className='flex basis-1/2 border border-white/20'>
                        <div className='w-full p-3 pt-2 pb-2'>
                          <h3 className='flex items-center text-sm'>
                            Languages
                            <Tooltip
                              placement='top'
                              className='ml-2'
                              overlay={
                                <span className='inline-block text-center'>
                                  This indicates the cities the influencer’s
                                  audience is located in.
                                </span>
                              }
                            >
                              <span className='inline-block  opacity-80 hover:opacity-70'>
                                <BsInfoCircleFill size={15} />
                              </span>
                            </Tooltip>
                          </h3>

                          <ul className='mt-3'>
                            {influencerReportData?.profile?.audienceCommenters?.languages?.map(
                              (city, index) =>
                                index < 6 && (
                                  <li className='relative mb-5 flex items-center justify-between after:absolute after:-bottom-1.5 after:block after:h-1 after:w-full after:rounded-full  after:bg-white/50'>
                                    <div className='flex items-center text-xs'>
                                      {city.name}
                                    </div>
                                    <div className='text-xs text-[#c0c0c0]'>
                                      {(city.weight * 100).toFixed(2)}%
                                    </div>
                                    <span
                                      style={{
                                        width: city.weight * 100 + '%',
                                      }}
                                      className='absolute -bottom-1.5 z-10 block h-1 rounded-full bg-pink'
                                    ></span>
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className='flex flex-wrap sm:flex-nowrap sm:space-x-3'>
                      <div className='mt-3 sm:basis-1/3 '>
                        <div className=' flex border border-white/20 '>
                          <div className='w-full basis-full p-3 py-3  '>
                            <h3 className='text-sm uppercase'>
                              Gender percentage
                              <Tooltip
                                placement='top'
                                className='ml-2'
                                overlay={
                                  <span className='inline-block text-center'>
                                    What gender categories does the influencer
                                    reach?
                                  </span>
                                }
                              >
                                <span className='inline-block  opacity-80 hover:opacity-70'>
                                  <BsInfoCircleFill size={15} />
                                </span>
                              </Tooltip>
                            </h3>
                            {influencerReportData?.profile?.audienceCommenters
                              ?.genders ? (
                              <div
                                style={{
                                  background: `conic-gradient(
                                  #7a5cff 0,
                                  #7a5cff ${
                                    influencerReportData?.profile
                                      ?.audienceCommenters?.genders[0]?.weight *
                                    100
                                  }%,

                                   #a722f4 0,
                                   #a722f4 100%
                              )`,
                                }}
                                className='mt-2 h-[90px] w-[90px] rounded-full'
                              ></div>
                            ) : null}

                            <ul className='mt-4'>
                              {influencerReportData?.profile?.audienceCommenters?.genders?.map(
                                (gender) => (
                                  <li className='mb-1 flex items-center justify-between'>
                                    <div className='flex items-center text-xs'>
                                      {gender.code === 'FEMALE' ? (
                                        <span className='mr-1 block h-2 w-2 rounded-full bg-purple text-[#ccc] '></span>
                                      ) : (
                                        <span className='mr-1 block h-2 w-2 rounded-full bg-pinkLight text-[#ccc] '></span>
                                      )}
                                      {gender.code}
                                    </div>
                                    <div className='text-xs text-[#c0c0c0]'>
                                      {(gender.weight * 100).toFixed(2)}%
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='mt-3 flex basis-full justify-center border border-white/20 sm:basis-[67%]'>
                        <div className='w-full p-3'>
                          <h3 className='text-sm '>
                            Age and Gender Split
                            <Tooltip
                              placement='top'
                              className='ml-2'
                              overlay={
                                <span className='inline-block text-center'>
                                  What gender categories does the influencer
                                  reach?
                                </span>
                              }
                            >
                              <span className='inline-block  opacity-80 hover:opacity-70'>
                                <BsInfoCircleFill size={15} />
                              </span>
                            </Tooltip>
                          </h3>
                          <div className='mt-2 flex flex-row justify-around'>
                            {influencerReportData?.profile?.audience?.gendersPerAge?.map(
                              (genderPerAge) => (
                                <div className='max-w-[70px] basis-1/5 '>
                                  <div className='flex h-[110px]  justify-between'>
                                    <div className='col flex flex-col-reverse text-center text-[10px]'>
                                      <span
                                        style={{
                                          height:
                                            genderPerAge.female * 100 + '%',
                                        }}
                                        className='mx-auto w-1.5 rounded-md bg-purple'
                                      ></span>
                                      <span>
                                        {(genderPerAge.female * 100).toFixed(2)}
                                        %
                                      </span>
                                    </div>
                                    <div className='col flex flex-col-reverse text-center text-[10px]'>
                                      <span
                                        style={{
                                          height: genderPerAge.male * 100 + '%',
                                        }}
                                        className='mx-auto w-1.5 rounded-md bg-pinkLight'
                                      ></span>
                                      <span>
                                        {(genderPerAge.male * 100).toFixed(2)}%
                                      </span>
                                    </div>
                                  </div>

                                  <span className='mt-1.5 block text-center text-xs'>
                                    {genderPerAge.code}
                                  </span>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {/* <div className='flex space-x-3'>
                  <div className='mt-3 flex basis-1/2 justify-center border border-white/20'>
                    <div className='w-full p-3'>
                      <h3 className='text-sm '>
                        Brand Affinity
                        <Tooltip
                          placement='top'
                          className='ml-2'
                          overlay={
                            <span className='inline-block text-center'>
                              These are the topics the audience posts about and
                              interacts with the most often.
                            </span>
                          }
                        >
                          <span className='inline-block  opacity-80 hover:opacity-70'>
                            <BsInfoCircleFill size={15} />
                          </span>
                        </Tooltip>
                      </h3>
                      <ul className='mt-3 text-[13px] text-[#f2e3f9] text-opacity-80'>
                        {influencerReportData?.profile?.audienceCommenters?.brandAffinity?.map(
                          (brand, index) =>
                            index < 5 && (
                              <li className='mb-1 flex items-center justify-between'>
                                <span>{brand.name}</span>
                                <span>{(brand.weight * 100).toFixed(2)}%</span>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className='mt-3 flex basis-1/2 justify-center border border-white/20'>
                    <div className='w-full p-3'>
                      <h3 className='text-sm '>
                        Audience Interests
                        <Tooltip
                          placement='top'
                          className='ml-2'
                          overlay={
                            <span className='inline-block text-center'>
                              These are the topics the audience posts about and
                              interacts with the most often.
                            </span>
                          }
                        >
                          <span className='inline-block  opacity-80 hover:opacity-70'>
                            <BsInfoCircleFill size={15} />
                          </span>
                        </Tooltip>
                      </h3>
                      <ul className='mt-3 text-[13px] text-[#f2e3f9] text-opacity-80'>
                        {influencerReportData?.profile?.audienceCommenters?.interests?.map(
                          (interest, index) =>
                            index < 5 && (
                              <li className='mb-1 flex items-center justify-between'>
                                <span>{interest.name}</span>
                                <span>
                                  {(interest.weight * 100).toFixed(2)}%
                                </span>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  </div>
                </div> */}
                {/* notable followers */}
                <div className='mb-3 mt-8 flex items-center'>
                  <BsPeople />
                  <span className='pl-1 font-semibold'>
                    Notable Subscribers
                  </span>
                </div>
                <div className='notable-followers-list text-sm'>
                  <div className='flex border-b border-white/20 pb-1 text-[13px] font-light text-white text-opacity-90 '>
                    <div className='w-[45%] flex-auto md:w-[64%]'>
                      Influencers
                    </div>
                    <div className='w-[35%] flex-auto md:w-[20%]'>
                      Engagements
                    </div>
                    <div className='w-[20%] flex-auto md:w-[16%]'>
                      Followers
                    </div>
                  </div>
                  {influencerReportData?.profile?.audience?.notableUsers?.map(
                    (user, index) =>
                      index < 20 && (
                        <div
                          key={user.userId}
                          className='flex items-center border-b border-white/20 py-2 hover:bg-secondary'
                        >
                          <div className='w-[45%] flex-auto md:w-[64%]'>
                            <div className='flex items-center'>
                              <div
                                className='h-12 w-12 rounded-full bg-cover'
                                style={{
                                  backgroundImage: `url(${user.picture})`,
                                }}
                              ></div>
                              <a
                                href={user.url}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='pl-3 font-light text-pink'
                              >
                                {user.username
                                  ? ' @' + user.username
                                  : user.fullname || 'unknown'}
                              </a>
                            </div>
                          </div>
                          <div className='w-[35%] flex-auto text-[#f2e3f9] text-opacity-70 md:w-[20%]'>
                            {formatNumber(user.followers)}
                          </div>
                          <div className='w-[20%] flex-auto text-[#f2e3f9] text-opacity-70 md:w-[16%]'>
                            {formatNumber(user.engagements)}
                          </div>
                        </div>
                      )
                  )}
                </div>

                {/* notable likers */}
                <div className='mb-3 mt-8 flex items-center'>
                  <BsHeartFill color='#ff4d4a' />
                  <span className='pl-2 font-semibold'>Notable Commenters</span>
                </div>
                <div className='notable-followers-list text-sm'>
                  <div className='flex border-b border-white/20 pb-1 text-[13px] font-light text-white text-opacity-90 '>
                    <div className='w-[45%] flex-auto md:w-[64%]'>
                      Influencers
                    </div>
                    <div className='w-[35%] flex-auto md:w-[20%]'>
                      Engagements
                    </div>
                    <div className='w-[20%] flex-auto md:w-[16%]'>
                      Followers
                    </div>
                  </div>

                  {influencerReportData?.profile?.audienceCommenters?.notableUsers?.map(
                    (user, index) =>
                      index < 20 && (
                        <div
                          key={user.userId}
                          className='flex items-center border-b border-white/20 py-2 hover:bg-secondary'
                        >
                          <div className='w-[45%] flex-auto md:w-[64%]'>
                            <div className='flex items-center'>
                              <div
                                className='h-12 w-12 rounded-full bg-cover'
                                style={{
                                  backgroundImage: `url(${user.picture})`,
                                }}
                              ></div>
                              <a
                                href={user.url}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='pl-3 font-light text-pink'
                              >
                                {user.username
                                  ? ' @' + user.username
                                  : user.fullname || 'unknown'}
                              </a>
                            </div>
                          </div>
                          <div className='w-[35%] flex-auto text-[#f2e3f9] text-opacity-70 md:w-[20%]'>
                            {formatNumber(user.followers)}
                          </div>
                          <div className='w-[20%] flex-auto text-[#f2e3f9] text-opacity-70 md:w-[16%]'>
                            {formatNumber(user.engagements)}
                          </div>
                        </div>
                      )
                  )}
                </div>

                {/* lookalikes similar topics */}
                <div className='mb-3 mt-8 flex items-center'>
                  <BsYoutube color='#fbad50' />
                  <span className='pl-2 font-semibold'>
                    Lookalikes (similar topics)
                  </span>
                </div>
                <div className='notable-followers-list text-sm'>
                  <div className='flex border-b border-white/20 pb-1 text-[13px] font-light text-white text-opacity-90 '>
                    <div className='w-[45%] flex-auto md:w-[64%]'>
                      Influencers
                    </div>
                    <div className='w-[35%] flex-auto md:w-[20%]'>
                      Engagements
                    </div>
                    <div className='w-[20%] flex-auto md:w-[16%]'>
                      Followers
                    </div>
                  </div>

                  {influencerReportData?.profile?.lookalikesByTopics?.map(
                    (user, index) =>
                      index < 20 && (
                        <div
                          key={user.userId}
                          className='flex items-center border-b border-white/20 py-2 hover:bg-secondary'
                        >
                          <div className='w-[45%] flex-auto md:w-[64%]'>
                            <div className='flex items-center'>
                              <div
                                className='h-12 w-12 rounded-full bg-cover'
                                style={{
                                  backgroundImage: `url(${user.picture})`,
                                }}
                              ></div>
                              <a
                                href={user.url}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='pl-3 font-light text-pink'
                              >
                                {user.username
                                  ? ' @' + user.username
                                  : user.fullname || 'unknown'}
                              </a>
                            </div>
                          </div>
                          <div className='w-[35%] flex-auto text-[#f2e3f9] text-opacity-70 md:w-[20%]'>
                            {formatNumber(user.followers)}
                          </div>
                          <div className='w-[20%] flex-auto text-[#f2e3f9] text-opacity-70 md:w-[16%]'>
                            {formatNumber(user.engagements)}
                          </div>
                        </div>
                      )
                  )}
                </div>
                {/* lookalikes similar topics */}
                <div className='mb-3 mt-8 flex items-center'>
                  <BsYoutube color='#fbad50' />
                  <span className='pl-2 font-semibold'>
                    Audience Lookalikes
                  </span>
                </div>

                <div className='notable-followers-list text-sm'>
                  <div className='flex border-b border-white/20 pb-1 text-[13px] font-light text-white text-opacity-90 '>
                    <div className='w-[45%] flex-auto md:w-[64%]'>
                      Influencers
                    </div>
                    <div className='w-[35%] flex-auto md:w-[20%]'>
                      Engagements
                    </div>
                    <div className='w-[20%] flex-auto md:w-[16%]'>
                      Followers
                    </div>
                  </div>

                  {influencerReportData?.profile?.audience?.audienceLookalikes?.map(
                    (user, index) =>
                      index < 20 && (
                        <div
                          key={user.userId}
                          className='flex items-center border-b border-white/20 py-2 hover:bg-secondary'
                        >
                          <div className='w-[45%] flex-auto md:w-[64%]'>
                            <div className='flex items-center'>
                              <div
                                className='h-12 w-12 rounded-full bg-cover'
                                style={{
                                  backgroundImage: `url(${user.picture})`,
                                }}
                              ></div>
                              <a
                                href={user.url}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='pl-3 font-light text-pink'
                              >
                                {user.username
                                  ? ' @' + user.username
                                  : user.fullname || 'unknown'}
                              </a>
                            </div>
                          </div>
                          <div className='w-[35%] flex-auto text-[#f2e3f9] text-opacity-70 md:w-[20%]'>
                            {formatNumber(user.followers)}
                          </div>
                          <div className='w-[20%] flex-auto text-[#f2e3f9] text-opacity-70 md:w-[16%]'>
                            {formatNumber(user.engagements)}
                          </div>
                        </div>
                      )
                  )}
                </div>
                {/* hashtags */}
                {influencerReportData?.profile?.hashtags?.length ? (
                  <>
                    <div className='mb-3 mt-8 flex items-center'>
                      <span className='font-semibold'># Popular Hashtags</span>
                    </div>
                    <div className='notable-followers-list text-sm '>
                      <div className='mb-3 flex border-b border-white/20 pb-1 text-[13px] font-light text-white text-opacity-90 '>
                        <div className='w-[50%] flex-auto md:w-[65%]'>
                          Hashtag
                        </div>
                        <div className='w-[50%] flex-auto text-right md:w-[35%]'>
                          Percent
                        </div>
                      </div>
                      {influencerReportData?.profile?.hashtags?.map(
                        (hashtag, index) =>
                          index < 6 && (
                            <div className='flex items-center pb-2'>
                              <span className='w-[50%] flex-auto text-[#f2e3f9] text-opacity-80 md:w-[65%]'>
                                #{hashtag.tag}
                              </span>
                              <span className='w-[50%] flex-auto text-right text-[#f2e3f9] text-opacity-80 md:w-[35%]'>
                                {(hashtag.weight * 100).toFixed(2)}%
                              </span>
                            </div>
                          )
                      )}
                    </div>
                  </>
                ) : null}
                {influencerReportData?.profile?.mentions?.length ? (
                  <>
                    {/* @ Popular Mention         */}
                    <div className='mb-2 mt-8 flex items-center'>
                      <span className='font-semibold'>@ Popular Mention</span>
                    </div>
                    <div className='notable-followers-list text-sm'>
                      <div className='mb-3  flex border-b border-white/20 pb-1 text-[13px] font-light text-white text-opacity-90  '>
                        <div className='w-[50%] flex-auto md:w-[65%]'>
                          Mentions
                        </div>
                        <div className='w-[50%] flex-auto text-right md:w-[35%]'>
                          Percent
                        </div>
                      </div>
                      {influencerReportData?.profile?.mentions?.map(
                        (mention, index) =>
                          index < 6 && (
                            <div className='flex items-center pb-2'>
                              <span className='w-[50%] flex-auto text-[#f2e3f9] text-opacity-80 md:w-[65%]'>
                                @{mention.tag}
                              </span>
                              <span className='w-[50%] flex-auto text-right text-[#f2e3f9] text-opacity-80 md:w-[35%]'>
                                {(mention.weight * 100).toFixed(2)}%
                              </span>
                            </div>
                          )
                      )}
                    </div>
                  </>
                ) : null}
                {influencerReportData?.profile?.audienceCommenters?.genders
                  ?.length ? (
                  <>
                    {/* @ Gender Split         */}
                    <div className='mb-2 mt-8 flex items-center'>
                      <span className='font-semibold'>Gender Split</span>
                    </div>
                    <div className='notable-followers-list text-sm '>
                      <div className='mb-3 flex justify-between border-b border-white/20 pb-1 text-[13px] font-light text-white text-opacity-90 '>
                        <div className='w-[40%] flex-auto md:w-[44%]'>
                          Gender
                        </div>
                        <div className='w-[30%] flex-auto md:w-[28%]'>
                          Commenters
                        </div>
                        <div className='w-[30%] flex-auto md:w-[28%]'>
                          Followers
                        </div>
                      </div>
                      <div className=' flex justify-between pb-1 text-[13px] font-light text-[#f2e3f9] text-opacity-90'>
                        <div className='w-[40%] flex-auto md:w-[44%]'>
                          {influencerReportData?.profile?.audience?.genders?.map(
                            (gender) => (
                              <div className='mt-1'>{gender.code}</div>
                            )
                          )}
                        </div>
                        <div className='w-[30%] flex-auto md:w-[28%]'>
                          {influencerReportData?.profile?.audienceCommenters?.genders?.map(
                            (gender) => (
                              <div className='flex justify-between pb-1 text-[#f2e3f9] text-opacity-80'>
                                <div className='w-[30%] flex-auto md:w-[28%]'>
                                  {Math.round(
                                    getPercentage(
                                      influencerReportData?.profile?.profile
                                        ?.engagements,
                                      gender.weight * 100
                                    )
                                  ).toLocaleString()}{' '}
                                  / {(gender.weight * 100).toFixed(2)}%
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        <div className='w-[30%] flex-auto md:w-[28%]'>
                          {influencerReportData?.profile?.audience?.genders?.map(
                            (gender) => (
                              <div className='flex justify-between pb-1 text-[#f2e3f9] text-opacity-80'>
                                <div className='w-[30%] flex-auto md:w-[28%]'>
                                  {Math.round(
                                    getPercentage(
                                      influencerReportData?.profile?.profile
                                        ?.followers,
                                      gender.weight * 100
                                    )
                                  ).toLocaleString()}{' '}
                                  / {(gender.weight * 100).toFixed(2)}%
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                {influencerReportData?.profile?.audience?.ethnicities
                  ?.length ? (
                  <>
                    {/* @ Popular Mention         */}
                    <div className='mb-2 mt-8 flex items-center'>
                      <span className='font-semibold'>Ethnicity</span>
                    </div>
                    <div className='notable-followers-list text-sm '>
                      <div className='mb-3 flex justify-between border-b border-white/20 pb-1 text-[13px] font-light text-white text-opacity-90 '>
                        <div className='w-[40%] flex-auto md:w-[44%]'>
                          Groups
                        </div>
                        <div className='w-[30%] flex-auto md:w-[28%]'>
                          Commenter
                        </div>
                        <div className='w-[30%] flex-auto md:w-[28%]'>
                          Followers
                        </div>
                      </div>
                      {influencerReportData?.profile?.audience?.ethnicities &&
                        ethnicitiesFollwersAndLikers(
                          influencerReportData?.profile?.audience?.ethnicities,
                          influencerReportData?.profile?.audienceCommenters
                            ?.ethnicities
                        )?.map(
                          (ethnicity, index) =>
                            index < 6 && (
                              <div className='justify- flex pb-1 text-[13px] font-light text-[#f2e3f9] text-opacity-90'>
                                <div className='w-[40%] flex-auto md:w-[44%]'>
                                  {ethnicity.likers.name ||
                                    ethnicity.followers.name}
                                </div>
                                <div className='w-[30%] flex-auto md:w-[28%]'>
                                  {Math.round(
                                    getPercentage(
                                      influencerReportData?.profile?.stats
                                        ?.avgLikes?.value,
                                      ethnicity.likers.weight * 100
                                    )
                                  ).toLocaleString()}{' '}
                                  / {(ethnicity.likers.weight * 100).toFixed(2)}
                                  %
                                </div>
                                <div className='w-[30%] flex-auto md:w-[28%]'>
                                  {Math.round(
                                    getPercentage(
                                      influencerReportData?.profile?.stats
                                        ?.followers.value,
                                      ethnicity.followers.weight * 100
                                    )
                                  ).toLocaleString()}{' '}
                                  /{' '}
                                  {(ethnicity.followers.weight * 100).toFixed(
                                    2
                                  )}
                                  %
                                </div>
                              </div>
                            )
                        )}
                    </div>
                  </>
                ) : null}

                {/* @ Language        */}
                <div className='mb-2 mt-8 flex items-center'>
                  <span className='font-semibold'>Language</span>
                </div>
                <div className='notable-followers-list text-sm '>
                  <div className='mb-3 flex justify-between border-b border-white/20 pb-1 text-[13px] font-light text-white text-opacity-90 '>
                    <div className='w-[40%] flex-auto md:w-[44%]'>Language</div>
                    <div className='w-[30%] flex-auto md:w-[28%]'>
                      Commenters
                    </div>
                    <div className='w-[30%] flex-auto md:w-[28%]'>
                      Followers
                    </div>
                  </div>
                  {influencerReportData?.profile?.audience?.languages &&
                    ethnicitiesFollwersAndLikers(
                      influencerReportData?.profile?.audience?.languages,
                      influencerReportData?.profile?.audienceCommenters
                        ?.languages
                    )?.map(
                      (lang, index) =>
                        lang.followers &&
                        lang.likers && (
                          <div className='justify- flex pb-1.5 text-[13px] font-light text-[#f2e3f9] text-opacity-90'>
                            <div className='w-[40%] flex-auto font-normal capitalize md:w-[44%]'>
                              {lang?.followers?.name ||
                                lang?.followers?.code ||
                                lang?.likers?.name}
                            </div>
                            <div className='w-[30%] flex-auto md:w-[28%]'>
                              {lang?.likers?.weight
                                ? Math.round(
                                    getPercentage(
                                      influencerReportData?.profile?.stats
                                        ?.avgLikes?.value,
                                      lang?.likers?.weight * 100
                                    )
                                  ).toLocaleString() + ' / '
                                : '- / -'}{' '}
                              {lang?.likers?.weight &&
                                (lang?.likers?.weight * 100).toFixed(2) + '%'}
                            </div>
                            <div className='w-[30%] flex-auto md:w-[28%]'>
                              {lang?.followers?.weight
                                ? Math.round(
                                    getPercentage(
                                      influencerReportData?.profile?.profile
                                        ?.followers,
                                      lang?.followers?.weight * 100
                                    )
                                  ).toLocaleString() + ' / '
                                : '- / -'}{' '}
                              {lang?.followers?.weight &&
                                (lang?.followers?.weight * 100).toFixed(2) +
                                  '%'}
                            </div>
                          </div>
                        )
                    )}
                </div>

                {/* @ Age Split        */}
                <div className='mb-2 mt-8 flex items-center'>
                  <span className='font-semibold'>Age Split</span>
                </div>
                <div className='notable-followers-list text-sm '>
                  <div className='mb-3 flex justify-between border-b border-white/20 pb-1 text-[13px] font-light text-white text-opacity-90 '>
                    <div className='w-[40%] flex-auto md:w-[44%]'>Age</div>
                    <div className='w-[30%] flex-auto md:w-[28%]'>
                      Commenters
                    </div>
                    <div className='w-[30%] flex-auto md:w-[28%]'>
                      Followers
                    </div>
                  </div>
                  {influencerReportData?.profile?.audience?.ages &&
                    ethnicitiesFollwersAndLikers(
                      influencerReportData?.profile?.audience?.ages,
                      influencerReportData?.profile?.audienceCommenters?.ages
                    )?.map(
                      (age, index) =>
                        age.followers &&
                        age.likers && (
                          <div className='justify- flex pb-1.5 text-[13px] font-light text-[#f2e3f9] text-opacity-90'>
                            <div className='w-[40%] flex-auto font-normal capitalize md:w-[44%]'>
                              {age?.followers?.name ||
                                age?.followers?.code ||
                                age?.likers?.code}
                            </div>
                            <div className='w-[30%] flex-auto md:w-[28%]'>
                              {age?.likers?.weight
                                ? Math.round(
                                    getPercentage(
                                      influencerReportData?.profile?.stats
                                        ?.avgLikes?.value,
                                      age?.likers?.weight * 100
                                    )
                                  ).toLocaleString() + ' / '
                                : '- / -'}{' '}
                              {age?.likers?.weight &&
                                (age?.likers?.weight * 100).toFixed(2) + '%'}
                            </div>
                            <div className='w-[30%] flex-auto md:w-[28%]'>
                              {age?.followers?.weight
                                ? Math.round(
                                    getPercentage(
                                      influencerReportData?.profile?.profile
                                        ?.followers,
                                      age?.followers?.weight * 100
                                    )
                                  ).toLocaleString() + ' / '
                                : '- / -'}{' '}
                              {(age?.followers?.weight * 100).toFixed(2)}%
                            </div>
                          </div>
                        )
                    )}
                </div>

                {/* @ Age Split        */}
                <div className='mb-2 mt-8 flex items-center'>
                  <span className='font-semibold'>Female Age Split</span>
                </div>
                <div className='notable-followers-list text-sm '>
                  <div className='mb-3 flex justify-between border-b border-white/20 pb-1 text-[13px] font-light text-white text-opacity-90 '>
                    <div className='w-[40%] flex-auto md:w-[44%]'>Age</div>
                    <div className='w-[30%] flex-auto md:w-[28%]'>
                      Commenters
                    </div>
                    <div className='w-[30%] flex-auto md:w-[28%]'>
                      Followers
                    </div>
                  </div>
                  {influencerReportData?.profile?.audience?.gendersPerAge &&
                    ethnicitiesFollwersAndLikers(
                      influencerReportData?.profile?.audience?.gendersPerAge,
                      influencerReportData?.profile?.audienceCommenters
                        ?.gendersPerAge
                    )?.map(
                      (genderPerAge, index) =>
                        genderPerAge.followers &&
                        genderPerAge.likers && (
                          <div className='justify- flex pb-1.5 text-[13px] font-light text-[#f2e3f9] text-opacity-90'>
                            <div className='w-[40%] flex-auto font-normal capitalize md:w-[44%]'>
                              {genderPerAge?.followers?.name ||
                                genderPerAge?.likers?.name ||
                                genderPerAge?.followers?.code}
                            </div>
                            <div className='w-[30%] flex-auto md:w-[28%]'>
                              {genderPerAge?.likers?.female
                                ? Math.round(
                                    getPercentage(
                                      influencerReportData?.profile?.stats
                                        ?.avgLikes?.value,
                                      genderPerAge?.likers?.female * 100
                                    )
                                  ).toLocaleString() + ' / '
                                : '- / -'}{' '}
                              {genderPerAge?.likers?.female &&
                                (genderPerAge?.likers?.female * 100).toFixed(
                                  2
                                ) + '%'}
                            </div>
                            <div className='w-[30%] flex-auto md:w-[28%]'>
                              {genderPerAge?.followers?.female
                                ? Math.round(
                                    getPercentage(
                                      influencerReportData?.profile?.profile
                                        ?.followers,
                                      genderPerAge?.followers?.female * 100
                                    )
                                  ).toLocaleString() + ' / '
                                : '- / -'}{' '}
                              {(genderPerAge?.followers?.female * 100).toFixed(
                                2
                              )}
                              %
                            </div>
                          </div>
                        )
                    )}
                </div>

                {/* @ Age Split        */}
                <div className='mb-2 mt-8 flex items-center'>
                  <span className='font-semibold'>Male Age Split</span>
                </div>
                <div className='notable-followers-list text-sm '>
                  <div className='mb-3 flex justify-between border-b border-white/20 pb-1 text-[13px] font-light text-white text-opacity-90 '>
                    <div className='w-[40%] flex-auto md:w-[44%]'>Age</div>
                    <div className='w-[30%] flex-auto md:w-[28%]'>
                      Commneters
                    </div>
                    <div className='w-[30%] flex-auto md:w-[28%]'>
                      Followers
                    </div>
                  </div>
                  {influencerReportData?.profile?.audience?.gendersPerAge &&
                    ethnicitiesFollwersAndLikers(
                      influencerReportData?.profile?.audience?.gendersPerAge,
                      influencerReportData?.profile?.audienceCommenters
                        ?.gendersPerAge
                    )?.map(
                      (genderPerAge, index) =>
                        genderPerAge.followers &&
                        genderPerAge.likers && (
                          <div className='justify- flex pb-1.5 text-[13px] font-light text-[#f2e3f9] text-opacity-90'>
                            <div className='w-[40%] flex-auto font-normal capitalize md:w-[44%]'>
                              {genderPerAge?.followers?.code ||
                                genderPerAge?.likers?.code}
                            </div>
                            <div className='w-[30%] flex-auto md:w-[28%]'>
                              {genderPerAge?.likers?.male
                                ? Math.round(
                                    getPercentage(
                                      influencerReportData?.profile?.stats
                                        ?.avgLikes?.value,
                                      genderPerAge?.likers?.male * 100
                                    )
                                  ).toLocaleString() + ' / '
                                : '- / -'}{' '}
                              {genderPerAge?.likers?.male &&
                                (genderPerAge?.likers?.male * 100).toFixed(2) +
                                  '%'}
                            </div>
                            <div className='w-[30%] flex-auto md:w-[28%]'>
                              {genderPerAge?.followers?.male
                                ? Math.round(
                                    getPercentage(
                                      influencerReportData?.profile?.profile
                                        ?.followers,
                                      genderPerAge?.followers?.male * 100
                                    )
                                  ).toLocaleString() + ' / '
                                : '- / -'}{' '}
                              {(genderPerAge?.followers?.male * 100).toFixed(2)}
                              %
                            </div>
                          </div>
                        )
                    )}
                </div>

                {/* @ Age Split        */}
                <div className='mb-2 mt-8 flex items-center'>
                  <span className='font-semibold'>Location by Country</span>
                </div>
                <div className='notable-followers-list text-sm '>
                  <div className='mb-3 flex justify-between border-b border-white/20 pb-1 text-[13px] font-light text-white text-opacity-90 '>
                    <div className='w-[40%] flex-auto md:w-[44%]'>Country</div>
                    <div className='w-[30%] flex-auto md:w-[28%]'>
                      Commenters
                    </div>
                    <div className='w-[30%] flex-auto md:w-[28%]'>
                      Followers
                    </div>
                  </div>
                  {influencerReportData?.profile?.audience?.geoCountries &&
                    ethnicitiesFollwersAndLikers(
                      influencerReportData?.profile?.audience?.geoCountries,
                      influencerReportData?.profile?.audienceCommenters
                        ?.geoCountries
                    )?.map(
                      (country, index) =>
                        country.followers &&
                        country.likers && (
                          <div className='justify- flex pb-1.5 text-[13px] font-light text-[#f2e3f9] text-opacity-90'>
                            <div className='w-[40%] flex-auto font-normal capitalize md:w-[44%]'>
                              {country?.followers?.name ||
                                country?.likers?.name}
                            </div>
                            <div className='w-[30%] flex-auto md:w-[28%]'>
                              {country?.likers?.weight
                                ? Math.round(
                                    getPercentage(
                                      influencerReportData?.profile?.stats
                                        ?.avgLikes?.value,
                                      country?.likers?.weight * 100
                                    )
                                  ).toLocaleString() + ' / '
                                : '- / -'}{' '}
                              {country?.likers?.weight &&
                                (country?.likers?.weight * 100).toFixed(2) +
                                  '%'}
                            </div>
                            <div className='w-[30%] flex-auto md:w-[28%]'>
                              {country?.followers?.weight
                                ? Math.round(
                                    getPercentage(
                                      influencerReportData?.profile?.profile
                                        ?.followers,
                                      country?.followers?.weight * 100
                                    )
                                  ).toLocaleString() + ' / '
                                : '- / -'}{' '}
                              {country?.followers?.weight &&
                                (country?.followers?.weight * 100).toFixed(2) +
                                  '%'}
                            </div>
                          </div>
                        )
                    )}
                </div>
                {influencerReportData?.profile?.audience?.geoCities?.length ? (
                  <>
                    {/* @ Cities         */}
                    <div className='mb-2 mt-8 flex items-center'>
                      <span className='font-semibold'>Location by Cities</span>
                    </div>
                    <div className='notable-followers-list text-sm '>
                      <div className='mb-3 flex justify-between border-b border-white/20 pb-1 text-[13px] font-light text-white text-opacity-90 '>
                        <div className='w-[40%] flex-auto md:w-[44%]'>City</div>
                        <div className='w-[30%] flex-auto md:w-[28%]'>
                          Commenters
                        </div>
                        <div className='w-[30%] flex-auto md:w-[28%]'>
                          Followers
                        </div>
                      </div>
                      {console.time('fun strt')}
                      {influencerReportData?.profile?.audience?.geoCities &&
                        combineLikersAndFollowersData(
                          influencerReportData?.profile?.audience?.geoCities,
                          influencerReportData?.profile?.audienceCommenters
                            ?.geoCities
                        )?.map(
                          (city, index) =>
                            city.followers &&
                            city.likers && (
                              <div className='justify- flex pb-1.5 text-[13px] font-light text-[#f2e3f9] text-opacity-90'>
                                <div className='w-[40%] flex-auto font-normal capitalize md:w-[44%]'>
                                  {city?.followers?.name || city?.likers?.name}
                                </div>
                                <div className='w-[30%] flex-auto md:w-[28%]'>
                                  {city?.likers?.weight
                                    ? Math.round(
                                        getPercentage(
                                          influencerReportData?.profile?.stats
                                            ?.avgLikes?.value,
                                          city?.likers?.weight * 100
                                        )
                                      ).toLocaleString() + ' / '
                                    : '- / -'}{' '}
                                  {city?.likers?.weight &&
                                    (city?.likers?.weight * 100).toFixed(2) +
                                      '%'}
                                </div>
                                <div className='w-[30%] flex-auto md:w-[28%]'>
                                  {city?.followers?.weight
                                    ? Math.round(
                                        getPercentage(
                                          influencerReportData?.profile?.profile
                                            ?.followers,
                                          city?.followers?.weight * 100
                                        )
                                      ).toLocaleString() + ' / '
                                    : '- / -'}{' '}
                                  {city?.followers?.weight &&
                                    (city?.followers?.weight * 100).toFixed(2) +
                                      '%'}
                                </div>
                              </div>
                            )
                        )}
                      {console.timeEnd('fun strt')}
                    </div>
                  </>
                ) : null}

                {influencerReportData?.profile?.audience?.interests?.length ? (
                  <>
                    {/* @ Interests         */}
                    <div className='mb-2 mt-8 flex items-center'>
                      <span className='font-semibold'>Audience Interests</span>
                    </div>
                    <div className='notable-followers-list text-sm '>
                      <div className='mb-3 flex justify-between border-b border-white/20 pb-1 text-[13px] font-light text-white text-opacity-90 '>
                        <div className='w-[40%] flex-auto md:w-[44%]'>
                          Interests
                        </div>
                        <div className='w-[30%] flex-auto md:w-[28%]'>
                          Likers
                        </div>
                        <div className='w-[30%] flex-auto md:w-[28%]'>
                          Followers
                        </div>
                      </div>

                      {influencerReportData?.profile?.audience?.interests &&
                        combineLikersAndFollowersData(
                          influencerReportData?.profile?.audience?.interests,
                          influencerReportData?.profile?.audienceCommenters
                            ?.interests
                        )?.map(
                          (interest, index) =>
                            interest.followers &&
                            interest.likers && (
                              <div className='justify- flex pb-1.5 text-[13px] font-light text-[#f2e3f9] text-opacity-90'>
                                <div className='w-[40%] flex-auto font-normal capitalize md:w-[44%]'>
                                  {interest?.followers?.name ||
                                    interest?.likers?.name}
                                </div>
                                <div className='w-[30%] flex-auto md:w-[28%]'>
                                  {interest?.likers?.weight
                                    ? Math.round(
                                        getPercentage(
                                          influencerReportData?.profile?.stats
                                            ?.avgLikes?.value,
                                          interest?.likers?.weight * 100
                                        )
                                      ).toLocaleString() + ' / '
                                    : '- / -'}{' '}
                                  {interest?.likers?.weight &&
                                    (interest?.likers?.weight * 100).toFixed(
                                      2
                                    ) + '%'}
                                </div>
                                <div className='w-[30%] flex-auto md:w-[28%]'>
                                  {interest?.followers?.weight
                                    ? Math.round(
                                        getPercentage(
                                          influencerReportData?.profile?.profile
                                            ?.followers,
                                          interest?.followers?.weight * 100
                                        )
                                      ).toLocaleString() + ' / '
                                    : '- / -'}{' '}
                                  {interest?.followers?.weight &&
                                    (interest?.followers?.weight * 100).toFixed(
                                      2
                                    ) + '%'}
                                </div>
                              </div>
                            )
                        )}
                    </div>
                  </>
                ) : null}

                {/* @ Interests         */}
                {influencerReportData?.profile?.audience?.brandAffinity
                  ?.length ? (
                  <>
                    <div className='mb-2 mt-8 flex items-center'>
                      <span className='font-semibold'>
                        Audience Brand Affinity
                      </span>
                    </div>
                    <div className='notable-followers-list text-sm '>
                      <div className='mb-3 flex justify-between border-b border-white/20 pb-1 text-[13px] font-light text-white text-opacity-90 '>
                        <div className='w-[40%] flex-auto md:w-[44%]'>
                          Brand
                        </div>
                        <div className='w-[30%] flex-auto md:w-[28%]'>
                          Likers
                        </div>
                        <div className='w-[30%] flex-auto md:w-[28%]'>
                          Followers
                        </div>
                      </div>

                      {influencerReportData?.profile?.audience?.brandAffinity &&
                        combineLikersAndFollowersData(
                          influencerReportData?.profile?.audience
                            ?.brandAffinity,
                          influencerReportData?.profile?.audienceCommenters
                            ?.brandAffinity
                        )?.map(
                          (brand, index) =>
                            brand.followers &&
                            brand.likers && (
                              <div className='justify- flex pb-1.5 text-[13px] font-light text-[#f2e3f9] text-opacity-90'>
                                <div className='w-[40%] flex-auto font-normal capitalize md:w-[44%]'>
                                  {brand?.followers?.name ||
                                    brand?.likers?.name}
                                </div>
                                <div className='w-[30%] flex-auto md:w-[28%]'>
                                  {brand?.likers?.weight
                                    ? Math.round(
                                        getPercentage(
                                          influencerReportData?.profile?.stats
                                            ?.avgLikes?.value,
                                          brand?.likers?.weight * 100
                                        )
                                      ).toLocaleString() + ' / '
                                    : '- / -'}{' '}
                                  {brand?.likers?.weight &&
                                    (brand?.likers?.weight * 100).toFixed(2) +
                                      '%'}
                                </div>
                                <div className='w-[30%] flex-auto md:w-[28%]'>
                                  {brand?.followers?.weight
                                    ? Math.round(
                                        getPercentage(
                                          influencerReportData?.profile?.profile
                                            ?.followers,
                                          brand?.followers?.weight * 100
                                        )
                                      ).toLocaleString() + ' / '
                                    : '- / -'}{' '}
                                  {brand?.followers?.weight &&
                                    (brand?.followers?.weight * 100).toFixed(
                                      2
                                    ) + '%'}
                                </div>
                              </div>
                            )
                        )}
                    </div>
                  </>
                ) : null}

                <div className='mt-5 flex justify-center '>
                  <div className='w-full'>
                    <h3 className='flex items-center text-sm '>
                      <span className=' text-xl font-semibold'>
                        Popular Posts
                      </span>
                    </h3>
                    <ul className='row -mx-[8px] mt-3 flex flex-wrap'>
                      {influencerReportData?.profile?.popularPosts?.map(
                        (post, index) =>
                          index < 6 && (
                            <li
                              key={post.id}
                              className='w-1/2 basis-1/2 p-[8px] sm:w-1/3 sm:basis-1/3 '
                            >
                              <span className='relative block  overflow-hidden border border-white/20 bg-secondary'>
                                <span className='block p-1.5 px-2 text-xs text-white/90 sm:text-sm'>
                                  {fromatDate(post.created)}
                                </span>
                                <a
                                  href={post.url}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  style={{
                                    backgroundImage: `url(${post.thumbnail})`,
                                  }}
                                  className='block h-32 w-full transform bg-cover bg-center duration-300 hover:scale-105 hover:opacity-80'
                                ></a>

                                <p className='my-2 text-clip px-3 text-xs text-[#f2e3f9] text-opacity-70'>
                                  {post.text}
                                </p>
                                <div className=' left-0 bottom-0 right-0 z-10 border-t border-white/20'>
                                  <div className='flex space-x-3 bg-secondary px-2 py-1'>
                                    <span className='flex items-center text-xs'>
                                      <FaHeart
                                        color='#ff4d4a'
                                        className='mr-1'
                                      />
                                      {formatNumber(post.likes)}
                                    </span>
                                    <span className='flex items-center text-xs'>
                                      <BsChatRightText
                                        color='#7a5cff'
                                        className='mr-1'
                                      />{' '}
                                      {formatNumber(post.comments)}
                                    </span>
                                  </div>
                                </div>
                              </span>
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    )
  );
});

export default YTInfluencerReport;
