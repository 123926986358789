import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  loading: false,
  otpSend: false,
  error: null,
};
export const sendOtp = createAsyncThunk('auth/sendOtp', async ({ email }) => {
  let url = `https://influencerapi.lovefy.com/api/users/otp`;
  let response = await axios.post(url, { email });
  return response.data;
});

const sendOtpSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(sendOtp.pending, (state, action) => {
        state.loading = true;
        state.otpSend = false;
        state.error = null;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.otpSend = true;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.loading = false;
        state.otpSend = false;
        state.error = `Oops! Something goes wrong!`;
      });
  },
  reducers: {
    clearOTP: function (state, aciton) {
      return initialState;
    },
  },
});
export const { clearOTP } = sendOtpSlice.actions;
export default sendOtpSlice.reducer;
