import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  loading: false,
  data: [],
  errorMessage: null,
};
let cancelToken;
export const getUsers = createAsyncThunk(
  'users/getUsers',
  async (query, { getState }) => {
    const { jsontoken } = getState()?.login?.data;
    const config = {
      headers: { Authorization: `Bearer ${jsontoken}` },
    };

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel();
    }
    cancelToken = axios.CancelToken.source();

    try {
      let dataUrl = `https://influencerapi.lovefy.com/api/influencers/youtube/users?search=${query}`;
      let { data } = await axios.get(dataUrl, config, {
        cancelToken: cancelToken.token,
      });

      const users = data.users;

      users.unshift({
        username: '@' + query,
      });
      return {
        eror: data.error,
        users,
      };
    } catch (error) {
      // console.log("d")
    }
  }
);

const YTusersSlice = createSlice({
  name: 'YTusers',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state, action) => {
        state.data = [];
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload;
        } else {
          state.loading = true;
          state.data = action.payload || [];
        }
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.errorMessage = `Oops! Something goes wrong!`;
      });
  },
});
export default YTusersSlice.reducer;
