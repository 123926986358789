import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateInstaFilters } from '../../redux/features/filters.feature';
import { getTags } from '../../redux/features/tags/tags.feature';
import CustomMultiSelect from '../utils/CustomMultiSelect';
import { BsInfoCircleFill } from 'react-icons/bs';
import Tooltip from 'rc-tooltip';
const InstaTagsFilter = () => {
  const dispath = useDispatch();
  let instaFiltersState = useSelector((state) => state.filters.instaFilters);

  let tagsState = useSelector((state) => state['tags']);
  // get topics
  const handleTags = async (value) => {
    dispath(getTags({ platform: 'instagram', query: value }));
  };

  const onChangeTopic = (arr) => {
    // const locationIds = arr.map((_) => _.id);
    dispath(
      updateInstaFilters({
        influencer: {
          ...instaFiltersState.influencer,
          // location: locationIds,
          tags: [...arr],
        },
        audience: {
          ...instaFiltersState.audience,
        },
      })
    );
  };
  return (
    <>
      <p className='mb-1 flex items-center text-sm text-white text-opacity-90'>
        Hashtags
        <Tooltip
          placement='top'
          overlay={
            <span className='inline-block text-center'>
              Search for influencers who have posted with a specific #hashtag in
              their content.
            </span>
          }
        >
          <span className='ml-2 hover:opacity-70'>
            <BsInfoCircleFill size={15} />
          </span>
        </Tooltip>
      </p>
      <CustomMultiSelect
        loading={tagsState.loading}
        options={tagsState.data.tags}
        handleSearch={handleTags}
        placeholder='#hashtags'
        checkbox={false}
        isObject={false}
        multi={true}
        onSelect={onChangeTopic}
        onRemove={onChangeTopic}
        selectedValues={instaFiltersState.influencer.tags}
      />
    </>
  );
};

export default InstaTagsFilter;
