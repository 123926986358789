import React from 'react';
import Brands from '../components/Brands';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import Navbar from '../components/Navbar';

const Home = () => {
  return (
    <div>
      <Navbar />
      <HeroSection />
      <Brands />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
