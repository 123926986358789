import React, { useEffect, useState } from 'react';
import Logo from '../../assets/img/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearSingup } from '../../redux/features/auth/singup.feature';
import { loginUser } from '../../redux/features/auth/login.feature';
const LoginForm = () => {
  const dispatch = useDispatch();
  const { loading, data, errorMessage } = useSelector((state) => state.login);
  useEffect(() => {
    dispatch(clearSingup());
    if (data?.success) {
      const redirect = setTimeout(function () {
        navigate('/');
      }, 2000);
    }
  }, [loading]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alert, setAlert] = useState('');
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      loginUser({
        email,
        password,
      })
    );
  };

  return (
    <div>
      <section className='min-h-screen bg-secondary'>
        <div className='mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-screen lg:py-0'>
          <Link
            to='/'
            className='mb-6 flex items-center text-2xl font-semibold text-secondary dark:text-white'
          >
            <img className='mr-2 w-[200px]' src={Logo} alt='logo' />
          </Link>
          <div className='w-full rounded-lg border border-white/50 bg-primary shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md md:mt-0 xl:p-0'>
            <div className='space-y-4 p-6 sm:p-8 md:space-y-6'>
              <h1 className='text-xl font-bold leading-tight tracking-tight  text-white md:text-2xl'>
                Sign in to your account
              </h1>
              {data?.success ? (
                <p className='text-green-500'>{data?.message}</p>
              ) : null}
              {!data?.success ? (
                <p className='text-yellow-500'>{data?.message}</p>
              ) : null}
              <form onSubmit={handleSubmit} className='space-y-4 md:space-y-6'>
                <div>
                  <label
                    htmlFor='email'
                    className='text-white-800 mb-2 block text-sm font-medium '
                  >
                    Your email
                  </label>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type='email'
                    name='email'
                    id='email'
                    className='block w-full rounded-lg  bg-gray-50 p-2.5 text-secondary focus:border focus:border-none focus:outline-pink  sm:text-sm'
                    placeholder='name@company.com'
                    required=''
                  />
                </div>
                <div>
                  <label
                    htmlFor='password'
                    className='text-white-800 mb-2 block text-sm font-medium '
                  >
                    Password
                  </label>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type='password'
                    name='password'
                    id='password'
                    placeholder='••••••••'
                    className='block w-full rounded-lg  bg-gray-50 p-2.5 text-secondary focus:border focus:border-none focus:outline-pink  sm:text-sm'
                    required=''
                  />
                </div>
                <div className='flex items-center justify-between'>
                  <div className='flex items-start'>
                    <div className='flex h-5 items-center'>
                      <input
                        id='remember'
                        aria-describedby='remember'
                        type='checkbox'
                        className='focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 h-4 w-4 rounded border border-gray-300 bg-gray-50 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800'
                        required=''
                      />
                    </div>
                    <div className='ml-3 text-sm'>
                      <label htmlFor='remember' className='text-white-80'>
                        Remember me
                      </label>
                    </div>
                  </div>
                  <Link
                    to='/forgetpassword'
                    className='text-primary-600 dark:text-primary-500 text-sm font-medium hover:underline'
                  >
                    Forgot password?
                  </Link>
                </div>
                <div className='text-center'>
                  {loading ? (
                    <button
                      disabled
                      className='mx-auto inline-block cursor-not-allowed rounded-lg border border-pinkLight  bg-gradient-to-r from-pinkLight to-purple py-1.5 px-6 text-white transition duration-300 hover:border-transparent focus:outline-none md:py-3 md:px-8'
                    >
                      Processing...
                    </button>
                  ) : (
                    <button
                      type='submit'
                      className='mx-auto inline-block rounded-lg border border-pinkLight  py-1.5 px-6 text-white transition duration-300 hover:border-transparent hover:bg-gradient-to-r hover:from-pinkLight hover:to-purple focus:outline-none md:py-3 md:px-8'
                    >
                      Sign in
                    </button>
                  )}
                </div>
                <p className='text-sm font-light text-white/80'>
                  Don’t have an account yet?{' '}
                  <Link
                    to='/signup'
                    className='font-medium text-pink hover:underline'
                  >
                    Sign up
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginForm;
