import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateInstaFilters } from '../../redux/features/filters.feature';
import { getTopics } from '../../redux/features/topics/topics.feature';
import CustomMultiSelect from '../utils/CustomMultiSelect';
import { BsInfoCircleFill } from 'react-icons/bs';
import Tooltip from 'rc-tooltip';
import { getUsers } from '../../redux/features/users/users.feature';
import Multiselect from 'multiselect-react-dropdown';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
const UsernameFilter = () => {
  let cancelToken;
  const dispath = useDispatch();
  let instaFiltersState = useSelector((state) => state.filters.instaFilters);
  const [inputValue, setInputValue] = useState({
    username: '',
  });

  let usernameState = useSelector((state) => state['users']);
  // console.log(usernameState)
  // const  [_users, setUsers] = useState([])
  const [loading, setLoading] = useState(false);
  const styles = {
    multiselectContainer: {
      // To change css for multiselect (Width,height,etc..)
    },
    searchBox: {
      // To change search box element look
      minHeight: 40,
    },
    inputField: {
      // To change input field position or margin
    },
    chips: {
      // To change css chips(Selected options)
    },
    optionContainer: {
      // To change css for option container
      background: '#260c33',
      fontSize: 14,
      border: 'none',
      borderRadius: 0,
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
    },
    option: {
      // To change css for dropdown options
    },
    groupHeading: {
      // To chanage group heading style
    },
  };

  const handleUsers = async (value) => {
    // console.log(usernameState.loading)
    dispath(getUsers(value.replace('@', '')));
    setInputValue({
      username: value.replace('@', ''),
    });
  };

  const onChangeUser = (arr) => {
    dispath(
      updateInstaFilters({
        influencer: {
          ...instaFiltersState.influencer,
          // location: locationIds,
          usernames: [...arr],
        },
        audience: {
          ...instaFiltersState.audience,
        },
      })
    );
  };

  return (
    <>
      <p className='mb-1 flex items-center text-sm text-white text-opacity-90'>
        Username
      </p>
      <Multiselect
        selectionLimit={2}
        style={styles}
        // options={_users}
        options={usernameState?.data?.users}
        onSearch={handleUsers}
        loading={usernameState.loading}
        handleSearch={handleUsers}
        isObject={true}
        displayValue='username'
        checkbox={false}
        placeholder='@username'
        // isObject={false}
        singleSelect={false}
        onSelect={onChangeUser}
        onRemove={onChangeUser}
        selectedValues={instaFiltersState.influencer.usernames}
      />
    </>
  );
};

export default UsernameFilter;
