import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';

const initialState = {
  loading: false,
  data: [],
  errorMessage: null,
};
let cancelToken;
export const sendContactEmail = createAsyncThunk(
  'contact/sendContactEmail',
  async (argu, { getState }) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel();
    }
    cancelToken = axios.CancelToken.source();
    try {
      const { email, subject, name, msg } = argu;
      let dataUrl = `https://influencerapi.lovefy.com/api/users/contact`;
      let response = await axios.post(
        dataUrl,
        { email, subject, name, msg },

        {
          cancelToken: cancelToken.token,
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

const sendContactEmailSlice = createSlice({
  name: 'contact',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(sendContactEmail.pending, (state, action) => {
        state.loading = true;
        state.errorMessage = '';
      })
      .addCase(sendContactEmail.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload;
        } else {
          state.loading = true;
          state.data = action.payload || {};
        }
      })
      .addCase(sendContactEmail.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = `Oops! Something goes wrong!`;
      });
  },
});
export default sendContactEmailSlice.reducer;
