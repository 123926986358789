import React from 'react';
import Slider from 'react-slick';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// logos
import { ReactComponent as NikeLogo } from '../assets/img/logos/nike-11.svg';
import { ReactComponent as GNBLogo } from '../assets/img/logos/gabbana.svg';
import { ReactComponent as PradaLogo } from '../assets/img/logos/prada.svg';
import { ReactComponent as KNCLogo } from '../assets/img/logos/calvin-klein-1.svg';
import { ReactComponent as GucciLogo } from '../assets/img/logos/gucci-logo-1.svg';
import CkLogo from '../assets/img/logos/brand-logo-3.svg';
import { ReactComponent as GuerlainLogo } from '../assets/img/logos/guerlain.svg';

const Brands = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className='bg-secondary py-10 sm:py-20'>
      <div className='container'>
        <h2 className='mb-1 text-3xl font-semibold'>
          Our Community of Businesses
        </h2>
        <p className='font-light text-white/90'>
          Find top influencers whose are working with top the brands
        </p>
        <div className='brands-silder-container mt-8 '>
          <Slider {...settings}>
            <div>
              <div className='flex items-center justify-center opacity-60 hover:opacity-80'>
                <GNBLogo />
              </div>
            </div>

            <div>
              <div className='pradalogo flex items-center justify-center opacity-60 hover:opacity-80'>
                <PradaLogo />
              </div>
            </div>
            <div>
              <div className='flex items-center justify-center opacity-60 hover:opacity-80'>
                <NikeLogo />
              </div>
            </div>
            <div>
              <div className='knclogo flex items-center justify-center opacity-60 hover:opacity-80'>
                <KNCLogo />
              </div>
            </div>
            <div>
              <div className='guccilogo flex items-center justify-center opacity-60 hover:opacity-80'>
                <GucciLogo />
              </div>
            </div>

            <div>
              <div className='flex items-center justify-center opacity-60 hover:opacity-80'>
                <GuerlainLogo />
              </div>
            </div>
            <div>
              <div className='text-center opacity-60 hover:opacity-80'>
                <img className='mx-auto' height={35} src={CkLogo} alt='' />
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Brands;
