import './App.css';
import Navbar from './components/Navbar';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import FindInfluencers from './pages/FindInfluencers';
import InstaInfluencerOverview from './components/insta/InstaInfluencerOverview';
import InstaInfluencerReport from './components/insta/InstaInfluencerReport';
import PdfPrint from './pages/PdfPrint';
import Login from './pages/Login';
import Register from './pages/Register';
import Profile from './pages/Profile';
import Forgetpassword from './pages/Forgetpassword';
import VerifyEmailPage from './pages/VerifyEmailPage';
import EmailVerificationPage from './pages/EmailVerificationPage';
function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/search' element={<FindInfluencers />} />
        <Route path='/overview' element={<InstaInfluencerOverview />} />
        <Route path='/report' element={<InstaInfluencerReport />} />
        <Route path='/print' element={<PdfPrint />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Register />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/forgetpassword' element={<Forgetpassword />} />
        <Route path='/verify-email' element={<VerifyEmailPage />} />
        <Route
          path='/email-verification/:email/:code'
          element={<EmailVerificationPage />}
        />
      </Routes>
    </div>
  );
}

export default App;
