import React from 'react';
import Select from 'react-select';

const SingleSelect = ({
  options,
  name,
  isSearchable,
  isClearable,
  placeholder,
  className,
  defaultValue,
  getOptionLabel,
  getOptionValue,
  onChange,
  value,
}) => {
  return (
    <Select
      className={`single-select text-sm ${className}`}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: '#260c33',
        },
      })}
      classNamePrefix='select'
      isSearchable={isSearchable}
      name={name}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      options={options}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={onChange}
      isClearable={isClearable}
      value={value}
    />
  );
};

export default SingleSelect;
