import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  loading: false,
  data: null,
  errorMessage: null,
};

export const verifyEmail = createAsyncThunk(
  'auth/verifyEmail',
  async ({ email, code }) => {
    // console.log(user);
    let url = `https://influencerapi.lovefy.com/api/users/verify-email`;
    let response = await axios.post(url, { email, code });
    console.log(response.data);
    return response.data;
  }
);

const verifyEmailSlice = createSlice({
  name: 'auth',
  initialState: initialState,

  extraReducers: (builder) => {
    builder
      .addCase(verifyEmail.pending, (state, action) => {
        state.loading = true;
        state.emailVerified = false;
        state.data = null;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action;
        state.emailVerified = true;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.loading = false;
        state.data = action;
        state.emailVerified = false;
        state.errorMessage = action.payload?.data?.message
          ? action.payload?.data?.message
          : `Oops! Something goes wrong!`;
      });
  },
});

export default verifyEmailSlice.reducer;
