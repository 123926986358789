import filtersReducer from './features/filters.feature';
import influencerReportReducer from './features/influencer-report.feature';
import influencersReducer from './features/influencers-list.feature';
import YTinfluencersReducer from './features/youtubeInfluencers-list.feature';

import locationsReducer from './features/locations/locations.feature';
import tagsReducer from './features/tags/tags.feature';
import topicsReducer from './features/topics/topics.feature';
import usersReducer from './features/users/users.feature';
import YTusersReducer from './features/users/YTusers.feature';
import TTinfluencersReducer from './features/tiktokInfluencers-list.feature';
import createUserReducer from './features/auth/singup.feature';
import loginReducer from './features/auth/login.feature';
import contactReducer from './features/contact.feature';

import resetPassReducer from './features/auth/reset-pass.feature';
import sendOtpReducer from './features/auth/otp.feature';
import verifyEmailReducer from './features/auth/verifyEmail.feature';

const rootReducer = {
  // counter: counterReducer,
  // employees: employeeReducer,
  // users: userListReducer,
  filters: filtersReducer,
  influencers: influencersReducer,
  locations: locationsReducer,
  tags: tagsReducer,
  topics: topicsReducer,
  influencerReport: influencerReportReducer,
  users: usersReducer,
  YTusers: YTusersReducer,
  youtubeInfluencers: YTinfluencersReducer,
  tiktokInfluencers: TTinfluencersReducer,
  signup: createUserReducer,
  login: loginReducer,
  userResetPass: resetPassReducer,
  userOtp: sendOtpReducer,
  contact: contactReducer,
  verifyEmail: verifyEmailReducer,
};
export default rootReducer;
