import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { genderOptionsArr } from '../../data/staticFilters';
import { updateYoutubeFilters } from '../../redux/features/filters.feature';
import SingleSelect from '../SingleSelect';

const YTGenderFilter = () => {
  const dispath = useDispatch();
  let { influencer, audience } = useSelector(
    (state) => state.filters.youtubeFilters
  );
  return (
    <>
      <p className='mb-1 text-sm text-white text-opacity-90'>Gender</p>
      <div className='flex space-x-2'>
        <SingleSelect
          className='w-full'
          options={genderOptionsArr}
          name='gender'
          placeholder='gender'
          onChange={(obj) =>
            dispath(
              updateYoutubeFilters({
                influencer: {
                  ...influencer,
                  gender: obj,
                },
                audience: {
                  ...audience,
                },
              })
            )
          }
          value={influencer.gender}
        />
      </div>
    </>
  );
};

export default YTGenderFilter;
