import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { FiAlertCircle } from 'react-icons/fi';
// import { Fade } from 'react-reveal';

import { useDispatch, useSelector } from 'react-redux';
import {
  clearResetPass,
  resetPass,
} from '../../redux/features/auth/reset-pass.feature';
import { clearOTP } from '../../redux/features/auth/otp.feature';

const NewPasswordForm = () => {
  const history = useNavigate();
  const [passInputType, setPassInputType] = useState('password');
  const [newPassword, setNewPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [successAlert, setSuccessAlert] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [SuccessAlertMsg, setSuccessAlertMsg] = useState('');
  const [showRestPassForm, setShowRestForm] = useState(false);

  const dispatch = useDispatch();

  const userResetPass = useSelector((state) => state.userResetPass);
  const { error, passwordRested, loading } = userResetPass;
  const userOtp = useSelector((state) => state.userOtp);
  const { user: otpUser } = userOtp;
  const { email } = otpUser;

  const handlePasswordEye = () =>
    passInputType === 'password'
      ? setPassInputType('text')
      : setPassInputType('password');
  // const redirect = location.search ? location.search.split("=")[1] : "/";
  const redirect = '/';

  useEffect(() => {
    if (error) {
      setAlert(true);
      setAlertMsg(error);
    } else {
      if (passwordRested) {
        setSuccessAlert(true);
        setSuccessAlertMsg('Password Successfully changed');
        // resetForm();
      }
    }

    const interval = setInterval(() => {
      setAlert(false);
    }, 3000);
    return () => clearInterval(interval);
  }, [error, passwordRested]);
  const handleSubmit = (e) => {
    const passwordMatch = confirmPassword === newPassword;

    if (!otp) {
      setAlert(true);
      setAlertMsg('Please enter valid OTP');
    } else {
      if (passwordMatch && confirmPassword && newPassword) {
        dispatch(resetPass({ email, otp, password: newPassword }));
      } else {
        setAlert(true);
        setAlertMsg('Please confirm your password');
      }
    }
    e.preventDefault();
  };
  const resetUserPassState = () => {
    dispatch(clearOTP());
    dispatch(clearResetPass());
    history('/login');
  };

  const resetForm = () => {
    setOtp('');
    setNewPassword('');
    setConfirmPassword('');
  };
  return (
    <div className='w-full max-w-[500px]'>
      <form
        autoComplete='new-password'
        onSubmit={handleSubmit}
        className='mx-auto w-full rounded-lg border border-white/40 bg-primary px-6 py-8 md:p-10'
      >
        <h1 className='text-tertiary mb-8 text-3xl font-bold'>
          Reset password
        </h1>
        {/* <input type='email' hidden value={email} /> */}
        {!passwordRested ? (
          <>
            <div className='relative'>
              <div className='mb-1 flex justify-between text-sm'>
                <label
                  htmlFor='otp'
                  className='text-white-800 mb-2 block text-sm font-medium '
                >
                  Enter OTP
                </label>
              </div>
              <input
                autoComplete='new-password'
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                type='text'
                id='otp'
                name='otp'
                className='block w-full rounded-md bg-gray-50 p-2.5 text-secondary focus:border focus:border-none focus:outline-pink  sm:text-sm'
              />
            </div>

            <>
              <div className='relative mt-6'>
                <div className='mb-1 flex justify-between text-sm'>
                  <label
                    htmlFor='password'
                    className='text-white-800 mb-2 block text-sm font-medium '
                  >
                    Enter new password
                  </label>
                  <div
                    onClick={handlePasswordEye}
                    className='text-tertiary/60 flex cursor-pointer items-center pr-1'
                  >
                    {passInputType === 'text' ? (
                      <>
                        <BsEye size={18} />
                      </>
                    ) : (
                      <>
                        <BsEyeSlash size={18} />
                      </>
                    )}
                  </div>
                </div>
                <input
                  autoComplete='new-password'
                  onChange={(e) => setNewPassword(e.target.value)}
                  value={newPassword}
                  type={passInputType}
                  id='password'
                  name='password'
                  className='block w-full rounded-md bg-gray-50 p-2.5 text-secondary focus:border focus:border-none focus:outline-pink  sm:text-sm'
                />
              </div>
              <div className='relative mt-6'>
                <div className='mb-1 flex justify-between text-sm'>
                  <label
                    htmlFor='confirmpass'
                    className='text-white-800 mb-2 block text-sm font-medium '
                  >
                    Confirm new password
                  </label>
                  {/* <div
                    onClick={handlePasswordEye}
                    className='text-tertiary/60 flex cursor-pointer items-center pr-1'
                  >
                    {passInputType === 'text' ? (
                      <>
                        <BsEye size={18} />
                      </>
                    ) : (
                      <>
                        <BsEyeSlash size={18} />
                      </>
                    )}
                  </div> */}
                </div>
                <input
                  autoComplete='new-password'
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                  type={passInputType}
                  id='confirmpass'
                  name='confirmpass'
                  className='block w-full rounded-md bg-gray-50 p-2.5 text-secondary focus:border focus:border-none focus:outline-pink  sm:text-sm'
                />
              </div>
            </>
          </>
        ) : null}

        {alert ? (
          <div className='relative mt-3'>
            <div className='flex items-center space-x-2 rounded bg-red-400 px-4 py-3'>
              <FiAlertCircle className='text-red-700' />
              <span className='text-sm'>{alertMsg}</span>
            </div>
          </div>
        ) : null}
        {successAlert ? (
          <div className='relative mt-3'>
            <div className='flex items-center space-x-2 rounded bg-green-400 px-4 py-3'>
              <FiAlertCircle className='text-white/80' />
              <span className='text-sm'>
                {SuccessAlertMsg}{' '}
                <div
                  onClick={resetUserPassState}
                  className='mx-auto ml-1 inline-block cursor-pointer font-semibold text-white/90 underline hover:text-primary/50'
                >
                  Login now
                </div>
              </span>
            </div>
          </div>
        ) : null}

        {!passwordRested && (
          <div className='mt-6 text-center'>
            {loading ? (
              <button
                disabled
                className='inline-flex rounded-full border-0 bg-gradient-to-r from-pinkLight to-purple py-2 px-6 text-base text-white transition duration-500 hover:bg-indigo-600 hover:from-purple hover:to-pinkLight focus:outline-none md:py-2 md:px-8'
              >
                Processing wait...
              </button>
            ) : (
              <button
                type='submit'
                className='inline-flex rounded-full border-0 bg-gradient-to-r from-pinkLight to-purple py-2 px-6 text-base text-white transition duration-500 hover:bg-indigo-600 hover:from-purple hover:to-pinkLight focus:outline-none md:py-2 md:px-8'
              >
                Rest Password
              </button>
            )}

            <div>
              <p className='mt-4 text-sm font-semibold opacity-90'>
                Please check your mailbox for the OTP
              </p>
              <p className='mt-2 text-sm font-semibold opacity-90'>
                Don't get the OTP{' '}
                <span
                  onClick={resetUserPassState}
                  className='mt-4 cursor-pointer text-sm underline opacity-90 hover:text-pink'
                >
                  Genrate New OTP
                </span>
              </p>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default NewPasswordForm;
